<template>
  <section class="reset-password-wrapper">
    <v-card elevation="0" class="reset-password-wrapper__card">
      <v-form v-model="valid" @submit.prevent="resetPassword">
        <v-container>
          <h2 class="reset-password-wrapper__card__h2">
            {{ $t("auth.set_new_password") }}
          </h2>
          <p>
            {{ $t("auth.requiriments_new_password") }}
          </p>
          <v-row class="mt-3">
            <v-col cols="12" md="12">
              <text-field-default
                v-model="form.password"
                class="text-field-custom"
                :rules="passwordRules"
                :label="$t('auth.password')"
                data-test="password"
                :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (value = !value)"
                :type="value ? 'password' : 'text'"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <text-field-default
                v-model="form.password_confirmation"
                class="text-field-custom"
                :rules="passwordConfirmationRules"
                :label="$t('auth.confirm_password')"
                data-test="password_confirmation"
                :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (value = !value)"
                :type="value ? 'password' : 'text'"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <button-custom
                block
                :label="$t('global.save')"
                type="submit"
                :disabled="!valid"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </section>
</template>

<script>
import TextFieldDefault from "@/components/forms/TextFieldDefault";
import ButtonCustom from "@/components/buttons/ButtonCustom";
import { rulesForm } from "@/utils/rulesForm.utils";
import auth from "@/services/auth";
import { mapActions } from "vuex";
const { required, min, oneDigit, oneCapitalize, oneSpecialCharacter } =
  rulesForm();

export default {
  name: "ResetPasswordForm",
  components: {
    TextFieldDefault,
    ButtonCustom,
  },
  data: (vm) => ({
    valid: false,
    value: true,
    form: {
      password: null,
      password_confirmation: null,
    },
    token: null,
    passwordRules: [
      (v) => min(v, 8),
      required,
      oneDigit,
      oneCapitalize,
      oneSpecialCharacter,
    ],
    passwordConfirmationRules: [
      required,
      () =>
        vm.form.password === vm.form.password_confirmation ||
        "Password must match",
    ],
  }),
  methods: {
    ...mapActions({
      openSnackbar: "states/openSnackbar",
    }),
    async validateToken() {
      try {
        await auth.validateToken(this.token);
      } catch (error) {
        await this.$router.push({ name: "invalidToken" });
      }
    },
    async resetPassword() {
      try {
        await auth.resetPassword({
          token: this.token,
          password: this.form.password,
        });
        this.openSnackbar({
          title: this.$t("Password changed"),
          message: this.$t("Your password has been confirmed successfully"),
          type: "success",
        });
        this.$router.push({ name: "login" });
      } catch (error) {
        this.openSnackbar({
          title: this.$t("Password not changed"),
          message:
            error.message ||
            this.$t(
              "Your password hasn’t change. Please try it again in a few minutes"
            ),
          type: "error",
        });
      }
    },
  },
  created() {
    const { token } = this.$route?.query || {};
    this.token = token;
    this.validateToken();
  },
};
</script>

<style scoped lang="scss">
.reset-password-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  &__card {
    width: 550px;
    padding: 30px;

    &__h2 {
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }
}
</style>

import i18n from "@/plugins/i18n";
import genericModels from "@/services/genericModels";
import { handlerErrorMessage, CREATED_MODEL, STATUS, MODEL } from "@/utils";

export default {
  PENDING_ABSENCE: [
    {
      field: "request_state",
      value: STATUS.PENDING,
      op: "==",
      or_with_next: true,
    },
    {
      field: "request_state",
      value: STATUS.PENDING_ACCEPT,
      op: "==",
      or_with_next: true,
    },
    { field: "request_state", value: STATUS.PENDING_CANCEL, op: "==" },
  ],
  /**
   * This function creates an absence.
   * Used when user clicks on create absence button
   * @param payload : { type: Enum "days of leave" | "vacations", starting_date: string, ending_date: string, comment?: string }
   * @returns Promise<void>
   */
  async create(payload) {
    try {
      if (!payload) return;
      const { data } = await genericModels.post(MODEL.ABSENCE, payload);
      return data;
    } catch (error) {
      return handlerErrorMessage(error);
    }
  },
  /**
   * Use approve operation on absence.
   * @param payload: {absenceIds : uuid[]}
   * @returns Promise<void>
   */
  async approve(payload) {
    const { absenceIds } = payload;
    if (!absenceIds || !payload) return;
    await genericModels.postOperation(
      MODEL.ABSENCE,
      "approve",
      absenceIds,
      true,
      {},
      i18n.t("Approved absence")
    );
  },
  /**
   * Use denied operation on absence.
   * @param payload: {absenceIds : uuid[]}
   * @returns Promise<void>
   */
  async deny(payload) {
    const { absenceIds } = payload;
    if (!absenceIds || !payload) return;
    await genericModels.postOperation(
      MODEL.ABSENCE,
      "denied",
      absenceIds,
      true,
      {},
      i18n.t("Diened absence")
    );
  },
  /**
   * Use cancel operation on absence.
   * @param payload: {absenceIds : uuid[]}
   * @returns Promise<void>
   */
  async cancel(payload) {
    const { absenceIds } = payload;
    if (!absenceIds || !payload) return;
    await genericModels.postOperation(
      MODEL.ABSENCE,
      "cancel",
      absenceIds,
      true,
      {},
      i18n.t("Cancellation requested")
    );
  },
  /**
   * Save on Vuex the pending absences, let's load on id for performance, we only need generic.pendingabsence.total load it, so we can know how many pending absence we have.
   * @return Promise<void>
   */
  async getPendingAbsences() {
    await genericModels.get(
      CREATED_MODEL.PENDINGABSENCE,
      {
        filters: {
          filters: this.PENDING_ABSENCE,
        },
      },
      false
    );
  },
};

import axiosInstance from "@/plugins/axios";
import store from "@/store";
const headers = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};
export default {
  login(data) {
    const { username, password } = data;

    const form_data = new FormData();
    form_data.append("grant_type", "");
    form_data.append("username", username);
    form_data.append("password", password);
    form_data.append("scope", "");
    form_data.append("client_id", "");
    form_data.append("client_secret", "");
    return axiosInstance.post("/login", form_data, headers);
  },
  logout() {
    return axiosInstance.post("/logout");
  },
  forgotPassword(data) {
    return axiosInstance.post("/recover-password/", data);
  },
  validateToken(token) {
    return axiosInstance.get(`/recover-password/?token=${token}`);
  },
  resetPassword({ token, password }) {
    return axiosInstance.post(`/recover-password/change/?token=${token}`, {
      password,
    });
  },
  async getUserInfo() {
    try {
      const { data } = await axiosInstance.get(`/me`);
      store.commit("auth/setUser", data);
    } catch (error) {
      throw new Error(error);
    }
  },
};

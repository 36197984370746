import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "@/plugins/i18n";
import vuetify from "@/plugins/vuetify";
import axios from "@/plugins/axios";
import Ripple from "vuetify/lib/directives/ripple";
import Vuetify from "vuetify";
import "@/utils/filters";
import globalDirectives from "./directives";
import rolesPlugin from "./plugins/userRoles";
import "./plugins/currency";

Vue.prototype.$axios = axios;
Vue.config.productionTip = false;

Vue.use(globalDirectives);
Vue.use(rolesPlugin);

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
});

new Vue({
  render: (h) => h(App),
  router,
  store,
  i18n,
  vuetify,
}).$mount("#app");

import store from "@/store";
import commentNode from "../commentNode";

const can = ({ model, action }) => {
  const permissions = store.state.permissions.permissions;
  return permissions.find((p) => p.model === model && p[action] === true);
};

export const canDirective = (el, bindings, vnode) => {
  if (bindings.value !== true) {
    const valid = can(bindings.value);
    if (!valid) {
      commentNode(el, vnode);
    }
  }
};

export const mutations = {
  /**
   * @param State : state
   * @param {} alertSelected
   */
  setYearstatisticSelected: (state, yearstatistic) => {
    state.yearstatisticSelected = yearstatistic;
  },
  setMonthlySaleSelected: (state, monthlySale) => {
    state.monthlysaleSelected = monthlySale;
  },
  setDataGraphics: (state, dataGraphics) => {
    state.dataGraphics = dataGraphics;
  },
};

const role = ["administrator", "director", "employee"];
const layout = "director";
import authMiddleware from "@/middlewares/auth";
import roleMiddleware from "@/middlewares/role";

const metaDirector = {
  role,
  layout,
  middleware: [authMiddleware, roleMiddleware],
};

const director = [
  {
    path: "/director/dashboard",
    name: "director.dashboard",
    component: () => import("@/views/director/DashboardView.vue"),
    meta: metaDirector,
  },
  {
    path: "/teams/time-report",
    name: "teams.time_report",
    component: () =>
      import("@/views/director/teams/dashboard/TeamsDashboard.vue"),
    meta: { ...metaDirector, breadCrumb: "Time report" },
  },
  {
    path: "/teams/user/time-report",
    name: "teams.user_time_report_year",
    component: () =>
      import(
        "@/views/director/teams/employee_detail_report/EmployeeDetailYearReport.vue"
      ),
    meta: { ...metaDirector, breadCrumb: "Employee Detail" },
  },
  {
    path: "/teams/time-report/:id/:month",
    name: "teams.user_time_report_month",
    component: () => import("@/views/schedule/MonthActivity.vue"),
    meta: { ...metaDirector, breadCrumb: "Monthly report" },
  },
];

export default director;

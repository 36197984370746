<template>
  <v-card
    elevation="0"
    class="cont-card__card d-flex flex-column align-center justify-center"
  >
    <v-icon color="error" size="30">mdi-alert-octagon-outline</v-icon>
    <h2 class="text-uppercase my-4">{{ $t("general.error_404") }}</h2>
    <h5 class="text-element">{{ $t("general.page_not_found") }}</h5>
  </v-card>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Page404",
  components: {},
  props: {},
};
</script>

<style lang="scss" scoped>
.cont-card {
  &__card {
    width: 552px;
    height: 232px;
    padding: 30px;
    .text-element {
      font-size: 16px;
    }
  }
}
</style>

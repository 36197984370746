export const getters = {
  tenantSelected: (state) => {
    return state.tenantSelected;
  },
  monthlyBudgetGraphics: (state) => {
    return state.monthlyBudgetGraphics;
  },
  monthlyBudgetGraphicsAllTenants: (state) => {
    return state.monthlyBudgetGraphicsAllTenants;
  },
};

import store from "@/store";
import i18n from "@/plugins/i18n";
import genericModels from "@/services/genericModels";
import budget from "@/services/budget";
import fileService from "@/services/genericFiles";
import { toNumber } from "lodash";
import { contentTypes } from "./constants/contentTypes";
import { USER_ROLE } from "./constants";

export const paginationDefault = {
  page: 1,
  itemsPerPage: 10,
  size: 10,
};
/**
 * Check if a value exists and always return a string.
 * Usefull when backend returns null on data, to show 'No data' to the user instead of nothing
 * @param  string | null : value
 * @returns string
 */
export const getField = (value) => {
  return value ? value : i18n.t("No data");
};
/**
 * Check if a value exists and always return a string.
 * Usefull when backend returns null on data, to show '-' to the user instead of nothing
 * @param  any : value
 * @returns string
 */
export const fillField = (value) => {
  let stringToReturn = "-";
  if (value && value != "") {
    stringToReturn = value;
  }
  return stringToReturn;
};
/**
 * Copy a text and show a notification that the text has been copied successfully.
 * @param string : message
 * @returns void
 */
export const copyToClipboard = (message) => {
  if (!message) return;
  navigator.clipboard.writeText(message);
  store.dispatch("states/openSnackbar", {
    title: i18n.t("Success"),
    message: i18n.t("The {} has been copied successfully", { message }),
    type: "success",
  });
};
/**
 * Accept a budget and sync data.
 * @param enum "budget"  modelName
 * @param string : idBudget
 * @param boolean : loadPendingBudgets
 */
export const acceptBudget = async (
  modelName,
  idBudget,
  loadPendingBudgets = false
) => {
  await genericModels
    .postOperation(
      modelName,
      "approve",
      [idBudget],
      true,
      null,
      "The {} have been approved successfully"
    )
    .then(() => {
      store.dispatch("budget/get_budgets", loadPendingBudgets);
      store.dispatch("budget/get_budgets_pending");
      budget.getBudgetHistory(idBudget);
    });
};
/**
 * You can show an error notification message if you pass an object with the data.
 * @param msjObj? : { title : string, message: string }
 * @returns void
 */
export const showNotificationError = (msjObj) => {
  // If msg does not exist show default error message:
  if (!msjObj) {
    const error = {
      title: i18n.t("There’s something wrong"),
      message: i18n.t("Error"),
    };
    const { title, message } = error;
    store.dispatch("states/openSnackbar", {
      title,
      message,
      type: "error",
    });
    return;
  }
  const { title, message } = msjObj;
  store.dispatch("states/openSnackbar", {
    title,
    message,
    type: "error",
  });
};
/**
 * This function is used to map some fields that the backend return that are an array. We need them as an array of strings
 * @param [[string, string]] : arr
 * return string[]
 */
export const getNameFields = (arr) => {
  let arrayToReturn = [];
  // Check if the array is empty
  if (!arr || arr.length === 0) return arrayToReturn;
  arrayToReturn = arr.map((item) => {
    return item[1];
  });
  return arrayToReturn;
};
/**
 * This function is used to map some fields that the backend return that are an array. We need them as an array of strings
 * @param [[string, string]] : arr
 * return string[]
 */
export const getIdFields = (arr) => {
  let arrayToReturn = [];
  // Check if the array is empty
  if (!arr || arr.length === 0) return arrayToReturn;
  arrayToReturn = arr.map((item) => {
    return item[0];
  });
  return arrayToReturn;
};
/**
 * You can download a file with this function. Just pass the url of the file and the name you want the file to be downloaded.
 * @param string : fileLink
 * @param string : fileName
 * @returns void
 */
export const downloadFile = (fileLink, fileName) => {
  fetch(fileLink)
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(() => {
      showNotificationError();
    });
};
/**
 * This function return the content type of a specific extension.
 * @param extension : string
 * @returns string
 */
export const getContentType = (extension) => {
  if (!extension || extension === "") return "";
  let obj = contentTypes.find((item) => item.extension === extension);
  return obj.mime;
};

/**
 * Used to know if a string is a valid uuid, usefull when searchin by id and check if it's a valid uuid.
 * @param uuid : string
 * @return boolean
 */

export const isUUID = (uuid) => {
  let s = "" + uuid;

  s = s.match(
    "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
  );
  if (s === null) {
    return false;
  }
  return true;
};
export const getChipsNotifications = (links, parent, children) => {
  const linkPared = links.findIndex((item) => item.t === parent);
  for (let i = 0; i < children.length; i++) {
    const linkChild = links[linkPared].links.findIndex(
      (item) => item.t === children[i].t
    );
    links[linkPared].links[linkChild].showChip =
      children[i].value > 0 ? true : false;
    links[linkPared].links[linkChild].valueChip = children[i].value;
  }
  return links;
};
export const getPendingAbsenceNotification = (navigationNavGroups, label) => {
  const knowGroupAbsence = (item) =>
    item.title === "team manager" && item.rolesAllowed.includes(USER_ROLE.ADM);
  const knowSection = (item) => item.t === "teams.teams";
  const knowLink = (item) => item.t === "schedule.absences";

  const group = navigationNavGroups.find(knowGroupAbsence);
  const indexGroup = navigationNavGroups.findIndex(knowGroupAbsence);
  const section = group.sections.find(knowSection);
  const indexSection = group.sections.findIndex(knowSection);
  const link = section.links.find(knowLink);
  const indexLink = section.links.findIndex(knowLink);
  link.valueChip = label?.total;
  const navigationGroupCopy = JSON.parse(JSON.stringify(navigationNavGroups));
  navigationGroupCopy[indexGroup].sections[indexSection].links[
    indexLink
  ].valueChip = label?.total;
  return navigationGroupCopy;
};
/**
 * This function is used to get the year of the form (string to number)
 * @param form : Object { year: string }
 * @returns number | void
 */
export const getYearOfForm = (form) => {
  if (!form) return;
  const arrStartDate = form.year.split("-");
  const year = toNumber(arrStartDate[0]);
  return year;
};
/**
 * This function is used to get a number (specially when the api returns null in number fields)
 * @param val : numeber
 * @returns number
 */
export const getValNumber = (val) => {
  if (!val) return 0;
  return val;
};
/**
 * This function is used to get extension from a filename.
 * @param filename: string
 * @return string
 */
export const getExtension = (filename) => {
  if (!filename) return null;
  const extension = filename.split(".").pop();
  return extension;
};

/**
 * This fuction return a string with the image from an image id
 * @param obj : { file_id: string, model: string (enum), model_id: string, base64: boolean, extension: string }
 */
export const getImage = async ({
  file_id,
  model,
  model_id,
  base64 = true,
  extension,
}) => {
  if (!file_id || !model || !model_id || !extension) return null;
  const fileBase64 = await fileService.getFileModelField({
    model,
    model_id,
    file_id,
    base64,
  });
  const contentType = getContentType(extension);
  const file = `data:${contentType};base64,${fileBase64}`;
  return file;
};
/**
 * Move from one position to another position.
 * @param arr : Array<any>
 * @param fromIndex  : number
 * @param toIndex  : number
 * @return Array<any>
 */
export const arraymove = (arr, fromIndex, toIndex) => {
  let element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
};

/**
 * This function returns true or false if the object has the total data.
 * @param item : {difference: number, estimate_amount: number, month: number, real_amount: number}
 * @return boolean
 */
export const isTotal = (item) => {
  let isTotal = false;
  if (!item) return isTotal;
  if (item.month === "total") {
    isTotal = true;
  }
  return isTotal;
};
/**
 * This function get the last day of a month
 * @param y : number (Year)
 * @param m : number (Month)
 * @return string
 */
export const lastday = (y, m) => {
  return new Date(y, m + 1, 0).getDate();
};
/**
 * Function truncate(str, maxlength) that checks the length of the str and, if it exceeds maxlength – replaces the end of str with the ellipsis character "…", to make its length equal to maxlength.
 * @param str: string
 * @param maxlength : number
 * @returns string
 */
export const truncate = (str, maxlength) => {
  return str.length > maxlength ? str.slice(0, maxlength - 1) + "…" : str;
};
/**
 * Get specific data from local storage.
 * @param payload : { key: string, field: string }
 * @returns any
 */
export const getFromLocalStorage = (payload) => {
  if (!payload) return;
  const { key, field } = payload;
  const dataOnLocalStorage = localStorage.getItem(key);
  if (dataOnLocalStorage) {
    const { [field]: data } = JSON.parse(dataOnLocalStorage);
    return [data];
  }
  return "";
};
/**
 * This function return an array of the elements that are repeated by the times you need.
 * You can see some tests of this function to know how is used in file general.spec.js
 * @param payload: { array: string[], repeated: number}
 */
export const justRepeated = (payload) => {
  if (!payload || !payload.array || !payload.repeated) return;
  const { array, repeated } = payload;
  const tempArray = [...array].sort();
  let duplicates = [];
  let counter = 0;
  for (let i = 0; i < tempArray.length; i++) {
    counter = 0;
    for (let j = 0; j < tempArray.length; j++) {
      if (tempArray[i] === tempArray[j]) {
        counter++;
      }
      if (counter === repeated) {
        duplicates.push(array[i]);
      }
    }
  }
  return [...new Set(duplicates)];
};

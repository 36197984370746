<template>
  <v-dialog
    :value="value"
    :max-width="maxWidth"
    @input="onInputDialog"
    v-bind="$attrs"
  >
    <slot name="header" />
    <v-card :height="cardHeight" :style="`padding: ${padding}`">
      <v-card-title class="d-flex justify-space-between">
        <span class="text-h5 text-uppercase">{{ title }}</span>
        <v-btn v-if="closable" icon small @click="onInputDialog(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <slot></slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { PropTypes } from "@/types";

export default {
  name: "WrapperDialog",
  props: {
    value: PropTypes.boolean,
    cardHeight: PropTypes.string,
    closable: Boolean,
    title: PropTypes.string,
    maxWidth: {
      default: "343px",
    },
    padding: {
      default: "10px",
    },
  },
  methods: {
    onInputDialog(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style></style>

import {
  capitalizeText,
  MODEL,
  requestFilters,
  handlerSuccessMessage,
  handlerErrorMessage,
} from "@/utils";
import genericModels from "@/services/genericModels";
import i18n from "@/plugins/i18n";
import axiosInstance from "@/plugins/axios";

export default {
  async getBusinessUnits() {
    try {
      const budgetModel = await genericModels.getModel(MODEL.PROJECT);
      const businessUnitsEnum = budgetModel.definitions.BusinessUnitsEnum.enum;
      return businessUnitsEnum.map((item) => capitalizeText(item));
    } catch (error) {
      return handlerErrorMessage(error);
    }
  },
  async getBudgetsFromDepartmentAndBusinessUnit(department_id, business_unit) {
    try {
      const filter1 = requestFilters.equal("department_id", [department_id]);
      const filter2 = requestFilters.equal("business_unit_id", [business_unit]);
      const filter3 = requestFilters.equal("status", ["approved"]);
      const filters = { filters: [...filter1, ...filter2, ...filter3] };
      const { data } = await genericModels.get(
        "budget",
        { filters: filters },
        true
      );
      return data.items;
    } catch (error) {
      return handlerErrorMessage(error);
    }
  },
  async approvedProject(id) {
    try {
      await genericModels.postOperation(MODEL.PROJECT, "approve", [id]);
      handlerSuccessMessage(
        i18n.t("The project have been approved successfully")
      );
    } catch (error) {
      return handlerErrorMessage(error);
    }
  },
  async getPendingProjectsLength() {
    try {
      const params = { params: { size: 1, fields: "id" } };

      const filter = requestFilters.equal("status", ["pending"]);
      const filters = { filters: [...filter] };
      const { data } = await axiosInstance.put(MODEL.PROJECT, filters, params);
      return data.total;
    } catch (error) {
      console.log(error);
      return handlerErrorMessage(error);
    }
  },
  async getUnassignAccountProjectsLength() {
    try {
      const params = { params: { size: 1, fields: "id" } };

      const filter = requestFilters.has_not("accounts", ["None"]);
      const filters = { filters: [...filter] };
      const { data } = await axiosInstance.put(MODEL.PROJECT, filters, params);
      return data.total;
    } catch (error) {
      console.log(error);
      return handlerErrorMessage(error);
    }
  },
  async getAccountsProject(ids) {
    try {
      const params = {
        params: { size: -1 },
      };

      const filter = requestFilters.in("id", ids);
      const filters = { filters: [filter] };
      const { data } = await axiosInstance.put(
        `${MODEL.ACCOUNT}?fields=id&fields=description&fields=code`,
        filters,
        params
      );
      return data.items;
    } catch (error) {
      console.log(error);
      return handlerErrorMessage(error);
    }
  },
  /**
   * This function is to filter projects by business_unit
   * @param business_unit : string[]
   * @returns [{"field":"project_id","value":[string],"op":"in"}]
   */
  async getFromBusinessUnit(business_unit) {
    if (!business_unit) return;
    try {
      const filter1 = requestFilters.equal("business_unit_id", business_unit);
      const { data } = await axiosInstance.put(
        MODEL.PROJECT,
        { filters: filter1 },
        { params: { size: -1, fields: "id" } }
      );
      const projectsMap = data.items.map((item) => item.id);
      return requestFilters.in("project_id", projectsMap);
    } catch (error) {
      return handlerErrorMessage(error);
    }
  },
  /**
   * This function is to filter projects by departments
   * @param departments : string[]
   * @returns [{"field":"project_id","value":[string],"op":"in"}]
   */
  async getFromDepartment(departments) {
    if (!departments) return;
    try {
      const filter1 = requestFilters.equal("department_id", departments);
      const { data } = await axiosInstance.put(
        MODEL.PROJECT,
        { filters: filter1 },
        { params: { size: -1, fields: "id" } }
      );
      const projectsMap = data.items.map((item) => item.id);
      return requestFilters.in("project_id", projectsMap);
    } catch (error) {
      return handlerErrorMessage(error);
    }
  },

  /**
   * This function is to filter projects by code
   * @param code : string[]
   * @returns [{"field":"project_id","value":[string],"op":"in"}]
   */
  async getFromCode(code) {
    if (!code) return;
    try {
      const filter1 = requestFilters.like("code", code);
      const { data } = await axiosInstance.put(
        MODEL.PROJECT,
        { filters: filter1 },
        { params: { size: -1, fields: "id" } }
      );
      const projectsMap = data.items.map((item) => item.id);
      return requestFilters.in("project_id", projectsMap);
    } catch (error) {
      return handlerErrorMessage(error);
    }
  },
  async getAllProjects() {
    try {
      const { data } = await genericModels.get(
        MODEL.PROJECT,
        { params: { size: -1 } },
        true
      );
      return data;
    } catch (error) {
      return handlerErrorMessage(error);
    }
  },
  async assignLedgerAccountToProject({
    project_id,
    account_ids,
    cost_center_id,
  }) {
    try {
      const fetchURL = (account_id) =>
        axiosInstance.post("accountprojectlink", {
          project_id,
          account_id,
          cost_center_id,
        });
      const promiseArray = account_ids.map(fetchURL);
      const data = await Promise.all(promiseArray);
      handlerSuccessMessage(
        i18n.t("The accounts ledgers have been assigned successfully")
      );
      return data;
    } catch (error) {
      console.log(error);
      return handlerErrorMessage(error);
    }
  },
  async removeLedgerAccountToProject({ account_id, model_id }) {
    try {
      const filter1 = requestFilters.equal("account_id", [account_id]);
      const filter2 = requestFilters.equal("project_id", [model_id]);
      const filters = [...filter1, ...filter2];
      const accountprojectlink = await axiosInstance.put("accountprojectlink", {
        filters,
      });
      const { data } = await axiosInstance.delete(
        `accountprojectlink/${accountprojectlink.data.items[0].id}`
      );
      handlerSuccessMessage(
        i18n.t("The accounts ledgers have been removed successfully")
      );
      return data;
    } catch (error) {
      console.log(error);
      return handlerErrorMessage(error);
    }
  },
  async getAccountAssigned() {
    try {
      const { data } = await axiosInstance.put("accountprojectlink");
      return data.items.map((item) => item.account_id);
    } catch (error) {
      console.log(error);
      return handlerErrorMessage(error);
    }
  },
  /**
   * This function returns the data for the graphic on budget detail.
   * @param id : string
   */
  async getPlotsEstimatedReal(id) {
    if (!id) return;
    try {
      const { data } = await axiosInstance.post(
        `${MODEL.PROJECT}/op/plot_estimated_real?model_ids=${id}`
      );
      return data;
    } catch (error) {
      return handlerErrorMessage(error);
    }
  },
};

import { user } from "./auth";

const state = {
  users: [],
  usersActive: [],
  userSelected: user,
  userTimeReportSelected:
    localStorage.getItem("USER_TIME_REPORT_SELECTED") || null,
  userWrapper: {
    isView: false,
    isViewEdit: false,
  },
};

const getters = {
  userSelected: (state) => {
    return state.userSelected;
  },
  userTimeReportSelected: (state) => {
    return state.userTimeReportSelected;
  },
  usersActive: (state) => {
    return state.usersActive;
  },
  userWrapper: (state) => {
    return state.userWrapper;
  },
  actionIds: (state) => {
    return state.userSelected?.action_ids?.map((value) => value[0]);
  },
};

const mutations = {
  /**
   * @param State : state
   * @param {} auth
   */
  setUsersList: (state, users) => {
    state.users = users.items;
  },
  /**
   * @param State : state
   * @param {} auth
   */
  setUsersActiveList: (state, users) => {
    state.usersActive = users;
  },
  /**
   * @param State : state
   * @param {} user
   */
  setUserSelected: (state, user) => {
    state.userSelected = user;
  },
  /**
   * @param State : state
   * @param {} user
   */
  setUserTimeReportSelected: (state, user) => {
    state.userTimeReportSelected = user;
    localStorage.setItem("USER_TIME_REPORT_SELECTED", user);
  },
  /**
   * @param State : state
   * @param {} userWrapper
   */
  setUserWrapper: (state, userWrapper) => {
    state.userWrapper = userWrapper;
  },
};

const actions = {
  /**
   * @param context : ActionContext<Store>
   * @param {} auth
   */
  set_users_list: (context, users) => {
    context.commit("setUsersList", users);
  },
  /**
   * @param context : ActionContext<Store>
   * @param {} auth
   */
  set_users_active_list: (context, users) => {
    context.commit("setUsersActiveList", users);
  },
  /**
   * @param context : ActionContext<Store>
   * @param {} auth
   */
  set_user_selected: (context, user) => {
    context.commit("setUserSelected", user);
  },
  /**
   * @param context : ActionContext<Store>
   * @param {} auth
   */
  set_user_time_report_selected: (context, user) => {
    context.commit("setUserTimeReportSelected", user);
  },
  /**
   * @param context : ActionContext<Store>
   * @param {} auth
   */
  set_user_wrapper: (context, userWrapper) => {
    context.commit("setUserWrapper", userWrapper);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

import moment from "moment";

export const datePrimary = (value, format = "LL") => {
  if (!value) return "";
  value = value.toString();
  return moment(value).format(format);
};

export const DATE_DD_MM_YYYY = (value) => {
  return datePrimary(value, "DD MMM YYYY");
};
export const DATE_MMM_DD_YYYY = (value) => {
  return datePrimary(value, "MMM DD YYYY");
};
export const DATE_hh_mm = (value) => {
  return datePrimary(value, "hh:mm");
};
export const dateHour = (value) => {
  if (!value) return "";
  value = value.toString();
  // 21:27:57.967935
  const array = value.split(":");
  const hour = `${array[0]}:${array[1]}`;
  return hour;
};

export const DATE_YYYY_MM_DD = (value) => {
  return datePrimary(value, "YYYY-MM-DD");
};

export const isExpireDate = (date, now = new Date()) => {
  return moment(date).isBefore(now);
};

export const rangeDateCurrentMonth = () => {
  const start = DATE_YYYY_MM_DD(moment().startOf("month"));
  const end = DATE_YYYY_MM_DD(moment().endOf("month"));
  return [start, end];
};

export const rangeDateCurrentYear = () => {
  const start = DATE_YYYY_MM_DD(moment().startOf("year"));
  const end = DATE_YYYY_MM_DD(moment().endOf("year"));
  return [start, end];
};

export const rangeDateLastYear = () => {
  const start = DATE_YYYY_MM_DD(moment().subtract(1, "years").startOf("year"));
  const end = DATE_YYYY_MM_DD(moment().subtract(1, "years").endOf("year"));
  return [start, end];
};

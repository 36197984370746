import { USER_ROLE } from "@/utils/constants";
const role = [USER_ROLE.ADM, USER_ROLE.EMP, USER_ROLE.DIR];
const layout = "admin";
import authMiddleware from "@/middlewares/auth";
import roleMiddleware from "@/middlewares/role";

const metaSchedule = {
  role,
  layout,
  middleware: [authMiddleware, roleMiddleware],
};

const schedule = [
  {
    path: "/schedule/dashboard",
    name: "schedule.dashboard",
    component: () => import("@/views/schedule/Dashboard.vue"),
    meta: metaSchedule,
  },
  {
    path: "/schedule/time-report",
    name: "schedule.time_report",
    component: () => import("@/views/schedule/TimeReport.vue"),
    meta: { ...metaSchedule, breadCrumb: "Time report" },
  },
  {
    path: "/schedule/time-report/:month",
    name: "schedule.time_report_month",
    component: () => import("@/views/schedule/MonthActivity.vue"),
    meta: { ...metaSchedule, breadCrumb: "Month detail" },
  },
  {
    path: "/schedule/absences-record",
    name: "schedule.absences-record",
    component: () => import("@/views/schedule/AbsenceRecord.vue"),
    meta: metaSchedule,
  },
  {
    path: "/schedule/absences",
    name: "schedule.absences",
    component: () => import("@/views/schedule/AbsenceView.vue"),
    meta: metaSchedule,
  },
];

export default schedule;

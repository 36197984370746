export default [
  {
    label: "Works (0)",
    data: [
      { label: "jan", value: 0 },
      { label: "feb", value: 0 },
      { label: "mar", value: 0 },
      { label: "apr", value: 0 },
      { label: "may", value: 0 },
      { label: "jun", value: 0 },
      { label: "jul", value: 0 },
      { label: "aug", value: 0 },
      { label: "sep", value: 0 },
      { label: "oct", value: 0 },
      { label: "nov", value: 0 },
      { label: "dec", value: 0 },
    ],
    count: 12,
    color: "rgb(220, 221, 232)",
  },
  {
    label: "Absent (0)",
    data: [
      { label: "jan", value: 0 },
      { label: "feb", value: 0 },
      { label: "mar", value: 0 },
      { label: "apr", value: 0 },
      { label: "may", value: 0 },
      { label: "jun", value: 0 },
      { label: "jul", value: 0 },
      { label: "aug", value: 0 },
      { label: "sep", value: 0 },
      { label: "oct", value: 0 },
      { label: "nov", value: 0 },
      { label: "dec", value: 0 },
    ],
    count: 12,
    color: "rgb(0, 56, 101)",
  },
  {
    label: "Vacations (0)",
    data: [
      { label: "jan", value: 0 },
      { label: "feb", value: 0 },
      { label: "mar", value: 0 },
      { label: "apr", value: 0 },
      { label: "may", value: 0 },
      { label: "jun", value: 0 },
      { label: "jul", value: 0 },
      { label: "aug", value: 0 },
      { label: "sep", value: 0 },
      { label: "oct", value: 0 },
      { label: "nov", value: 0 },
      { label: "dec", value: 0 },
    ],
    count: 12,
    color: "rgb(222, 200, 156)",
  },
];

import { USER_ROLE } from "@/utils/constants";
const role = [USER_ROLE.EMP, USER_ROLE.DIR, USER_ROLE.ADM];
const layout = "admin";
import authMiddleware from "@/middlewares/auth";
import roleMiddleware from "@/middlewares/role";

const metaSuppliers = {
  role,
  layout,
  middleware: [authMiddleware, roleMiddleware],
};

const suppliers = [
  {
    path: "/suppliers/dashboard",
    name: "suppliers.dashboard",
    component: () => import("@/views/suppliers/SuppliersDashboard.vue"),
    meta: metaSuppliers,
  },
  {
    path: "/suppliers/:id",
    name: "suppliers.details",
    component: () => import("@/views/suppliers/SupplierDetails.vue"),
    meta: metaSuppliers,
  },
];

export default suppliers;

<template>
  <v-dialog :value="showWelcomeMessage" max-width="600" v-bind="$attrs">
    <section class="welcome-alert-wrapper">
      <v-row no-gutters>
        <v-col cols="7" sm="7">
          <img
            src="@/assets/images/canalejas-welcome.jpg"
            alt="Canalejas"
            class="welcome-alert-wrapper__image"
          />
        </v-col>
        <v-col cols="5" sm="5">
          <v-btn
            class="welcome-alert-wrapper__close"
            depressed
            fab
            plain
            small
            @click.prevent="closeWelcomeMessage"
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
          <section class="welcome-alert-wrapper__body">
            <div>
              <h1>{{ $t("general.welcome") }}</h1>
              <p>{{ $t("general.canalejas_SGI") }}</p>
              <button-custom
                :label="$t('general.start_explore')"
                @click="closeWelcomeMessage"
                class="text-none mt-4"
                outlined
                icon="mdi-arrow-right"
                right-icon
              />
            </div>
          </section>
        </v-col>
      </v-row>
    </section>
  </v-dialog>
</template>

<script>
import ButtonCustom from "@/components/buttons/ButtonCustom";
import { mapGetters } from "vuex";

export default {
  name: "WelcomeAlert",
  computed: {
    ...mapGetters({
      showWelcomeMessage: "states/showWelcomeMessage",
    }),
  },
  components: {
    ButtonCustom,
  },
  methods: {
    onInputDialog(value) {
      this.$emit("input", value);
    },
    closeWelcomeMessage() {
      this.$store.dispatch("states/closeWelcomeMessage");
    },
  },
};
</script>

<style scoped lang="scss">
.welcome-alert-wrapper {
  width: 100%;
  overflow: hidden;
  background: white;
  position: relative;

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    color: var(--v-neutral-lighten4);
  }

  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  &__body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    position: relative;
    > div {
      > h1 {
        color: var(--v-primary-base);
        font: {
          weight: 600;
          size: 32px;
        }
        text-transform: uppercase !important;
      }
      > p {
        color: var(--v-neutral-darken1);
      }
    }
  }
}
</style>

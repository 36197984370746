import authMiddleware from "@/middlewares/auth";
import roleMiddleware from "@/middlewares/role";
import { USER_ROLE } from "@/utils/constants";

const role = [USER_ROLE.ADM, USER_ROLE.EMP];
const layout = "admin";

const metaEmployee = {
  role,
  layout,
  middleware: [authMiddleware, roleMiddleware],
};

const employee = [
  {
    path: "/employee/dashboard",
    name: "employee.dashboard",
    component: () => import("@/views/admin/Dashboard/DashboardView.vue"),
    meta: metaEmployee,
  },
];

export default employee;

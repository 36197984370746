<template>
  <v-main class="cont-card">
    <section class="d-flex align-center justify-center">
      <slot></slot>
    </section>
  </v-main>
</template>

<script>
export default {
  name: "LayoutBackground",
  props: {},
};
</script>

<style lang="scss" scoped></style>

import genericModels from "@/services/genericModels";

export const actions = {
  /**
   * @param context : ActionContext<Store>
   * @param {} store
   */
  SET_CONTRACT_SELECTED: (context, contract) => {
    context.commit("setContractSelected", contract);
  },
  /**
   * @param context : ActionContext<Store>
   * @param {} store
   */
  GET_CONTRACTS: (context, entityId) => {
    return genericModels.get(
      "contract",
      {
        filters: {
          filters: [
            { field: "active", op: "==", value: true },
            { field: "entity_id", op: "==", value: entityId },
          ],
          sort_by: [{ field: "updated_at", desc: true }],
          show_inactives: false,
        },
      },
      true
    );
  },
};

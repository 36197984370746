<template>
  <v-app id="app">
    <component :is="resolveLayout">
      <router-view />
    </component>
    <main-snackbar></main-snackbar>
    <welcome-alert></welcome-alert>
    <dialog-work-day-almost-over v-if="showTimerComponent" />
    <loading-default></loading-default>
    <dialog-working-timer v-if="showWorkingTimerDialog" />
    <time-tracker v-if="showTimerComponent" />
  </v-app>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout";
import MainSnackbar from "@/components/general/Snackbar";
import AdminLayout from "@/layouts/admin/AdminLayout";
import DirectorLayout from "@/layouts/director/DirectorLayout";
import EmployeeLayout from "@/layouts/employee/EmployeeLayout";
import FinanceLayout from "@/layouts/finance/FinanceLayout";
import WelcomeAlert from "@/components/general/WelcomeAlert";
import LoadingDefault from "@/components/general/LoadingDefault";
import DialogWorkingTimer from "@/components/time-tracking/DialogWorkingTimer.vue";
import TimeTracker from "@/components/time-tracking/TimeTracker.vue";
import { mapGetters } from "vuex";
import { USER_ROLE } from "./utils";
import DialogWorkDayAlmostOver from "@/components/dialog/DialogWorkDayAlmostOver.vue";

export default {
  data() {
    return {
      showWelcome: false,
      showLoading: true,
    };
  },
  components: {
    DefaultLayout,
    AdminLayout,
    DirectorLayout,
    MainSnackbar,
    EmployeeLayout,
    FinanceLayout,
    WelcomeAlert,
    LoadingDefault,
    DialogWorkingTimer,
    TimeTracker,
    DialogWorkDayAlmostOver,
  },
  computed: {
    ...mapGetters({
      userRole: "auth/user_role",
      hasTimeSession: "timeTracker/hasTimeSession",
      preventTimerDialog: "timeTracker/preventTimerDialog",
    }),

    resolveLayout() {
      const route = this.$route;
      const { layout } = route.meta;
      if (layout) return `${layout}-layout`;
      return "default-layout";
    },

    showWorkingTimerDialog() {
      return (
        !this.hasTimeSession &&
        !this.preventTimerDialog &&
        [USER_ROLE.EMP, USER_ROLE.DIR, USER_ROLE.ADM].includes(this.userRole)
      );
    },

    showTimerComponent() {
      return (
        this.hasTimeSession &&
        [USER_ROLE.EMP, USER_ROLE.DIR, USER_ROLE.ADM].includes(this.userRole)
      );
    },
  },
};
</script>

import i18n from "@/plugins/i18n";
import store from "@/store";

const getRole = () => {
  const localStorageUser = localStorage.getItem("user");
  if (localStorageUser) {
    const { role } = JSON.parse(localStorageUser);
    return role;
  }
  return "";
};

export default function role({ next, router, to }) {
  const role = getRole();
  if (!to.meta.role.includes(role)) {
    store.dispatch("states/openSnackbar", {
      title: i18n.t("No authorized"),
      message: i18n.t("You do not have permissions to access this section"),
      type: "error",
    });
    return router.push({ name: `${role}.dashboard` });
  }
  return next();
}

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import en from "vuetify/lib/locale/en";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

const opts = {
  icons: {
    iconfont: "mdi",
  },
  lang: {
    locales: { en },
    current: "en",
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: {
          base: "#B0853B", // 500
          lighten1: "#F5EEE1", // 100
          lighten2: "#86682D", // 800
          lighten3: "#DEC89C", // 200
          lighten4: "#58441D", // 900
        },
        secondary: {
          base: "#3978AC", // 500
          lighten1: "#E8F0F7", // 100
          lighten2: "#003865", // 800
        },
        neutral: {
          base: "#DCDDE8", // 500
          lighten1: "#FAFAFA", // 50
          lighten2: "#F1F2F6", // 100
          lighten3: "#BCBECD", // 900
          lighten4: "#8D91A5", // 400
          darken1: "#55596D", // 900
          darken2: "#292D45", // 900
        },
        success: {
          base: "#4C7836", // 500
          lighten1: "#4C7836", // 100
          lighten2: "#4C7836", // 800
          lighten3: "#335124", //
          lighten4: "#E7F4E1", //
        },
        error: {
          base: "#A63F3F", // 400
          lighten1: "#F8EDED", // 100
          lighten2: "#682727", // 800
        },
      },
    },
  },
};

export default new Vuetify(opts);

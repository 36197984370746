<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    :color="colorDefault"
    elevation="0"
    class="text-none v-btn-hover justify-center"
    :style="hoverStyleButton"
  >
    <v-icon :color="colorIcon" :small="!smallButton" v-if="showIconButton">
      {{ icon }}
    </v-icon>
    <span :class="[labelColor, textSizeButton]">
      {{ label }}
    </span>
    <v-icon :color="colorIcon" :small="!smallButton" v-if="!showIconButton">
      {{ icon }}
    </v-icon>
  </v-btn>
</template>

<script>
import { PropTypes } from "@/types";
import colors from "vuetify/lib/util/colors";
import { adjustColor } from "@/utils/adjustColor.utils";
import { capitalizeText } from "@/utils/capitalizeText.utils";
export default {
  name: "ButtonCustom",
  props: {
    label: PropTypes.string,
    icon: PropTypes.string,
    rightIcon: Boolean,
    colorIcon: PropTypes.string,
    colorLabel: PropTypes.string,
  },
  computed: {
    labelColor() {
      return this.colorLabel ? this.colorLabel : "";
    },
    smallButton() {
      return this.$attrs["small"] === undefined;
    },
    lighten() {
      return this.$attrs["lighten"] !== undefined;
    },
    showIconButton() {
      return this.icon && !this.rightIcon;
    },
    textSizeButton() {
      return this.smallButton
        ? "body2"
        : !this.lighten
        ? "subtitle2"
        : "subtitle2-white";
    },
    colorBase() {
      return this.$attrs["color"] || "primary";
    },
    themeColorButton() {
      return this.$vuetify.theme.themes.light[this.colorBase];
    },
    colorDefault() {
      const variantColor =
        this.$attrs["outlined"] === undefined ? "" : "lighten-2";
      return `${this.colorBase} ${variantColor}`;
    },

    hoverStyleButton() {
      if (this.themeColorButton) {
        return {
          "--button-bg-color":
            this.$attrs["outlined"] === undefined
              ? this.adjustColorTheme(-55)
              : this.adjustColorTheme(210),
        };
      }

      return {
        "--button-bg-color":
          this.$attrs["outlined"] === undefined
            ? this.adjustColorCustom(-55)
            : this.adjustColorCustom(210),
      };
    },
  },
  methods: {
    colorBaseCapitalize() {
      const splitNameColor = this.colorBase.split("-");
      if (splitNameColor.length > 1) {
        splitNameColor[1] = capitalizeText(splitNameColor[1]);
      }
      return splitNameColor.join("");
    },
    adjustColorTheme(amount) {
      return adjustColor(this.themeColorButton?.base || "white", amount);
    },
    adjustColorCustom(amount) {
      return adjustColor(
        colors[this.colorBaseCapitalize]?.base || "white",
        amount
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.v-btn-hover:hover {
  background: var(--button-bg-color) !important;
}
</style>

import router from "@/router";
import store from "@/store";
import axios from "axios";
import i18n from "@/plugins/i18n";
import { showNotificationError } from "@/utils";

const requestInterceptorToken = (config) => {
  const token = localStorage.getItem("access_token");
  config.headers["Content-Type"] = `application/json`;
  config.headers["X-Requested-With"] = `XMLHttpRequest`;
  if (token) config.headers["Authorization"] = `Bearer ${token}`;
  return config;
};

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
});
// This axios instance was made to avoid thestate loading interceptor...
export const axiosInstance2 = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
});
axiosInstance2.interceptors.request.use(requestInterceptorToken);

axiosInstance.interceptors.request.use(async (request) => {
  if (request.url == "/login") {
    await store.dispatch("states/setLoading", true);
  }
  return request;
});

axiosInstance.interceptors.response.use(
  async (response) => {
    setTimeout(() => {
      store.dispatch("states/setLoading", false);
    }, 600);
    return response;
  },
  async (error) => {
    if (
      (error.response.status === 401 &&
        error.response.data.detail !== "Incorrect username or password") ||
      error.response.data?.detail === "Invalid header padding"
    ) {
      store.commit("auth/setClearUser");
      await router.replace({ name: "login" });
      router.go();
    } else {
      setTimeout(() => {
        store.dispatch("states/setLoading", false);
      }, 600);
      const errorMessage = {
        title: i18n.t("There’s something wrong"),
        message: error.response.data.detail || error.message || i18n.t("Error"),
      };
      showNotificationError(errorMessage);
    }
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(requestInterceptorToken);

export default axiosInstance;

export const getIdsFromFilter = (array, label) => {
  const idsFilters = array.filter((item) => item[label]);
  const ids = idsFilters.reduce((acc, item) => {
    const a = item[label].reduce((acc, item) => {
      return acc.concat(item[0]);
    }, []);
    return acc.concat(a);
  }, []);
  return [ids];
};

export const mutations = {
  /**
   * @param State : state
   * @param {} storeSelected
   */
  setStoreSelected: (state, store) => {
    state.storeSelected = store;
  },
  /**
   * @param State : state
   * @param refreshStoreWrapper : boolean
   */
  setRefreshStoreWrapper: (state, refreshStoreWrapper) => {
    state.refreshStoreWrapper = refreshStoreWrapper;
  },
};

const docs = [
  {
    path: "/docs/",
    component: () => import("@/docs/layouts/layoutDocs.vue"),
    children: [
      {
        path: "/",
        name: "index.docs",
        component: () => import("@/docs/components/IndexDocs.vue"),
      },
    ],
  },
];

export default docs;

const compose = (field, values, op, field_model) =>
  values.map((value, index) => ({
    field,
    value,
    op,
    or_with_next: index < values.length - 1 || undefined,
    field_model,
  }));

const inFilter = (field, values, field_model) => ({
  field,
  field_model,
  value: values,
  op: "in",
});

const has_not = (field, values, fieldModel) =>
  compose(field, values, "has_not", fieldModel);

const equal = (field, values, fieldModel) =>
  compose(field, values, "==", fieldModel);

const notEqual = (field, values, fieldModel) =>
  compose(field, values, "!=", fieldModel);
const equalOrGreater = (field, values, fieldModel) =>
  compose(field, values, ">=", fieldModel);

const equalOrSmaller = (field, values, fieldModel) =>
  compose(field, values, "<=", fieldModel);

const like = (field, values, fieldModel) =>
  compose(field, values, "ilike", fieldModel);

const related = (model1, model2, values, reverse = false) => {
  const fieldModel = `${model1}${model2}link`;

  const model = reverse ? `${model1}_id` : `${model2}_id`;
  const baseModel = reverse ? `${model2}_id` : `${model1}_id`;

  const baseFilter = {
    field: "id",
    op: "==",
    value: baseModel,
    value_model: fieldModel,
  };

  const filters =
    values.length > 1
      ? [inFilter(model, values, fieldModel)]
      : equal(model, values, fieldModel);

  return [baseFilter, ...filters];
};

export const requestFilters = {
  compose,
  equal,
  equalOrGreater,
  equalOrSmaller,
  like,
  in: inFilter,
  related,
  has_not,
  notEqual,
};

<template>
  <v-card
    width="240px"
    height="100%"
    outlined
    rounded="0"
    class="v-card-main-navigation-drawer"
  >
    <v-navigation-drawer permanent>
      <template v-slot:prepend>
        <section
          class="c-list-item-profile-info d-flex flex-row align-center flex-column"
        >
          <main-logo class="mt-4" />
          <v-list-item two-line class="px-2">
            <v-list-item-avatar>
              <icon-avatar text="Ana" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                class="subtitle-1 white--text font-weight-bold"
              >
                {{ $t("navigation.hi") }} {{ user.name }}!
              </v-list-item-title>
              <v-list-item-subtitle class="subtitle-2 white--text font-italic">
                {{ user.user_role }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-menu v-model="isOptionsMenuProfile">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon class="white--text">mdi-menu-down</v-icon>
                  </v-btn>
                </template>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
          <transition>
            <div class="c-options-menu-profile" v-if="isOptionsMenuProfile">
              <v-list-item data-logout="logout" @click="onLogout()">
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{
                  $t("navigation.log_out")
                }}</v-list-item-title>
              </v-list-item>
            </div>
          </transition>
        </section>
      </template>

      <v-list>
        <template v-for="item in links">
          <v-list-item
            v-if="!item.links"
            :key="item.t"
            :to="{ name: item.to }"
            link
            exact
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{ $t(item.t) }}</v-list-item-title>
          </v-list-item>
          <v-list-group
            v-else
            :key="item.t"
            :prepend-icon="item.icon"
            no-action
            active-class="ac"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ $t(item.t) }}</v-list-item-title>
            </template>
            <v-list-item
              v-for="item in item.links"
              :key="item.t"
              :to="{ name: item.to }"
              link
              exact
            >
              <v-list-item-title v-text="$t(item.t)"></v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>

        <v-divider class="my-4"></v-divider>

        <v-list-group
          v-for="item in groups"
          :key="item.t"
          :prepend-icon="item.icon"
          no-action
          active-class="ac"
        >
          <template v-slot:activator>
            <v-list-item-title>{{ $t(item.t) }}</v-list-item-title>
          </template>
          <v-list-item
            v-for="item in item.links"
            :key="item.t"
            :to="{ name: item.to }"
            link
            exact
          >
            <v-list-item-title v-text="$t(item.t)"></v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import IconAvatar from "@/components/icons/IconAvatar";
import MainLogo from "@/components/general/Logo.vue";
import authService from "@/services/auth";

export default {
  name: "MainNavigationDrawer",
  components: { IconAvatar, MainLogo },

  props: {
    links: { type: Array, default: () => [] },
    groups: { type: Array, default: () => [] },
  },

  data() {
    return {
      isOptionsMenuProfile: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      setClearUser: "auth/setClearUser",
      setClearTimer: "timeTracker/clearTimer",
      stopWork: "timeTracker/stopWork",
    }),
    async onLogout() {
      try {
        await this.stopWork();
        await authService.logout();
      } finally {
        await this.setClearUser();
        this.setClearTimer();
        await this.$router.push("/login");
      }
    },
  },
};
</script>

<style lang="scss">
.v-card-main-navigation-drawer {
  /* Neutros/200 */
  border: 1px solid $color-neutral-500 !important;
  position: relative;
  z-index: +99999999;
  .v-navigation-drawer__prepend {
    .c-list-item-profile-info {
      background: $color-neutral-900-4 !important;
      padding: 8px;
      gap: 16px;
      .subtitle-2 {
        /* Neutros/300 */
        color: $color-neutral-900-1 !important;
        font-size: 12px !important;
      }
    }

    .c-options-menu-profile {
      width: 100%;
      box-shadow: 0px 4px 4px rgba(85, 89, 109, 0.2);
      border-radius: 0px 0px 2px 2px;
      background: $color-neutral-900-4;
      .v-list-item {
        .v-list-item__title {
          color: $color-neutral-100;
          font-size: 14px;
        }
        .v-list-item__icon {
          align-self: auto;
          margin: 0 10px 0 0;
          i {
            color: $color-neutral-100;
          }
        }
      }
    }
  }

  .v-navigation-drawer__content {
    .v-list-item {
      padding: 0 10px;
      border-radius: 0px;
      /* Neutros/400 */
      color: $color-neutral-900-2 !important;

      &::before {
        border-radius: 0px;
      }

      .v-list-item__title {
        font-weight: 400;
        font-size: 16px;
        color: $color-neutral-900-2;
      }
      .v-list-item__icon {
        margin-right: 10px;
        margin-left: 0px;
      }
    }

    a.v-list-item--active {
      /* Primario/500 */
      border-left: 1px solid $color-primary-500;
      background: rgba(245, 238, 225, 0.5);

      /* Primario/500 */
      color: $color-primary-500 !important;

      .v-list-item__title {
        font-weight: 500;
      }
    }
  }
}
</style>

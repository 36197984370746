<template>
  <text-field-default
    ref="field"
    v-bind="$attrs"
    v-on="$listeners"
    @input="onInputTextField"
    @blur="verifyFieldError"
    data-input="custom"
    :append-icon="appendIconComputed"
    class="pt-0 mt-0"
  />
</template>

<script>
import { PropTypes } from "@/types";
import TextFieldDefault from "./TextFieldDefault.vue";

export default {
  name: "TextFieldCustom",
  components: {
    TextFieldDefault,
  },
  props: {
    appendIcon: PropTypes.string,
  },
  data() {
    return {
      errorField: false,
    };
  },
  computed: {
    appendIconComputed() {
      if (this.errorField) {
        return "mdi-alert-circle";
      }
      return this.appendIcon ? this.appendIcon : "";
    },
  },
  methods: {
    verifyFieldError() {
      setTimeout(() => {
        this.errorField = !!this.$refs.field.$children[0].errorBucket.length;
      }, 0);
    },
    onInputTextField(value) {
      this.verifyFieldError();
      this.$emit("input", value);
    },
  },
};
</script>

<style></style>

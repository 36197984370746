<template>
  <v-main class="cont-card">
    <ResetPasswordForm />
  </v-main>
</template>

<script>
import ResetPasswordForm from "@/components/auth/ResetPasswordForm";

export default {
  name: "ResetPasswordView",
  components: { ResetPasswordForm },
  methods: {},
};
</script>

<style scoped lang="scss"></style>

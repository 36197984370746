export const STATUS = {
  PENDING: "pending",
  PENDING_ACCEPT: "pending accept",
  PENDING_CANCEL: "pending cancel",
  APPROVED: "approved",
  ACCEPTED: "accepted",
  REJECTED: "rejected",
  RETURNED: "returned",
  CANCELED: "canceled",
  FINISHED: "finished",
  EMPTY: "empty",
  RENTED: "rented",
  SIGNED: "signed",
  OPEN: "open",
  LOI: "LOI",
  UNDER_NEGOTIATION: "under negotiation",
  CLOSE: "close",
  CLOSED_CONTRACT: "closed contract",
  NOT_ASSIGNED: "not assigned",
  PUBLISHED: "published",
  DRAFT: "draft",
};

export const useSortByPagination = (pagination, field = "updated_at") => {
  const { sortBy, sortDesc } = pagination;

  const tableSort =
    sortBy && sortBy.length > 0
      ? sortBy.map((item, idx) => {
          return {
            field: item,
            desc: sortDesc[idx],
          };
        })
      : [{ field, desc: true }];

  return tableSort;
};

export const useParamsPagination = (pagination) => {
  const { page, itemsPerPage } = pagination;

  return {
    page,
    itemsPerPage,
    size: itemsPerPage,
  };
};

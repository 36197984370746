export const MODEL = {
  ABSENCE: "absence",
  ABSENCE_SEARCH: "absence/search",
  ACCOUNT: "account",
  ACCOUNTING_ENTRY: "accountingentry",
  ACCOUNTING_MOVE: "accountingmove",
  ALERT: "alert",
  BUDGET: "budget",
  USER_ACTION_LINK: "useractionlink",
  CATEGORY: "category",
  USER_DEPARTMENT_LINK: "userdepartmentlink",
  DEPARTMENT: "department",
  HISTORY: "history",
  PERMISSION: "permission",
  RULE: "rule",
  USER: "user",
  PROJECT: "project",
  INVOICE: "invoice",
  TENANT: "entity",
  TERMS: "invoicingterm",
  STORE: "ubication",
  FLOOR: "floor",
  BUSINESS_UNIT: "businessunit",
  TENANT_CATEGORY: "tenantcategory",
  SUPPLIER: "supplier",
  ACCOUNT_PROJECT_LINK: "accountprojectlink",
  FILE_STORE: "filestore",
  CONTRACT: "contract",
  YEARSTATISTIC: "yearstatistic",
  EVENT: "event",
  POST: "post",
  NEWS: "news",
  ENTITY_ESTIMATION: "entityestimation",
  JOBRECORD: "jobrecord",
  MANDATORYFILE: "mandatoryfile",
  MANDATORYFOLDER: "mandatoryfolder",
};
export const CREATED_MODEL = {
  BUDGETHISTORY: {
    model: MODEL.BUDGET,
    label: "budgethistory",
  },
  INVOICEHISTORY: {
    model: MODEL.INVOICE,
    label: "invoicehistory",
  },
  PROJECTHISTORY: {
    model: MODEL.PROJECT,
    label: "projecthistory",
  },
  TENANTHISTORY: {
    model: MODEL.TENANT,
    label: "tenanthistory",
  },
  PENDINGABSENCE: {
    model: MODEL.ABSENCE_SEARCH,
    label: "pendingabsence",
  },
  ABSENCE_BY_USER_ID: {
    model: MODEL.ABSENCE,
    label: "absencebyuserid",
  },
};

import moment from "moment";
import { MONTHS, ESTIMATION_MONTHS } from "@/utils/constants";
import { chartColors, getValNumber } from "@/utils";

export const getters = {
  getProjectViewSelected: ({ projectViewSelected }) => {
    if (!projectViewSelected) return null;
    return { ...projectViewSelected };
  },
  getProjectId: ({ projectViewSelected }) => {
    if (!projectViewSelected) return null;
    return projectViewSelected.id;
  },
  isProjectEstimated: ({ projectViewSelected }) => {
    if (!projectViewSelected) return null;
    const startDate = moment(projectViewSelected.start_date);
    const expiredDate = moment(projectViewSelected.expired_date);
    const totalMonths = expiredDate.diff(startDate, "months", false) + 1;
    let countEstimatedMounts = 0;
    if (startDate.year() == expiredDate.year()) {
      for (let i = startDate.month(); i <= expiredDate.month(); i++) {
        if (projectViewSelected[`estimation_${MONTHS[i]}`] !== null) {
          countEstimatedMounts++;
        }
      }
      return totalMonths == countEstimatedMounts;
    } else {
      for (let i = 0; i < totalMonths; i++) {
        const newDate = expiredDate.add(i == 0 ? 0 : -1, "months");
        if (
          projectViewSelected[
            `estimation_${newDate.format("MMM").toLowerCase()}`
          ] !== null
        ) {
          countEstimatedMounts++;
        }
      }
      return totalMonths == countEstimatedMounts;
    }
  },
  monthRange: ({ projectViewSelected }) => {
    if (!projectViewSelected) return null;
    let months = [];
    const startDate = moment(projectViewSelected.start_date);
    const endDate = moment(projectViewSelected.expired_date);
    const totalMonths = endDate.diff(startDate, "months", false);
    for (let i = 0; i <= totalMonths; i++) {
      const newMonth = startDate.add(i == 0 ? 0 : 1, "months");
      months.push({
        id: i,
        date: newMonth.format("YYYY-MM-DD"),
        month: newMonth.month(),
        year: newMonth.year(),
        monthName: newMonth.format("MMM").toLowerCase(),
      });
    }
    return months;
  },
  getProjectYear: ({ projectViewSelected }) => {
    if (!projectViewSelected) return null;
    return {
      startYear: moment(projectViewSelected.start_date).year(),
      endYear: moment(projectViewSelected.expired_date).year(),
    };
  },
  getMonthsEstimated: ({ projectViewSelected }) => {
    if (!projectViewSelected) return null;

    let estimatedMonths = [];
    /** [{}] */
    estimatedMonths = ESTIMATION_MONTHS.map((item, index) => {
      const estimated = projectViewSelected[item]
        ? projectViewSelected[item]
        : 0;
      const real_amount = projectViewSelected[`paid_${MONTHS[index]}`];
      const difference = estimated - real_amount;
      const year = projectViewSelected.year;
      return {
        id: index + 1,
        year,
        month: `${MONTHS[index]}`,
        estimated_amount: estimated,
        real_amount,
        difference,
      };
    });
    // Sum each data of estimated_amount, real_amount, difference
    const totalAmounts = estimatedMonths.reduce(
      (acumulador, actual) => {
        return {
          total_amount: "Total amount",
          estimated_amount:
            acumulador.estimated_amount + actual.estimated_amount,
          real_amount: acumulador.real_amount + actual.real_amount,
          difference: acumulador.difference + actual.difference,
        };
      },
      { estimated_amount: 0, real_amount: 0, difference: 0 }
    );
    estimatedMonths.push(totalAmounts);
    /*
    const startDate = moment(projectViewSelected.start_date);
    const expiredDate = moment(projectViewSelected.expired_date);
    const totalMonths = expiredDate.diff(startDate, "months", false) + 1;
    let count = 1;
    if (startDate.year() == expiredDate.year()) {
      for (let i = startDate.month(); i <= expiredDate.month(); i++) {
        if (projectViewSelected[`estimation_${MONTHS[i]}`] !== null) {
          const estimated = projectViewSelected[`estimation_${MONTHS[i]}`];
          const paid = projectViewSelected[`paid_${MONTHS[i]}`];
          const difference = parseInt(estimated) - parseInt(paid);

          estimatedMonths.push({
            id: count,
            year: startDate.year(),
            month: `${MONTHS[i]}`,
            estimated_amount: estimated,
            real_amount: paid,
            difference: difference,
          });
          count++;
        }
      }
    } else {
      for (let i = 0; i < totalMonths; i++) {
        const newDate = expiredDate.add(i == 0 ? 0 : -1, "months");
        if (
          projectViewSelected[
            `estimation_${newDate.format("MMM").toLowerCase()}`
          ] !== null
        ) {
          const estimated =
            projectViewSelected[
              `estimation_${newDate.format("MMM").toLowerCase()}`
            ];
          const paid =
            projectViewSelected[
              `estimation_${newDate.format("MMM").toLowerCase()}`
            ];
          const difference = parseInt(estimated) - parseInt(paid);

          estimatedMonths.push({
            id: count,
            year: newDate.year(),
            month: `${newDate.format("MMM").toLowerCase()}`,
            estimated_amount: estimated,
            real_amount: paid,
            difference: difference,
          });
          count++;
        }
      }
    }
    */
    return estimatedMonths;
  },
  getDataMonthly: ({ projectViewSelected }) => {
    if (!projectViewSelected) return null;
    return [
      {
        label: "Real amount",
        color: chartColors.primary,
        data: [
          {
            value: projectViewSelected["paid_jan"],
            label: "JAN",
          },
          {
            value: projectViewSelected["paid_feb"],
            label: "FEB",
          },
          {
            value: projectViewSelected["paid_mar"],
            label: "MAR",
          },
          {
            value: projectViewSelected["paid_apr"],
            label: "APR",
          },
          {
            value: projectViewSelected["paid_may"],
            label: "MAY",
          },
          {
            value: projectViewSelected["paid_jun"],
            label: "JUN",
          },
          {
            value: projectViewSelected["paid_jul"],
            label: "JUL",
          },
          {
            value: projectViewSelected["paid_aug"],
            label: "AUG",
          },
          {
            value: projectViewSelected["paid_sep"],
            label: "SEP",
          },
          {
            value: projectViewSelected["paid_oct"],
            label: "OCT",
          },
          {
            value: projectViewSelected["paid_nov"],
            label: "NOV",
          },
          {
            value: projectViewSelected["paid_dec"],
            label: "DEC",
          },
        ],
      },
      {
        label: "Estimated",
        color: chartColors.secondary,
        data: [
          {
            value: getValNumber(projectViewSelected["estimation_jan"]),
            label: "JAN",
          },
          {
            value: getValNumber(projectViewSelected["estimation_feb"]),
            label: "FEB",
          },
          {
            value: getValNumber(projectViewSelected["estimation_mar"]),
            label: "MAR",
          },
          {
            value: getValNumber(projectViewSelected["estimation_apr"]),
            label: "APR",
          },
          {
            value: getValNumber(projectViewSelected["estimation_may"]),
            label: "MAY",
          },
          {
            value: getValNumber(projectViewSelected["estimation_jun"]),
            label: "JUN",
          },
          {
            value: getValNumber(projectViewSelected["estimation_jul"]),
            label: "JUL",
          },
          {
            value: getValNumber(projectViewSelected["estimation_aug"]),
            label: "AUG",
          },
          {
            value: getValNumber(projectViewSelected["estimation_sep"]),
            label: "SEP",
          },
          {
            value: getValNumber(projectViewSelected["estimation_oct"]),
            label: "OCT",
          },
          {
            value: getValNumber(projectViewSelected["estimation_nov"]),
            label: "NOV",
          },
          {
            value: getValNumber(projectViewSelected["estimation_dec"]),
            label: "DEC",
          },
        ],
      },
      {
        label: "Difference",
        color: chartColors.gray,
        data: [
          {
            value:
              getValNumber(projectViewSelected["estimation_jan"]) -
              getValNumber(projectViewSelected["paid_jan"]),
            label: "JAN",
          },
          {
            value:
              getValNumber(projectViewSelected["estimation_feb"]) -
              getValNumber(projectViewSelected["paid_feb"]),
            label: "FEB",
          },
          {
            value:
              getValNumber(projectViewSelected["estimation_mar"]) -
              getValNumber(projectViewSelected["paid_mar"]),
            label: "MAR",
          },
          {
            value:
              getValNumber(projectViewSelected["estimation_apr"]) -
              getValNumber(projectViewSelected["paid_apr"]),
            label: "APR",
          },
          {
            value:
              getValNumber(projectViewSelected["estimation_may"]) -
              getValNumber(projectViewSelected["paid_may"]),
            label: "MAY",
          },
          {
            value:
              getValNumber(projectViewSelected["estimation_jun"]) -
              getValNumber(projectViewSelected["paid_jun"]),
            label: "JUN",
          },
          {
            value:
              getValNumber(projectViewSelected["estimation_jul"]) -
              getValNumber(projectViewSelected["paid_jul"]),
            label: "JUL",
          },
          {
            value:
              getValNumber(projectViewSelected["estimation_aug"]) -
              getValNumber(projectViewSelected["paid_aug"]),
            label: "AUG",
          },
          {
            value:
              getValNumber(projectViewSelected["estimation_sep"]) -
              getValNumber(projectViewSelected["paid_sep"]),
            label: "SEP",
          },
          {
            value:
              getValNumber(projectViewSelected["estimation_oct"]) -
              getValNumber(projectViewSelected["paid_oct"]),
            label: "OCT",
          },
          {
            value:
              getValNumber(projectViewSelected["estimation_nov"]) -
              getValNumber(projectViewSelected["paid_nov"]),
            label: "NOV",
          },
          {
            value:
              getValNumber(projectViewSelected["estimation_dec"]) -
              getValNumber(projectViewSelected["paid_dec"]),
            label: "DEC",
          },
        ],
      },
    ];
  },

  hasProjectStatus:
    ({ projectViewSelected }) =>
    (status = [], project) => {
      const { status: projectStatus } = project || projectViewSelected;
      return status.includes(projectStatus);
    },
};

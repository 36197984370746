export const user = {
  id: "",
  created_at: "",
  created_id: "",
  updated_at: "",
  updated_id: "",
  active: false,
  email: "",
  name: "",
};
export const verifyLocalStorage = (value = "user") => {
  const storageUser = localStorage.getItem(value);
  if (storageUser) {
    return JSON.parse(storageUser);
  }
  return storageUser;
};
const state = {
  access_token: localStorage.getItem("access_token") || "",
  user: { ...verifyLocalStorage(), ...user },
  token_type: "",
  userRole: localStorage.getItem("USER_ROLE_SELECTED"),
};

const getters = {
  isAdmin: (state) => {
    return state.user.user_role === "administrator";
  },
  isDirector: (state) => {
    return state.user.user_role === "director";
  },
  isEmployee: (state) => {
    return state.user.user_role === "employee";
  },
  isFinance: (state) => {
    return state.user.user_role === "finance";
  },
  user: (state) => {
    return state.user;
  },
  role: (state) => {
    return state.user.role;
  },
  user_role: (state) => {
    return state.user.user_role;
  },
  roleSelected: (state) => {
    return state.userRole;
  },
};

const mutations = {
  /**
   * @param State : state
   * @param {} auth
   */
  setAuth: (state, auth) => {
    state.access_token = auth.access_token;
    state.user = auth.user;
    if (auth.access_token)
      localStorage.setItem("access_token", auth.access_token);
    if (auth.user)
      localStorage.setItem(
        "user",
        JSON.stringify({ role: auth.user.user_role })
      );
  },
  setUser: (state, user) => {
    state.user = { ...verifyLocalStorage(), ...user };
    if (user) {
      localStorage.setItem(
        "user",
        JSON.stringify({ role: user.user_role, id: user.id })
      );
      localStorage.setItem("USER_ROLE_SELECTED", user.user_role);
    }
  },
  setClearUser: (state) => {
    localStorage.clear();
    state.user = user;
  },
};

const actions = {
  /**
   * @param context : ActionContext<Store>
   * @param {} auth
   */
  setAuth: (context, auth) => {
    context.commit("setAuth", auth);
  },
  setUser: (context, user) => {
    context.commit("setUser", user);
  },
  setClearUser: (context) => {
    context.commit("setClearUser");
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

// import axiosInstance from "@/plugins/axios";
import axiosInstance from "@/plugins/axios";
import i18n from "@/plugins/i18n";
import {
  requestFilters,
  showNotificationError,
  paginationDefault,
  CREATED_MODEL,
  handlerErrorMessage,
} from "@/utils";
import { MODEL, STATUS } from "@/utils/constants";
import genericModels from "@/services/genericModels";

export default {
  /**
   * This method is created to know if there are already an accepted budget in th
   * @param { department_id: string, start_date: string, expired_date: string} filterObj
   * @returns Promise<Budget[]>
   */
  async getCheckedBudget(filterObj) {
    try {
      const { department_id } = filterObj;
      const status = STATUS.APPROVED || "approved";
      const model = MODEL.BUDGET || "budget";
      const filters = [
        ...requestFilters.equal("status", [status]),
        ...requestFilters.equal("department_id", [department_id]),
      ];
      const { data } = await axiosInstance.put(model, { filters });
      const isValid = data?.items.length === 0;
      return isValid;
    } catch (error) {
      const errorObj = {
        title: i18n.t("There’s something wrong"),
        message: error.message || i18n.t("Error"),
      };
      showNotificationError(errorObj);
    }
  },
  /**
   * @param idBudget : string
   */
  async getBudgetHistory(idBudget) {
    if (!idBudget) return;
    const filters = {
      filters: [...requestFilters.equal("id", [idBudget || ""])],
      sort_by: [{ field: "updated_at", desc: true }],
      show_inactives: true,
    };
    const relatedFields = [
      {
        model: "user",
        id_model: "updated_id",
        fields: ["business_position", "name"],
      },
      {
        model: "user",
        id_model: "created_id",
        fields: ["business_position", "name"],
      },
    ];
    const model = CREATED_MODEL.BUDGETHISTORY;
    await genericModels.get(model, {
      related_fields: relatedFields,
      params: {
        ...paginationDefault,
      },
      filters,
    });
  },
  async getPlots(year) {
    try {
      const payload = {
        plots: ["total_expenses"],
        plots_params: {},
        start_date: `${year}-01-01`,
        end_date: `${year}-12-31`,
      };
      const { data } = await axiosInstance.post(
        `${MODEL.BUDGET}/model_op/plot`,
        payload
      );
      return data;
    } catch (error) {
      return handlerErrorMessage(error);
    }
  },
  /**
   * This function returns the data for the graphic on budget detail.
   * @param idBudget : string
   */
  async getPlotsEstimatedReal(idBudget) {
    if (!idBudget) return;
    try {
      const { data } = await axiosInstance.post(
        `${MODEL.BUDGET}/op/plot_estimated_real?model_ids=${idBudget}`
      );
      return data;
    } catch (error) {
      return handlerErrorMessage(error);
    }
  },
};

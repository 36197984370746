const state = {
  rules: [],
};

const getters = {};

const mutations = {
  /**
   * @param State : state
   * @param {} auth
   */
  setRulesList: (state, rules) => {
    state.rules = rules.items;
  },
};

const actions = {
  /**
   * @param context : ActionContext<Store>
   * @param {} auth
   */
  set_rules_list: (context, rules) => {
    context.commit("setRulesList", rules);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

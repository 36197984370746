import { USER_ROLE } from "@/utils/constants";
const role = [USER_ROLE.ADM, USER_ROLE.EMP, USER_ROLE.DIR];
const layout = "admin";
import authMiddleware from "@/middlewares/auth";
import roleMiddleware from "@/middlewares/role";

const metaFiles = {
  role,
  layout,
  middleware: [authMiddleware, roleMiddleware],
};

const files = [
  {
    path: "/files/shared-files",
    name: "files.shared_files",
    component: () => import("@/views/files/SharedFiles.vue"),
    meta: metaFiles,
  },
  {
    path: "/files/shared-files/folder/:id",
    name: "files.shared_files.folder_details",
    component: () => import("@/views/files/SharedFilesFolderDetails.vue"),
    meta: metaFiles,
  },
  {
    path: "/files/shared-files/mandatory-files/:id",
    name: "files.mandatory-files.file_details",
    component: () => import("@/views/files/MandatoryFileDetails.vue"),
    meta: metaFiles,
  },
  {
    path: "/files/shared-files/mandatory-files",
    name: "files.mandatory-files",
    component: () => import("@/views/files/MandatoryFiles.vue"),
    meta: metaFiles,
  },
  {
    path: "/files/my-files",
    name: "files.my_files",
    component: () => import("@/views/files/MyFiles.vue"),
    meta: metaFiles,
  },
  {
    path: "/files/my-files/folder/:id",
    name: "files.my_files.folder_details",
    component: () => import("@/views/files/MyFilesFolderDetails.vue"),
    meta: metaFiles,
  },
];

export default files;

export const getters = {
  yearstatisticSelected: (state) => {
    return state.yearstatisticSelected;
  },
  monthlysaleSelected: (state) => {
    return state.monthlysaleSelected;
  },
  dataGraphics: (state) => {
    return state.dataGraphics;
  },
};

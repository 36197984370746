import axiosInstance, { axiosInstance2 } from "@/plugins/axios";
import store from "@/store";
import i18n from "@/plugins/i18n";
import {
  handlerErrorMessage,
  handlerSuccessMessage,
  setStoreListModel,
  setStoreModelSchema,
  cleanParamsOrFilters,
  getParamsOrFilters,
  getRelatedFieldsModel,
  getModelString,
  showNotificationError,
  fieldsMapToString,
} from "@/utils";

const get = async (model, payload, returnValue = false) => {
  try {
    const params = getParamsOrFilters(
      getModelString(model, "label"),
      "params",
      payload
    );
    const filters = getParamsOrFilters(
      getModelString(model, "label"),
      "filters",
      payload
    );
    let fields = "";
    if (payload?.fields) {
      fields = payload.fields.length
        ? fieldsMapToString({ fields: payload.fields })
        : "";
    }
    let { data } = await axiosInstance.put(
      `${getModelString(model, "model")}${fields}`,
      filters,
      params
    );
    if (payload && payload.related_fields?.data !== undefined) {
      const info = {
        related_fields: payload.related_fields.data,
        data,
        pagination: payload.related_fields.pagination,
      };
      data.items = await getRelatedFieldsModel(info);
    }

    if (returnValue) {
      return { data, success: true };
    } else {
      setStoreListModel(getModelString(model, "label"), data);
    }
  } catch (error) {
    console.log("error ", error);
    return handlerErrorMessage(error);
  }
};

const simpleGet = async (model) => {
  try {
    const { data } = await axiosInstance.get(model);
    setStoreListModel(model, data);
    return data;
  } catch (error) {
    return handlerErrorMessage(error);
  }
};

const read = async (model, model_id) => {
  try {
    const { data } = await axiosInstance2.get(`${model}/${model_id}`);
    return data;
  } catch (error) {
    return handlerErrorMessage(error);
  }
};

const postOperation = async (
  model,
  operationType = null,
  model_ids = [],
  getModel = true,
  payload,
  successMsg = "The {} have been created successfully",
  showMessage = true
) => {
  try {
    let modelIdsText = model_ids
      .map((item, index) => {
        const q = index === 0 ? "?" : "&";
        return `${q}model_ids=${item}`;
      })
      .join("");
    const { data } = await axiosInstance.post(
      `${model}/op/${operationType}${modelIdsText}`,
      payload
    );
    if (getModel) {
      const params = store.state.generic[`${model}_params`] || null;
      get(model, { params });
    }
    if (showMessage) {
      handlerSuccessMessage(i18n.t(successMsg, { model }));
    }
    return { data, success: true };
  } catch (error) {
    const possibleErrors = [
      error?.response?.data?.detail,
      error?.detail,
      error?.message,
      i18n.t("Error"),
    ];
    const errorMsg = possibleErrors.find((item) => item !== undefined);

    const errorObj = {
      title: i18n.t("There’s something wrong"),
      message: errorMsg,
    };
    showNotificationError(errorObj);
    return handlerErrorMessage(errorMsg);
  }
};
/**
 * Make general post operations...
 * @param { model: string, operationType: string, model_ids = string[], payload: any } item
 * @returns data: any
 */
const postOperationGeneral = async (data) => {
  const { model, operationType = "", modelIds = [], payload } = data;
  try {
    let modelIdsText = modelIds
      .map((item, index) => {
        const q = index === 0 ? "?" : "&";
        return `${q}model_ids=${item}`;
      })
      .join("");
    const { data } = await axiosInstance.post(
      `${model}/op/${operationType}${modelIdsText}`,
      payload
    );
    return { data, success: true };
  } catch (error) {
    let errorMsg = error.detail
      ? error.detail
      : error.message
      ? error.message
      : i18n.t("Error");
    if (error?.response?.data?.detail) {
      errorMsg = i18n.t(error.response.data.detail);
    }
    const errorObj = {
      title: i18n.t("There’s something wrong"),
      message: errorMsg,
    };
    showNotificationError(errorObj);
    return handlerErrorMessage(error);
  }
};
const postModelOperation = async (model, operationType = null, payload) => {
  try {
    const { data } = await axiosInstance.post(
      `${model}/model_op/${operationType}`,
      payload
    );
    // if (getModel) {
    //   const params = store.state.generic[`${model}_params`] || null;
    //   get(model, { params });
    // }
    // handlerSuccessMessage(i18n.t(successMsg, { model }));
    return { data, success: true };
  } catch (error) {
    let errorMsg = error.detail
      ? error.detail
      : error.message
      ? error.message
      : i18n.t("Error");
    if (error?.response?.data?.detail) {
      errorMsg = i18n.t(error.response.data.detail);
    }
    const errorObj = {
      title: i18n.t("There’s something wrong"),
      message: errorMsg,
    };
    showNotificationError(errorObj);
    return handlerErrorMessage(error);
  }
};

const postOperationGetFiles = async (model, model_ids = []) => {
  try {
    let modelIdsText = model_ids
      .map((item, index) => {
        const q = index === 0 ? "?" : "&";
        return `${q}model_ids=${item}`;
      })
      .join("");
    const { data } = await axiosInstance.post(
      `${model}/op/get_file${modelIdsText}`
    );
    return { data, success: true };
  } catch (error) {
    return handlerErrorMessage(error);
  }
};

const post = async (model, payload, getModel = true) => {
  try {
    const { data } = await axiosInstance.post(model, payload);
    if (getModel) {
      const params = store.state.generic[`${model}_params`] || null;
      get(model, { params });
    }
    handlerSuccessMessage(
      i18n.t("The {} have been created successfully", { model })
    );
    return { data, success: true };
  } catch (error) {
    return handlerErrorMessage(error);
  }
};

const put = async (model, { model_id, payload }, getModel = true) => {
  try {
    const { data } = await axiosInstance.patch(
      `${getModelString(model, "model")}/${model_id}`,
      payload
    );
    if (data.status === "close") {
      await axiosInstance.post(
        `${getModelString(model, "model")}/op/set_close?model_ids=${model_id}`
      );
    }
    if (getModel) {
      get(model);
    }
    handlerSuccessMessage(
      i18n.t("The {} have been updated successfully", { model })
    );
    return { data, success: true };
  } catch (error) {
    return handlerErrorMessage(error);
  }
};

const remove = async (model, model_id) => {
  try {
    await axiosInstance.delete(`${model}/${model_id}`);
    get(model);
    handlerSuccessMessage(
      i18n.t("The {} have been removed successfully", { model })
    );
  } catch (error) {
    return handlerErrorMessage(error);
  }
};

const getModel = async (model) => {
  try {
    const { data } = await axiosInstance.get(`${model}/schema`);
    setStoreModelSchema(model, data);
    return data;
  } catch (error) {
    return handlerErrorMessage(error);
  }
};

export default {
  get,
  post,
  put,
  remove,
  read,
  simpleGet,
  getModel,
  cleanParamsOrFilters,
  postOperation,
  postOperationGeneral,
  postOperationGetFiles,
  postModelOperation,
};

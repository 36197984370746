import i18n from "@/plugins/i18n";

export const rulesForm = () => ({
  required: (value) =>
    (Array.isArray(value) ? value.length > 0 : !!value) ||
    i18n.tc("rules.required", 1),
  min: (value, min) =>
    (value && value.length >= min) || i18n.t("rules.min", { min }),
  max: (value, max) =>
    (value && value.length <= max) || i18n.t("rules.max", { max }),
  email: (value) => /.+@.+\..+/.test(value) || i18n.t("rules.email"),
  oneDigit: (value) => (value && /\d/.test(value)) || "At least one digit",
  oneCapitalize: (value) =>
    (value && /[A-Z]{1}/.test(value)) || "At least one capital latter",
  oneSpecialCharacter: (value) =>
    (value && /[^A-Za-z0-9]/.test(value)) || "At least one special character",
  phone: (value) => {
    const regex = /^\(?([0-9]{4})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
    return regex.test(value) || "Invalid number phone";
  },
  number: (v) => parseFloat(v) > 0 || "Number has required",
});

<template>
  <section class="forgot-password-wrapper">
    <v-card elevation="0" class="forgot-password-wrapper__card">
      <v-form v-model="valid" @submit.prevent="forgotPassword" data-test="form">
        <v-container>
          <h2 class="forgot-password-wrapper__card__h2">
            {{ $t("auth.enter_email") }}
          </h2>
          <p>
            {{ $t("auth.we_send_change_password") }}
          </p>
          <v-row class="my-3">
            <v-col cols="12" md="12">
              <text-field-default
                v-model="form.email"
                name="password"
                class="text-field-custom"
                :rules="emailRules"
                :label="$t('auth.business_email')"
                type="email"
                data-test="mail"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <button-custom
                block
                :label="$t('Confirm')"
                type="submit"
                :disabled="!valid"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </section>
</template>

<script>
import TextFieldDefault from "@/components/forms/TextFieldDefault";
import ButtonCustom from "@/components/buttons/ButtonCustom";
import auth from "@/services/auth";
import { rulesForm } from "@/utils/rulesForm.utils";
import { mapActions } from "vuex";

const { required, email } = rulesForm();

export default {
  name: "ForgotPasswordForm",
  components: {
    TextFieldDefault,
    ButtonCustom,
  },
  data: () => ({
    valid: false,
    form: {
      email: null,
    },
    emailRules: [required, email],
  }),
  methods: {
    ...mapActions({
      openSnackbar: "states/openSnackbar",
    }),
    async forgotPassword() {
      try {
        await auth.forgotPassword({ email: this.form.email });
        this.openSnackbar({
          title: this.$t("Email sent"),
          message: this.$t("The email has been send"),
          type: "success",
        });
        await this.$router.push({ name: "login" });
      } catch (error) {
        this.openSnackbar({
          title: this.$t("There’s something wrong"),
          message:
            error.message ||
            this.$t("Please, check your mail and try it again"),
          type: "error",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.forgot-password-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  &__card {
    width: 550px;
    padding: 30px;
    &__h2 {
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }
}
</style>

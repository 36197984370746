import axiosInstance from "@/plugins/axios";
import store from "@/store";
import i18n from "@/plugins/i18n";
import { requestFilters } from "./requestFilters.utils";

export const handlerErrorMessage = (error) => {
  throw new Error(error);
};

export const handlerSuccessMessage = (message) => {
  store.dispatch("states/openSnackbar", {
    title: i18n.t("Success"),
    message: message,
    type: "success",
  });
};

export const setStoreListModel = (model, data) => {
  store.dispatch("generic/setModelList", { model, data });
};

export const setStoreModelSchema = (model, data) => {
  let name = data.name;
  store.dispatch("generic/setModelSchema", { model, data, name });
};

export const setParamsStoreListModel = (model, payload, label) => {
  store.dispatch("generic/setParamsOrFiltersModelList", {
    model,
    payload,
    label,
  });
};

export const cleanParamsOrFilters = (model, label) => {
  store.dispatch("generic/setParamsOrFiltersModelList", {
    model,
    payload: null,
    label,
  });
};

export const getParamsOrFilters = (model, label, payload) => {
  let result;
  const dynamicField = `${label}`;
  const { [dynamicField]: dynamicLabel } = payload || {};
  if (!dynamicLabel) {
    result = store.state.generic[`${model}_${dynamicField}`] || null;
  } else {
    result = dynamicLabel;
    setParamsStoreListModel(model, dynamicLabel, label);
  }
  return result;
};

export const getStringModel = (idModel) => {
  if (idModel) {
    if (typeof idModel === "string" || typeof idModel === "number") {
      return idModel.toString();
    } else if (idModel[0]) {
      return idModel[0].toString();
    } else {
      return null;
    }
  }
  return null;
};
/**
 * @param related_fields : Object[]
 * @returns string
 */
export const fieldsMapToString = (related_fields) => {
  const related_fields_map = [...related_fields.fields, "id"];
  const fieldMap = related_fields_map
    .map((item, index) => {
      const q = index === 0 ? "?" : "&";
      return `${q}fields=${item}`;
    })
    .join("");
  return fieldMap;
};

export const getConcatModelsRelated = (model1, model2, related_fields) => {
  const arrayMap = model1.items.map((item) => {
    const key = related_fields.id_model;
    return {
      ...item,
      [key]: model2.items.find(
        (a) => a.id === noPoint(getStringModel(item[key]))
      ),
    };
  });
  return arrayMap;
};
/**
 * Sometimes backend return .0 or .number in an id, this function is made to check if the id has .0 (Which is not valid and remove it)
 * @param id : string
 * @returns string
 */
export const noPoint = (id) => {
  if (!id) return null;
  let idModel = id;
  let arrayString = id.split(".");
  if (arrayString.length > 0) {
    idModel = arrayString[0];
  }
  return idModel;
};

export const getRelatedModel = async (info) => {
  try {
    const { related_fields, dataModel, pagination } = info;
    const idMap = dataModel.items.map((item) => {
      return noPoint(getStringModel(item[related_fields.id_model]));
    });
    const fieldMap = fieldsMapToString(related_fields);
    const filter = requestFilters.in("id", idMap);
    const { data } = await axiosInstance.put(
      `${related_fields.model}${fieldMap}`,
      { filters: [filter] },
      { params: { ...pagination } }
    );
    return data;
  } catch (error) {
    handlerErrorMessage(error);
  }
};

export const getRelatedFieldsModel = async (info) => {
  const { related_fields, data, pagination } = info;
  try {
    let data2;
    if (Array.isArray(related_fields)) {
      data2 = await Promise.all(
        related_fields.map((related_field) => {
          const information = {
            related_fields: related_field,
            dataModel: data,
            pagination,
          };
          return getRelatedModel(information);
        })
      );
      let newData;
      related_fields.forEach((item, index) => {
        if (index === 0) {
          newData = getConcatModelsRelated(data, data2[index], item);
        } else {
          newData = getConcatModelsRelated(
            { ...data, items: newData },
            data2[index],
            item
          );
        }
      });
      return newData;
    } else {
      const information = { related_fields, dataModel: data, pagination };
      data2 = await getRelatedModel(information);
    }
    return getConcatModelsRelated(data, data2, related_fields);
  } catch (error) {
    console.log("error ", error);
    handlerErrorMessage(error);
  }
};
/**
 * Should return a string with the correct model string.
 * @param { model: string, label: string } | string : a, field : enum = "model" | "label"
 * @param field : enum = "model" | "label"
 */
export const getModelString = (a, field) => {
  let dataToReturn = "";
  if (typeof a === "object" && a) {
    const { [field]: foo } = a;
    dataToReturn = foo;
  } else {
    dataToReturn = a;
  }
  return dataToReturn;
};
/**
 * Should return correct string. Ig updated?id file does not exist check for created_id field
 * @param { created_id?: { business_position: string, name: string } || updated_id?: { business_position: string, name: string } } : obj, field : string
 * @param fieldName : string Related to a field from a model schema.
 */
export const getCorrectFieldName = (obj, fieldName) => {
  let dataToReturn = null;
  if (obj?.updated_id) {
    dataToReturn = obj.updated_id[fieldName];
  } else if (obj?.created_id) {
    dataToReturn = obj.created_id[fieldName];
  } else {
    dataToReturn = null;
  }
  return dataToReturn;
};

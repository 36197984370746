<template>
  <wrapper-layout>
    <v-row no-gutters>
      <v-col cols="12" md="2">
        <navigation-drawer v-bind="navigation" />
      </v-col>
      <v-col md="10">
        <section class="py-8 px-9">
          <slot></slot>
        </section>
      </v-col>
    </v-row>
  </wrapper-layout>
</template>

<script>
import NavigationDrawer from "@/components/navigation/NavigationDrawer";
import navigation from "@/navigation/employee";
import WrapperLayout from "../WrapperLayout.vue";

export default {
  components: { NavigationDrawer, WrapperLayout },

  computed: {
    navigation() {
      return {
        links: navigation.navLinks,
        groups: navigation.navGroups,
      };
    },
  },
};
</script>

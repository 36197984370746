/* eslint-disable prettier/prettier */
export default {
  global: {
    newsletter: "Newsletter",
    download_template: "Download template",
    upload_file_csv: "Upload CSV",
    the_token_expired: "The token has expired",
    please_try_to_reset_the_password:
      "Please try to reset the password again in a few minutes",
    login: "Login",
    edit_month: "Edit Month #",
    estimated_amount: "Estimated amount",
    cancel: "Cancel",
    save: "Save",
    create: "Create",
    advanced_filters: "Advanced filters",
    category: "Category",
    download_report: "Download report",
    business_unit: "Business unit",
    department: "Department",
    status: "Status",
    apply_filters: "Apply filters",
    search_by_name: "Search by name",
    detail: "Detail",
    movements: "Movements",
    attachments_and_files: "Attachments and Files",
    all: "All",
    pending: "Pending",
    upload: "Upload",
    company: "Company",
    cif: "CIF",
    year: "Year",
    information: "Information",
    contact: "Contact",
    financial: "Financial",
    email: "Email",
    area_code: "Area code",
    phone: "Phone",
    add_file: "Add file",
    phone_number: "Phone number",
    consumption: "Consumption",
    image_editor: "Image editor",
    post_detail: "Post detail",
    pending_request: "Pending request",
    view_details: "View details",
    back: "Back",
    workday_almost_over: "Your work day is almost over",
    workday_almost_over_message: "{start_hour} - {end_hour}. See you later {name}",
    ok_got_it: "Ok, got it",
    hours: "hours",
    hour: "hour",
    hours_left: "hours left",
    hour_left: "hour left",
    vacations_days: "vacations days",
  },
  content_management: {
    news: "News",
    title: "Content Management",
    search_by_name: "Search by name",
    show_in_carousel: "Show in carousel",
    hide_in_carousel: "Hide in carousel",
    add_post: "Add post",
    create_post: "Create post",
    edit: "Edit",
    preview: "Preview",
    publish: "Publish",
    delete: "Delete",
    name: "Title",
    status: "Status",
    last_update: "Last Update",
    newsletter: "Newsletter",
    cancel: "Cancel",
    yes_publish: "Yes, publish",
    message_again: "Don't show this message again",
    delete_this_post: "Are you sure you want to delete this post?",
    yes_delete: "Yes, delete",
    you_re_about_to_publish_this_post: "You're about to publish this post",
    When_publish_news:
      "When you publish news you can't edit it anymore. Also it will be send to the notification inbox from all the employees.",
    you_re_about_to_draft_this_post: "You're about to mark as draft this post",

    draft: "Draft",
    make_as_draft: "Make as draft",
    form: {
      title: "Type the title of the banner.",
      description: "Write a short description",
      attach_file: "Attach an image",
      link: "Link (Optional)",
      add_to_post_carousel: "Add to post carousel",
      preview_post: "Preview post",
      publish: "Publish",
    },
  },
  newsletter: {
    delete_all: "Delete all",
    pending_notification: "pending notification",
    pending_notifications: "pending notifications",
    no_notifications: "No notifications in your inbox",
    notifications: "Notifications",
    news_published: "News published",
    news_marked_as_draft: "News marked as draft",
  },
  dashboard: {
    daily_activity: "Daily activity",
    record: "Record",
  },
  status: {
    active: "Active",
    archived: "Archived",
    disable: "Disabled",
    not_approved: "Not approved",
    not_asigned: "Not asigned",
    not_paid: "Not paid",
    pending: "Pending",
    open: "Open",
    approved: "Approved",
    deleted: "Deleted",
    finished: "Finished",
    rejected: "Rejected",
    cancelled: "Cancelled",
    canceled: "Canceled",
    denied: "Denied",
    returned: "Returned",
    absent: "Absent",
    extraordinary: "Extraordinary",
    under_negociation: "Under negotiation",
    unpaid: "Unpaid",
    paid: "Paid",
    pending_accept: "Pending Accept",
    pending_cancel: "Pending Cancel",
    assigned: "Assigned",
    empty: "Empty",
    rented: "Rented",
    signed: "Signed",
    disabled: "Disabled",
    close: "Closed",
    solved: "Solved",
    loi: "LOI",
    draft: "Draft",
    published: "Published",
    accepted: "Accepted",
    not_assigned: "Not assigned",
  },
  ccm_manager: {
    title: "CCM Manager",
    department: {
      search_by_name: "Search by name",
      title: "Department",
      creation_date: "Creation date",
      new_department: "New department",
      rename_department: "Rename department",
      write_department_name: "Write department name",
      deactivate_department: "Deactivate department",
      activate_department: "Activate department",
      status: "Status",
      department_name: "Department name",
    },
  },
  absence: {
    type: "Absence type",
    days: "Absent days",
    starting_date: "Starting date",
    ending_date: "Ending date",
    state: "State",
    team_member: "Team member",
    business_unit: "Bussines unit",
    departament: "Departament",
    search_by_name: "Search by name",
    search_by_member_team: "Search by member team",
    absence_type: "Absence type",
    approval_status: "Approval status",
    select_starting_date: "Select starting date",
    select_ending_date: "Select ending date",
    absence_detail: "Absence detail",
    information: "Information",
    history: "History",
    comment: "Comment",
    absence_record: "Absences record",
    days_off: "Days off",
    days_of_leave: "Days of leave",
    absence_request: "Absence request",
    total_absences: "Total absences",
    status: "Status",
    deny: "Deny",
    approve: "Approve",
    error_date: "Starting date can't be greater that ending date"
  },
  user: {
    personal_information: "Personal information",
    first_name: "First name",
    last_name: "Last name",
    email: "E-mail",
    phone: "Phone number",
    business_information: "Business information",
    business_position: "Business position",
    department: "Department",
    user_type: "User type",
    leader: "Leader",
    leadership: "Leadership",
    role: "Role",
    manage_permissions: "Permission information",
    select_category: "Select category",
    find_action_name: "Find action by name",
    selected_actions: "Selected actions",
    personal_informations: "Personal informations",
    business_informations: "Business informations",
    select_departament: "Select a departament",
    select_user_type: "Select a user type",
    select_leader: "Select leader",
    select_role: "Select role",
    continue: "Continue",
    back: "Back",
    search_actions: "Search actions",
    category: "Category",
    categories: "Categories",
    action: "Select action",
    model: "Select model",
    is_this_employee_a_leader: "Is this employee a leader?",
    add_user: "Add user",
    yes: "Yes",
    no: "No",
    search_by_name: "Search by name",
    user_name: "User name",
    user_role: "User role",
    user_detail: "User Detail",
    user_edit: "Edit user",
    added_by: "Added by",
    date_admission: "Date of admission",
    information: "Information",
    permissions: "Permissions",
    history: "History",
    advanced_filters: "Advanced filters",
    are_want_delete: "Are you sure you want to delete this {model}?",
    will_be_visible_members:
      "The {model} will no longer be visible to other members on the platform",
    confirm_archive: "Yes, archive",
    action_only: "Action",
    search_by: "Search by",
    status: "Status",
    clean_filter: "Clean filters",
    apply_filters: "Apply filters",
    log_history: "Log in history",
    last_upgrade: "Last upgrade",
    users: "Users",
    last_access: "Last access",
    view: "View",
    edit: "Edit",
    archive: "Archive",
    action_user: "Action",
    actions: "Actions",
    rules: "Rules",
  },
  category: {
    name: "Category name",
    new_category: "New category",
    rename_category: "Rename category",
    are_want_delete: "Are you sure you want to delete this {model}?",
    will_be_visible_members:
      "The {model} will no longer be visible to other members on the platform",
    category_name: "Category name",
    creation_date: "Creation date",
    delete: "Delete",
  },
  actions: {
    confirm_delete: "Yes, delete",
    edit_action: "Edit action",
    are_want_delete: "Are you sure you want to delete this {model}?",
    will_be_visible_members:
      "The {model} will no longer be visible to other members on the platform",
    add_action: "Add action",
    new_action: "New Action",
    action_name: "Action name",
    select_category: "Select category",
    category: "Category",
    edit: "Edit",
    read_users: "Read users",
    delete: "Delete",
    new_register: "New register",
    add_register: "Add register",
  },
  permissions: {
    add_permission: "Add permission",
    edit: "Edit permission",
    information: "Permission information",
    are_want_delete: "Are you sure you want to delete this {model}?",
    will_be_visible_members:
      "The {model} will no longer be visible to other members on the platform",
    select_action: "Select action",
    back: "Back",
    model: "Model",
  },
  rules: {
    required: "The field is required|All fields are required",
    url: "The URL must start with http:// or https://",
    min: "The field must contain at least {min} characters",
    max: "The field must contain maximum {max} characters",
    email: "Enter a valid email",
    rules: "Rules",
    add_rule: "Add rule",
    select_action: "Select action",
    model: "Model",
    domain: "Domain",
    back: "Back",
    edit: "Edit rule",
    are_want_delete: "Are you sure you want to delete this {model}?",
    will_be_visible_members:
      "The {model} will no longer be visible to other members on the platform",
    editr: "Edit",
    delete: "Delete",
  },
  auth: {
    enter_email: "Enter you email",
    we_send_change_password:
      "We will send you an email to start the change password process",
    business_email: "Business email",
    login: "Login",
    password: "Password",
    forget_password: "I forgot my password",
    enter: "Enter",
    set_new_password: "Set your new password",
    requiriments_new_password:
      "Your new password must have 8 characters, 1 capital letter and 1 symbol",
    confirm_password: "Confirm password",
  },
  budget: {
    pending_approval: "Pending approval",
    budget_project: "Budget & Project",
    budget_options: "Budget options",
    budget: "Budget",
    budget_report: "Budget Report",
    invoice: "Invoice",
    projects: "Projects",
    you_dont_have_yet_accounting_move:
      " You don't have any accounting move yet",
    create_first_platform_accounting_move:
      "Create your first accounting move on the platform",
    budget_id: "Budget ID",
    new_budget: "New Budget",
    edit_budget: "Edit Budget",
    search_by_budget_name: "Search By Budget name",
    approve_budget: "Approve budget",
    cancel_budget: "Cancel budget",
    monthly_budget: "Monthly budget",
    budget_name: "Budget name",
    search_by_month: "Search by month",
    month: "Month",
    estimate_amount: "Estimate amount",
    real_amount: "Real amount",
    difference: "Difference",
    project_name: "Project name",
    search_by_name: "Search by name",
    invoice_date: "Invoice date",
    download: "Download",
    will_rejected_finance_review:
      "The invoice will be rejected to the finance department for review",
    reject: "Reject",
    approve: "Approve",
    search_by_project_id: "Search by project ID",
    department: "Department",
    approval_status: "Approval status",
    assigned_status: "Assigned status",
    assign_project: "Assign to project",
    search_by_invoice_id: "Search by invoice ID",
    select_project: "Select project",
    add_monthly_amount: "Add monthly amount",
    create_estimate_amount: "Create estimate amount",
    new_project: "New project",
    new_project_lower_case: "new project",
    edit_project: "Edit project",
    tag_manager: "Tag Manager",
    project_options: "Project options",
    project_information: "Project information",
    project_description: "Project description",
    select_files: "Select files",
    starting_date: "Starting date",
    ending_date: "Ending date",
    requester_information: "Requester information",
    financial_information: "Financial information",
    estimated_amount: "Estimated amount",
    total_estimated_amount: "Total estimated amount",
    department_budget_available: "Department budget available",
    write_project_description: "Write a project description",
    attach_file: "Attach a file",
    select_starting_date: "Select starting date",
    select_ending_date: "Select ending date",
    assign_tenant: "Assign tenant",
    there_no_records_yet: "There's no records yet",
    record_the_estimated_monthly_amount: "Record the estimated monthly amount",
    tags: "Tags",
    source: "Source",
    status: "Status",
    file_uploaded: "File uploaded successfully",
    upload_file_report: "Upload file",
    budget_revenue: "This budget is revenue",
    select_source: "Select source",
    select_tenant: "Select Tenant",
    message_roject_Tenant:
      "This project is part of the Tenant's monthly budget",
    message_kpi_store_amount: "This project is a KPI from Rent store amount",
    message_kpi_storage_amount: "This project is a KPI from Storage amount",
    message_kpi_charge_amount:
      "This project is a KPI from Service charge amount",
  },
  project: {
    project_name: "Project name",
    project_information: "Project information",
    business_unit: "Business unit",
    department: "Department",
    source: "Source",
    starting_date: "Starting date",
    ending_date: "Ending date",
    project_description: "Project description",
    tags: "Tags",
    files: "Files",
    financial_information: "Financial information",
    amount: "Amount",
    department_budget: "Department budget",
    requester_information: "Requester information",
    select_year: "Select year",
    select_supplier: "Select supplier",
    assign_supplier: "Assign supplier",
  },
  reports: {
    tag_Manager: "Tag Manager",
    budget_options: "Budget options",
    edit_budget: "Edit budget",
    are_want_cancel: "Are you sure you want to cancel this {model}?",
    yes_cancel: "Yes, cancel",
    no: "No",
    once_cancelled_not_activate:
      "Once this {model} is cancelled you will not be able to activate it again",
    once_rejected_not_activate:
      "Once this {model} is rejected you will not be able to activate it again",
    reason_for_canceling: "Write the reason for canceling the project",
    yes_reject: "Yes, reject",
    are_want_reject: "Are you sure you want to reject this {model}?",
    reason_for_rejecting: "Write the reason for rejecting the project",
    are_want_return: "Are you sure you want to return this {model}?",
    yes_return: "Yes, return",
    once_returned_not_activate:
      "Once this {model} is returned you will not be able to activate it again",
    reason_for_returning: "Write the reason for returning the budget",
    advanced_filters: "Advanced filters",
    department: "Department",
    business_unit: "Business unit",
    tags: "Tags",
    select_year: "Select year",
    clean_filter: "Clean filters",
    apply_filters: "Apply filters",
    reason_for_canceling_project: "Write the reason for canceling the project",
    reason_for_rejecting_project: "Write the reason for rejecting the project",
    budget_name: "Budget name",
    select_business_unit: "Select business unit",
    amount: "Amount",
    description: "Description",
    budget_history: "Budget history",
    budgets: "Budgets",
    budget_detail: "Budget detail",
    overall_budget: "Overall budget",
    budget_information: "Budget information",
    starting_date: "Starting date",
    ending_date: "Ending date",
    budget_description: "Budget description",
    there_are_no_tags: "There are no tags",
    real_amount_vs_estimated: "Real amount vs estimated",
    status: "Status",
  },
  buttons: {
    advanced_filters: "Advanced filters",
    clean_filter: "Clean filters",
    apply_filters: "Apply filters",
    change_user_status: "Change user status",
    are_want_change_status: "Are you sure you want to change the user status?",
    yes: "Yes",
    no: "No",
  },
  departments: {
    select_department: "Select department",
  },
  dialog: {
    yes_cancel: "Yes, cancel",
    are_want_cancel: "Are you sure you want to cancel this {model}?",
    once_cancelled_not_activate:
      "Once this {model} is cancelled you will not be able to activate it again",
    reason_for_canceling: "Write the reason for canceling the {model}",
    are_want_reject: "Are you sure you want to reject this {model}?",
    once_rejected_not_activate:
      "Once this {model} is rejected you will not be able to activate it again",
    reason_for_rejected: "Write the reason for rejecting the {model}",
    yes_return: "Yes, return",
    are_want_return: "Are you sure you want to return this {model}?",
    once_returned_not_activate:
      "Once this {model} is returned you will not be able to activate it again",
    reason_for_returning: "Write the reason for returning the {model}",
    folder_name: "Folder name",
    folder_description: "Folder description (Optional)",
    share_item: "Share {0}",
    select_business_unit: "Select business unit",
    select_departments: "Select departments",
    search_by_user_name: "Search by user name",
    move_file_to: "Move file to",
    select_folder: "Select folder",
    move: "move",
    uploading_files: "Uploading 1 file | Uploading {count} files",
    yes_solve: "Yes, resolve",
    are_want_solve: "Are you sure you want to solve this {model}?",
    once_resolve_not_activate:
      "Once this {model} is resolve you will not be able to activate it again",
    reason_for_resolving: "Write the reason for resolving the {model}",
  },
  files: {
    files: "Files",
    file_information: "File information",
    file_name: "File name",
    owner: "Owner",
    last_modified: "Last modified",
    last_access: "Last access",
    by: "By",
    created_on: "Created on",
    folder_information: "Folder information",
    shared_with: "Shared with",
    not_shared: "Not shared",
    manage_access: "Manage access",
    folder_name: "Folder name",
    description: "Description",
    business_unit: "Business unit",
    department: "Department",
    advanced_filters: "Advanced filters",
    search_by_user_name: "Search by user name",
    apply: "apply",
    my_files: "My files",
    upload_file: "Upload file",
    new_folder: "New folder",
    new: "New",
    you_dont_have_uploaded_files_yet: "You don't have uploaded files yet",
    name: "Name",
    are_you_sure_you_want_to_delete_this:
      "Are you sure you want to delete this {0}",
    all_assigned_files_will_be_deleted_automatically:
      "All the assigned files will be deleted automatically",
    shared_files: "Shared files",
    accept_terms: "Accept terms",
    accepted_terms:
      "I have read and accept the contract's terms and conditions",
    mandatory_files: "Mandatory files",
    users_agree: "Users agree",
    approval_status: "Approval status",
    agreed_users: "Agreed users",
    pending_users: "Pending users"
  },
  finances: {
    assign_to_project: "Assign to project",
    select_project: "Select project",
    expense_detail: "expense detail",
    business_unit: "Business unit",
    department: "Department",
    company: "Company",
    concept: "Concept",
    cost_center: "Cost center",
    taxbase: "Tax base",
    invoice_name: "Invoice Name",
  },
  tax_base: {
    department: "Department",
    select_project: "Select project",
    assign_to_project: "Assign to project",
    expenses: "Expenses",
    invoice_detail: "Invoice detail",
    detail: "Detail",
    accounting_notes: "Accounting notes",
    concept: "Concept",
    project: "Project",
    cost_center: "Cost center",
    bussines_unit: "Business unit",
    invoice_type: "Invoice type",
    company: "Company",
    tax_base: "Tax base",
    search_by_concept: "Search by concept",
    date: "Date",
    status: "Status",
    amount: "Amount",
    payment_method: "Payment method",
    invoice_number: "Invoice number",
    invoice_date: "Invoice date",
    vat_id: "VAT ID",
    country: "Country",
    tax: "Tax",
    pending_amount: "Pending amount",
    total: "Total",
    invoice_file: "Invoice file",
    company_information: "Company information",
    invoice_information: "Invoice information",
  },
  navigation: {
    hi: "Hi",
    my_profile: "My profile",
    settings: "Settings",
    log_out: "Log out",
  },
  teams: {
    teams: "Teams",
    absences: "Absences",
    month_report: "Month report {month}",
    employee_detail: "Employee detail",
    search_by_day: "Search by day",
    working_days: "Working days",
    activity: "Activity",
    days: "Days",
    no_employee_activity_log: "No employee activity log",
    time_report: "Time report",
    employee_status: "Employee status",
    pending_request: "Pending request",
    view_all: "View all",
    search_by_user_name: "Search by user name",
    great: "Great",
    no_pending_requests: "No pending requests",
    absences_record: "Absences record",
  },
  tenant: {
    edit_tenant_data: "Edit tenant data",
    app_name: "Tenants",
    tenant_list: "Tenant list",
    area_local: " Store area sqm",
    company: "Company",
    category: "Category",
    floor: "Floor",
    advanced_filters: "Advanced filters",
    local_name: "Local name",
    search_by_local: "Search by local name",
    search_by_company: "Search by company name",
    search_by_brand_name: "Search by brand name",
    status: "Status",
    tenant_detail: "Tenant detail",
    tenant_id: "Id",
    warehouse: "Storage",
    warehouse_area: "Storage area sqm",
    place_information: "Detail",
    invoice: "Invoice",
    invoicing_terms: "Invoicing Terms",
    alerts: "Alerts",
    crm: "CRM",
    attachments_files: "Attachments and files",
    monthly_budget: "Monthly budget",
    history: "History",
    search_by_alert: "Search by alert",
    alert_created_by: "Alert create by",
    notified_date: "Date of notification",
    new_alert: "New alert",
    create_alert: "Create alert",
    search_by_name: "Search by name",
    responsible_user: "Responsible user",
    area_min: "Min",
    area_max: "Max",
    user: "User",
    action: "Action",
    date: "Date",
    search_by_file: "Search by file",
    file_size: "File size",
    message_no_files: "No files uploaded",
    view_tenant: "View tenant",
    complete_information: "Complete information",
    edit_tenant: "Edit tenant",
    assign_store: "Assign store",
    store_information: "Store information",
    tenant_information: "Tenant information",
    company_information: "Company information",
    nif: "NIF",
    address: "Fiscal address",
    commercial_address: "Commercial address",
    contact_name: "Contact name",
    financial_information: "Financial information",
    aval: "Aval",
    additional_warranty: "Additional warranty",
    return_good: "Return good performance of works",
    good_performance: "Good performance of Works",
    IBI_refacturable: "IBI refacturable",
    IBI: "IBI",
    owners_contribution: "Owners contribution",
    warehouse_amount: "Warehouse amount",
    service_charge_amount: "Service charge amount",
    fixed_rent_amount: "Minimum Guaranteed Rent amount",
    equities: "Equities",
    ibi_amount: "IBI amount",
    guaranteed_minimum_income: "Minimum Guaranteed Rent",
    fixed_Rent: "Minimum Guaranteed Rent",
    observation: "Observation",
    rent_amount: "Rent amount",
    contract_signing_date: "Contract signing date",
    contract_starting_date: "Starting date",
    ending_date: "Ending date",
    hiring_period: "Hiring period",
    contract_duration: "Contract duration",
    equity_observations: "Equities observations",
    information: "Information",
    no: "No",
    yes: "Yes",
    opening_date: "Opening date",
    contract_ending_date: "Contract ending date",
    clean_filter: "Clean filters",
    apply_filters: "Apply filters",
    alert_information: "Alert information",
    alert_title: "Alert title",
    alert_description: "Alert description",
    copy_link: "Copy a link",
    period: "Period",
    select_date: "Select date",
    write_number: "Write number",
    user_responsible_alert: "User responsible for the alert",
    search_by: "Search by",
    yes_create: "yes, create",
    going_create_alert: "You're going to create an alert",
    show_message: "This message will be shown to {fullName}",
    edit_alert: "Edit alert",
    attach_file: "Attach a file",
    select_period: "Select period",
    are_want_delete_file: "Are you sure you want to delete this file?",
    upload_brand_image: "Upload brand image",
    company_name: "Company name",
    social_reason: "Social reason",
    email: "Email",
    area_code: "Area Code",
    business_position: "Business position",
    local: "Local",
    select_local: "Select store",
    starting_date: "Starting date",
    clean_filters: "Clean filters",
    movements: "Movements",
    month: "Month",
    estimated_amount: "Estimated amount",
    real_amount: "Real amount",
    difference: "Difference",
    search_by_concept: "Search by concept",
    stores: "Stores",
    kpi: "KPI's",
    financial: "Financial",
    contract: "Contract",
    contract_information: "Contract information",
    contact: "Contact",
    contact_information : "Contact information",
    rent_store: "Rent store amount",
    storage_rent: "Storage rent",
    rent_per_mt: "Rent per sqm Storage",
    rent_per_mt_store: "Rent per sqm Store",
    service_charge: "Service charge amount",
    net_sales: "Tenant net sales per sqm",
    worker_information: "Worker information",
    change_store_status: "Change store status",
    difference_percentage: "Difference %",
    search_by_type_of_event: "Search by type of event",
    worker_assigned: "Worker assigned",
    event: "Event",
    department: "Department",
    contact_person: "Contact person",
    creation_date: "Creation date",
    last_update: "Last update",
    new_event: "New event",
    event_detail: "Event detail",
    edit: "Edit",
    delete: "Delete",
    create_event: "Create event",
    event_information: "Event information",
    select_event_date: "Select event date",
    write_event_type: "Write event type",
    select_worker: "Select worker assigened",
    event_description: "Event description (Optional)",
    contact_person_information: "Contact person information",
    full_name: "Full name",
    contact_email: "Contact email",
    phone_number: "Phone number",
    title_dialog_close: "Are you sure You're  about to close the contract?",
    content_dialog_close:
      "When the contract is closed, the tenant information is saved and the assigned store will become empty.",
    delete_dialog_close: "Yes, close contract",
    view_alert_detail: "Alert detail",
    alert_history: "History",
    alert_file: "File",
    alert_link: "Links",
    alert_calendar: "Created on",
    are_want_delete_event: "Are you sure you want to delete this event?",
    tenant_net_sales_per_sqm: "Tenant net sales per sqm",
    monthly_sale: "Monthly sale",
    add_monthly_amount: "Add monthly amount",
    monthly_sale_history: "Monthly sale history",
    tenants: "Tenants",
    created_at: "Creation Date",
    month_detail: "Month detail",
    monthly_sale_amount: "Monthly sale amount",
    difference_item: "Difference %",
    file: "File",
    save: "Save",
    select_a_month_and_year: "Select a month and year",
    write_real_amount: "Write real amount",
    attach_a_file_Optional: "Attach a file (Optional)",
    select_contract: "Select contract",
    requester_information: "Requester information",
    reason: "Reason",
    alert_name: "Alert name",
    programmed_by: "Programmed by",
    event_type: "Event type",
    departament: "Departament",
    attached_files: "Attached files",
    name: "Name",
    select_store: "Select store",
    edit_monthly_amount: "Edit monthly amount",
    store_detail: "Store detail",
    add_contact_details: "Add contact details",
    store_assigned_to_contract: "Stores assigned to contract",
    tenant_options: "Tenant options",
    assign_ledger_account: "Assign ledger account",
    ledger_accounts_assigned: "Ledger accounts assigned",
    commercial_adress: "Commercial adress",
  },
  detail: {
    upload_brand_image: "Upload brand image",
    brand_name: "Brand name",
    name_contact: "Name contact",
    select_category: "Select category",
    company: "Company",
    nif: "NIF",
    fiscal_address: "Fiscal address",
    email: "Email",
    phone_number: "Phone number",
    search_contact: "Search by contact",
    business_position: "Business position",
    contact_email: "Contact email",
    area_code: "Area Code",
    aval: "Aval",
    additional_warranty: "Additional warranty",
    good_performance: "Good performance of Works",
    select_ibi: "Select IBI",
    owners_contribution: "Owners contribution",
    storage_amount: "Storage amount",
    service_charge_amount: "Service charge amount",
    fixed_rent_amount: "Minimum Guaranteed Rent amount",
    equities: "Equities",
    ibi_amount: "IBI amount",
    guarenteed_minimum_income: "Guarenteed minimum income",
    comments: "Comments",
    rent_amount: "Rent amount",
    contract_signing_date: "Contract signing date",
    opening_date: "Opening date",
    starting_date: "Starting date",
    ending_date: "Ending date",
    phone: "Phone",
    delivery_date_of_store: "Premises Handover",
  },
  invoicing: {
    add_terms: "Add terms",
    add_terms_uppercase: "ADD TERMS",
    select_rent_start_date: "Select rent start date",
    select_rent_update: "Select rent update",
    choose_date: "Choose a date",
    bail_amount: "Bail amount",
    invoice_amount: "Invoice amount",
    attach_file: "Attach a file",
    terms_description: "Terms description",
    contract_information: "Contract information",
    description_terms: "Description terms",
    files: "Files",
    rent_update: "Rent update",
    annual_billing_forecast: "Annual billing forecast",
    next_invoice: "Next invoice",
    edit_terms: "Edit terms",
    edit_terms_uppercase: "EDIT TERMS",
  },
  store: {
    floor_information: "Floor information",
    floor: "Floor 1",
    local_information: "Local information",
    technical_information: "Technical information",
    electric_meter_number: "Electric meter number",
    area_local: " Store area sqm",
    warehouse: "Warehouse",
    area: "Area",
    water_meter_number: "Water meter number",
    climate_ring_counter_number: "Climate ring counter number",
    rated_ring_flow: "Rated ring flow",
    assigned_electrical_power: "Assigned electrical power",
    add_store: "Add store",
    search_store_name: "Search by store name",
    advanced_filters: "Advanced filters",
    edit_store: "Edit store",
    store_detail: "Store detail",
    information: "Information",
    available: "Available",
    not_available: "Not available",
    upload_plane_image: "Upload plane image (Optional)",
    write_electric_meter_number: "Write electric meter number",
    write_water_meter_number: "Write water meter number",
    assigned_flow_climate_ring: "Assigned flow climate ring",
    upload_location_image: "Upload location image",
    local_name: "Local name",
    floor_title: "Floor",
    clean_filters: "Clean filters",
    apply_filters: "Apply filters",
    select_store: "Select store",
    status: "Status",
    select_local: "Select store",
    accept: "Accept",
    ok: "Ok",
    assigne_store: "assigne the store",
    are_assigne_store:
      "You are about to assigne the store {store_name} with the {company_name}",
    store_information: "Store information",
    change_store_status: "Change store status",
    edit_local: "Edit local",
    assign_ledger_account: "Assign ledger account",
    register_company:
      "Register the company(ies) brand name that are under negotiation",
    status_change: "Status change",
    brand_name: "Brand name",
    change_to: "Change to",
    add_brand: "Add brand",
    consumption_history: "Consumption history",
    electrical_consumption: "Electrical consumption",
    water_consumption: "Water consumption",
    climate_ring_consumption: "Climate ring consumption",
    camera_ring_consumption: "Camera ring consumption",
    assign_flow_chamber_ring: "Assigned flow chamber ring",
    assign_hood_flow: "Assigned hood flow",
    write_counter_ring_cameras: "Write counter ring cameras",
    total_sqm_store: "Total sqm Store",
    total_sqm_warehouse: "Total sqm Warehouse",
    select_definitive_tenant: "Select the definitive tenant",
    write_the_brand_name_from_the_definitive_tenant:
      "Write the brand name from the definitive tenant",
    status_was_changed: "Status was changed",
    delete: "Delete",
  },
  time_tracking: {
    create_absence: "Create absence",
    select_absence_type: "Select absence type",
    days_of_leave: "Days of leave",
    vacations: "Vacations",
    starting_date: "Starting date",
    ending_date: "Ending date",
    comment: "Comment",
    optional: "Optional",
    working_timer: "Working timer",
    select_working_mode: "Select working mode",
    absence_created: "Absence created",
    accept: "accept",
    stop: "stop",
    start_time: "Start time",
  },
  docs: {
    new_action: "New Action",
    action_name: "Action name",
    select_departament: "Select a departament",
    are_want_delete_action: "Are you sure you want to delete this action?",
    action_will_no_visible:
      "The action will no longer be visible to other members on the platform",
    hi: "Hi",
    role: "Role",
  },
  graphics: {
    overall_budget: "Overall Budget",
    budget: "Budget",
    budget_source: "Budget by source",
    budget_business_unit: "Budget by Business unit",
    department_budget: "Department Budget",
    total_expenses: "Total expenses",
  },
  schedule: {
    schedule_name: "Schedule",
    absences_record: "Absences record",
    create_absence: "Create absence",
    time_report: "Time report",
    activity: "Activity",
    not_assigned: "Not assigned",
    hours_left: "1 hour left | {count} hours left",
    days_left: "1 day left | {count} days left",
    last_year: "Last year",
    daily_work: "Daily work",
    daily_activity: "Daily activity",
    days_claimed: "Days claimed",
    next_vacation_day: "Next vacation day",
    working_days: "Working days",
    absences: "Absences",
    general: "General",
    pending: "Pending",
  },
  views: {
    password_has_confirmed_successfully:
      "Your password has been confirmed successfully",
    password_reset: "Password reset",
    login: "Login",
    token_has_expired: "The token has expired",
    try_reset_password_again:
      "Please try to reset the password again in a few minutes.",
  },
  empty: "Empty",
  rented: "Rented",
  signed: "Signed",
  not_assigned: "Not assigned",
  hours_left: "1 hour left | {count} hours left",
  days_left: "1 day left | {count} days left",
  last_year: "Last year",
  daily_work: "Daily work",
  daily_activity: "Daily activity",
  days_claimed: "Days claimed",
  next_vacation_day: "Next vacation day",
  shared_files: "Shared files",
  my_files: "My files",
  upload_file: "Upload file",
  new_folder: "New folder",
  new: "New",
  you_dont_have_uploaded_files_yet: "You don't have uploaded files yet",
  name: "Name",
  shared_with: "Shared with",
  last_modified: "Last modified",
  Assigned: "Assigned",
  Extraordinary: "Extraordinary",
  Unpaid: "Unpaid",
  Paid: "Paid",
  "Not approved": "Not approved",
  "Not asigned": "Not asigned",
  team_manager: "Team manager",
  uploading_files: "Uploading 1 file | Uploading {count} files",
  private: "Private",
  folder_name: "Folder name",
  folder_description: "Folder description (Optional)",
  new_folder_created: "New folder created",
  details: "Details",
  share: "Share",
  edit_name: "Edit name",
  download: "Download",
  delete: "Delete",
  move_to: "Move to",
  Open: "Open",
  "Opening date": "Opening date",
  you_must_move_this_file: "You must move this file \nto a folder to share it",
  "Not available": "Not available",
  folder_information: "Folder information",
  not_shared: "Not shared",
  manage_access: "Manage access",
  description: "Description",
  owner: "Owner",
  last_access: "Last access",
  by: "By",
  created_on: "Created on",
  share_item: "Share {0}",
  done: "Done",
  select_business_unit: "Select business unit",
  select_departments: "Select departments",
  edited_folder: "Edited folder",
  edit_folder: "Edit folder",
  save: "Save",
  are_you_sure_you_want_to_delete_this:
    "Are you sure you want to delete this {0}",
  all_assigned_files_will_be_deleted_automatically:
    "All the assigned files will be deleted automatically",
  folder_deleted: "Folder deleted",
  file_deleted: "File deleted",
  file_information: "File information",
  file_name: "File name",
  move_file_to: "Move file to",
  select_folder: "Select folder",
  move: "Move",
  moved_file: "Moved file",
  apply: "Apply",
  created_date: "Created date",
  business_unit: "Business unit",
  department: "Department",
  shared_by: "Shared by",
  shared: "Shared",
  folder_options: "Folder options",
  upload_files: "Upload files",
  supplier: "Supplier",
  general: {
    floor: "Floor",
    add_new_tag: "Add a new tag",
    tag_list: "Tag list",
    add_tags: "Add tags",
    create_new_tag: "Create new tag",
    are_you_want_reject: "Are you sure you want to reject this {model}?",
    once_this_rejected_will_activate:
      "Once this {model} is rejected you will not be able to activate it again",
    write_reason_rejecting: "Write the reason for rejecting the {model}",
    business_unit: "Business unit",
    create_first_platform: "Create your first {model} on the platform",
    we_cannot_find_any_results_parameter:
      "We cannot find any results with this parameter. Please clear the search and try again.",
    clear_search: "Clear search",
    error_404: "Error 404",
    page_not_found: "Page not found",
    private: "Private",
    yes: "Yes",
    no: "No",
    welcome: "Welcome",
    start_explore: "Start to explore",
    canalejas_SGI: "To Canalejas SGI",
    you_dont_have_yet: "You don't have any {model} yet",
  },
  months: {
    jan: "January",
    feb: "February",
    mar: "March",
    apr: "April",
    may: "May",
    jun: "June",
    jul: "July",
    aug: "August",
    sep: "September",
    oct: "October",
    nov: "November",
    dec: "December",
  },
  suppliers: {
    suppliers: "Suppliers",
    options: "Supplier options",
    search_by_supplier_name: "Search by supplier name",
    edit_information: "Edit information",
    archive: "Archive",
    enable_supplier: "Enable supplier",
    disable_supplier: "Disable supplier",
    fiscal_address: "Fiscal address",
    comercial_address: "Comercial address",
    complete_information: "Complete information",
    business_information: "Business information",
    contact_information: "Contact information",
    financial_information: "Financial information",
    payment_method: "Payment method",
    id_type: "ID type",
    bank: "Bank",
    account_holder: "Account holder",
    id_number: "ID number",
    account_type: "Account type",
    account_number: "Account number",
    files: "Files",
    last_modified: "Last modified",
    search_by_file: "Search by file",
    view_details: "View details",
    file_information: "File information",
    file_name: "File name",
    owner: "Owner",
    last_access: "Last access",
    created_on: "Created on",
    fiscal_business_name: "Fiscal business name",
    edit_supplier: "Edit supplier",
    contact_full_name: "Contact full name",
    contact_business_position: "Contact business position",
  },
  ledger_account: {
    find_ledger_account_by_number: "Find ledger account by number",
    select_ledger_account_by_name: "Select ledger account by name",
    select_cost_center: "Select cost center",
    assign_to_ledger_accounts: "Assign to ledger accounts",
    edit_ledger_accounts: "Edit ledger accounts",
  },
  Archived: "Archived",
  Available: "Available",
  "Last access": "Last access",
  "Password changed": "Password changed",
  "Password not changed": "Password not changed",
  "Please, check your mail and try it again":
    "Please, check your mail and try it again",
  "There’s something wrong": "There’s something wrong",
  "There is already an accepted budget, with the same department and in the same year":
    "There is already an accepted budget, with the same department and in the same year",
  "Your password hasn’t change. Please try it again in a few minutes":
    "Your password hasn’t change. Please try it again in a few minutes",
  "Your role has to be director to accept a budget":
    "Your role has to be director to accept a budget",
  "The {} have been created successfully":
    "The {model} have been created successfully",
  "The {} have been updated successfully":
    "The {model} have been updated successfully",
  "The {} have been removed successfully":
    "The {model} have been removed successfully",
  "The {} have been approved successfully":
    "The {model} have been approved successfully",
  "Are you sure you want to archive this {}?":
    "Are you sure you want to archive this {model}?",
  "No pdf available": "No pdf available",
  "You do not have permissions to access this section":
    "You do not have permissions to access this section",
  Welcome: "Welcome",
  "food hall": "Food hall",
  "Search by project ID": "Search by project ID",
  no_activity_logs: "No activity logs",
  working_timer: "Working timer",
  select_working_mode: "Select working mode",
  accept: "Accept",
  stop: "Stop",
  "There's no records yet": "There's no records yet",
  "The invoice has been approved successfully":
    "The invoice has been approved successfully",
  "The invoice has been set as extraordinary":
    "The invoice has been set as extraordinary",
  "Create estimate amount": "Create estimate amount",
  "Estimated amount": "Estimated amount",
  "Total estimated amount": "Total estimated amount",
  activity: "Activity",
  create_absence: "Create absence",
  "not approved": "Not approved",
  "credit card": "Credit Card",
  "debit card": "Debit Card",
  "bank transfer": "Bank Transfer",
  CIF: "CIF",
  "DNI/NIE": "DNI/NIE",
  PASSPORT: "PASSPORT",
  "Yes, delete": "Yes, delete",
  "content management": "content management",
  create: "Create",
  Dashboard: "Dashboard",
  Incidents: "Incidents",
  "Users manager": "Users manager",
  Store_deleted: "Store deleted",
};

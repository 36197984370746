import { render, staticRenderFns } from "./DirectorLayout.vue?vue&type=template&id=1a0aecbe&scoped=true&"
import script from "./DirectorLayout.vue?vue&type=script&lang=js&"
export * from "./DirectorLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a0aecbe",
  null
  
)

export default component.exports
export const actions = {
  /**
   * @param context : ActionContext<Store>
   * @param {} store
   */
  SET_STORE_SELECTED: (context, store) => {
    context.commit("setStoreSelected", store);
  },
  /**
   * @param context : ActionContext<Store>
   * @param refreshStoreWrapper : boolean
   */
  SET_REFRESH_STORE_WRAPPER: (context, refreshStoreWrapper) => {
    context.commit("setRefreshStoreWrapper", refreshStoreWrapper);
    setTimeout(() => {
      context.commit("setRefreshStoreWrapper", false);
    }, 1000);
  },
  GET_STORES: (context) => {
    // TODO(Carlos): Implementation pending
    context.commit("getStores");
  },
};

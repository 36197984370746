import Vue from "vue";

const state = {};

const getters = {};

const mutations = {
  /**
   * @param State : state
   * @param {} auth
   */
  setModelList: (state, { model, data }) => {
    Vue.set(state, model, data);
  },

  setParamsOrFiltersModelList: (state, { model, payload, label }) => {
    Vue.set(state, `${model}_${label}`, payload);
  },
  setModelSchema: (state, { model, data, name }) => {
    Vue.set(state, `${model}_${name}`, data);
  },
};

const actions = {
  /**
   * @param context : ActionContext<Store>
   * @param {} auth
   */
  setModelList: (context, payload) => {
    context.commit("setModelList", payload);
  },
  setModelSchema: (context, payload) => {
    context.commit("setModelSchema", payload);
  },
  setParamsOrFiltersModelList(context, payload) {
    context.commit("setParamsOrFiltersModelList", payload);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

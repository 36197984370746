import store from "@/store";
import commentNode from "../commentNode";

export const roleDirective = (el, binding, vnode) => {
  const userRole = store.getters["auth/user_role"];
  if (userRole) {
    const allowedRoles = binding.value;

    !allowedRoles.includes(userRole) && commentNode(el, vnode);
  }
};

import { USER_ROLE } from "@/utils/constants";
const role = [USER_ROLE.ADM, USER_ROLE.EMP, USER_ROLE.DIR];
const layout = "admin";
import authMiddleware from "@/middlewares/auth";
import roleMiddleware from "@/middlewares/role";

const metaBudget = {
  role,
  layout,
  middleware: [authMiddleware, roleMiddleware],
};

const budget = [
  {
    path: "/module/budget/reports",
    name: "shared.budget.report",
    component: () => import("@/views/budget/reports/BudgetReportView.vue"),
    meta: metaBudget,
  },
  {
    path: "/module/budget/reports/:id",
    name: "shared.budget.report.id",
    component: () => import("@/views/budget/reports/id/BudgetDetailView.vue"),
    meta: metaBudget,
  },
  {
    path: "/module/budget/reports/:month/:budgetId",
    name: "shared.budget.month.id.month",
    component: () => import("@/views/budget/reports/id/BudgetMonthDetail.vue"),
    meta: metaBudget,
  },
  {
    path: "/module/budget/projects",
    name: "shared.budget.project",
    component: () => import("@/views/budget/projects/BudgetProjectView.vue"),
    meta: { ...metaBudget, breadCrumb: "Budget" },
  },
  {
    path: "/module/budget/projects/:id",
    name: "shared.budget.project.show",
    component: () => import("@/views/budget/projects/BudgetProjectDetail.vue"),
    meta: { ...metaBudget, breadCrumb: "Project detail" },
  },
  {
    path: "/module/budget/invoice",
    name: "shared.budget.invoice",
    component: () => import("@/views/budget/invoice/BudgetInvoiceView.vue"),
    meta: metaBudget,
  },
  {
    path: "/module/budget/dashboard",
    name: "shared.budget.dashboard",
    component: () => import("@/views/budget/dashboard/BudgetDashboardView"),
    meta: metaBudget,
  },
  {
    path: "/module/budget/finance/dashboard",
    name: "finance.dashboard",
    component: () => import("@/views/budget/finance/DashboardView.vue"),
    meta: metaBudget,
  },
  {
    path: "/module/budget/finance/expenses",
    name: "finances.expenses",
    component: () => import("@/views/budget/finance/expenses/ExpensesView.vue"),
    meta: metaBudget,
  },
  {
    path: "/module/budget/finance/expenses/invoice/:procede",
    name: "finances.expenses.invoice",
    component: () =>
      import("@/views/budget/finance/expenses/ExpensesInvoiceDetailView.vue"),
    meta: metaBudget,
  },
];

export default budget;

import authMiddleware from "@/middlewares/auth";
import roleMiddleware from "@/middlewares/role";
import { USER_ROLE } from "@/utils/constants";

const role = [USER_ROLE.ADM, USER_ROLE.EMP];
const layout = "admin";

const metaAdmin = {
  role,
  layout,
  middleware: [authMiddleware, roleMiddleware],
};

const admin = [
  {
    path: "/admin/dashboard",
    name: "administrator.dashboard",
    component: () => import("@/views/admin/Dashboard/DashboardView.vue"),
    meta: metaAdmin,
  },
  {
    path: "/admin/users",
    name: "admin.users",
    component: () => import("@/views/admin/users/UsersView.vue"),
    meta: metaAdmin,
  },
  {
    path: "/admin/actions/users",
    name: "admin.actions.users",
    component: () => import("@/views/admin/actions/users/UsersView.vue"),
    meta: metaAdmin,
  },
  {
    path: "/admin/categories",
    name: "admin.users.categories",
    component: () => import("@/views/admin/categories/CategoriesView.vue"),
    meta: metaAdmin,
  },
  {
    path: "/admin/actions",
    name: "admin.users.actions",
    component: () => import("@/views/admin/actions/ActionsView.vue"),
    meta: metaAdmin,
  },
  {
    path: "/admin/permissions",
    name: "admin.users.permissions",
    component: () => import("@/views/admin/permissions/PermissionsView.vue"),
    meta: metaAdmin,
  },
  {
    path: "/admin/rules",
    name: "admin.users.rules",
    component: () => import("@/views/admin/rules/RulesView.vue"),
    meta: metaAdmin,
  },
];

export default admin;

import { toNumber } from "lodash";

const NumAbbr = require("number-abbreviate");
const numAbbr = new NumAbbr();

export const squareMeters = (value) => {
  const fixValueNumber = value.toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 5,
  });
  return `${fixValueNumber} sqm`;
};

/**
 * This function is used to abbreviate long numbers by one decimal. Ex: 15000 => 1.5k
 * You can see more details or tests on @web/tests/unit/utils/filters/numbers.spec.js
 * @param {number} num
 * @return string
 */
export const abbr = (number) => {
  return numAbbr.abbreviate(number.toFixed(2), 1);
};

/**
 * This function fix or put away some decimals of a number in javascript.
 * Test for more info in general.spec.js
 * @param val : number
 * @return number
 */
export const fixDecimals = (val) => {
  // if (!val) return null;
  return toNumber(parseFloat(val).toFixed(2));
};
/**
 * This function is used to format numbers and add a %
 * @param val: number
 * @return string
 */

export const percentageDecimals = (val) => {
  return `${fixDecimals(val)} %`;
};

/**
 * This function is used to format numbers and add a MB
 * @param val: number
 * @return string
 */
export const megaByte = (val) => {
  return `${fixDecimals(val)} MB`;
};

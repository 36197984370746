import genericModels from "@/services/genericModels";
import { CREATED_MODEL, STATUS, requestFilters } from "@/utils";

export const actions = {
  /**
   * @param context : ActionContext<Store>
   * @param wrapper : { isViewAbsenceWrapper: boolean },
   */
  SET_WRAPPER: (context, wrapper) => {
    context.commit("setWrapper", wrapper);
  },
  GET_ABSENCE_PENDING: async () => {
    await genericModels.get(
      CREATED_MODEL.PENDINGABSENCE,
      {
        filters: {
          filters: [...requestFilters.equal("request_state", [STATUS.PENDING])],
        },
      },
      true
    );
  },
};

import axiosInstance, { axiosInstance2 } from "@/plugins/axios";
import i18n from "@/plugins/i18n";
import { handlerErrorMessage } from "@/utils";
import { ref } from "vue";
import { contentTypes } from "@/utils/constants/contentTypes";

const getFileModelField = async ({
  model,
  model_id,
  file_id,
  base64 = true,
}) => {
  try {
    let { data } = await axiosInstance2.get(
      `/${model}/${model_id}/file/${file_id}?base64=${base64}`
    );
    return data;
  } catch (error) {
    return handlerErrorMessage(error);
  }
};

const postFileModel = async ({ model, model_id, files }) => {
  try {
    const formData = new FormData();
    files.forEach((file) => formData.append("files", file));

    let { data } = await axiosInstance.post(
      `/${model}/${model_id}/file`,
      formData
    );
    return data;
  } catch (error) {
    return handlerErrorMessage(
      i18n.t("An error occurred while trying to upload the file")
    );
  }
};

const postFileModelIdWithProgress = ({ model, model_id, file }) => {
  const progress = ref(0);
  const formData = new FormData();
  formData.append("files", file);
  axiosInstance2.post(`/${model}/${model_id}/file`, formData, {
    onUploadProgress: (progressEvent) => {
      progress.value = (100 * progressEvent.loaded) / progressEvent.total;
    },
  });

  return { progress, name: file.name };
};

const postFileModelWithProgress = ({ model, file }) => {
  const progress = ref(0);
  const formData = new FormData();
  formData.append("file", file);
  axiosInstance2.post(`/${model}`, formData, {
    onUploadProgress: (progressEvent) => {
      progress.value = (100 * progressEvent.loaded) / progressEvent.total;
    },
  });

  return { progress, name: file.name };
};

const postFileModelField = async ({ model, model_id, field, files }) => {
  try {
    const formData = new FormData();
    files.forEach((file) => formData.append("file", file));
    let { data } = await axiosInstance.post(
      `/${model}/${model_id}/file/${field}`,
      formData
    );
    return data;
  } catch (error) {
    return handlerErrorMessage(
      i18n.t("An error occurred while trying to upload the file")
    );
  }
};

const deleteFileModel = async ({ model, model_id, field_id }) => {
  try {
    let { data } = await axiosInstance.delete(
      `/${model}/${model_id}/file/${field_id}`
    );
    return data;
  } catch (error) {
    return handlerErrorMessage(error);
  }
};

const deleteFileModelField = async ({ model, model_id, field_id, field }) => {
  try {
    let { data } = await axiosInstance.delete(
      `/${model}/${model_id}/file/${field}/${field_id}`
    );
    return data;
  } catch (error) {
    return handlerErrorMessage(error);
  }
};
/**
 * This function is used to download an excel file.
 * @param { model: string, type: string, query: string } obj
 * @returns Object
 */
const downloadFileExcel = async (obj) => {
  try {
    const { model, type, query = "" } = obj;
    let { data } = await axiosInstance.get(`/${model}/${type}${query}`, {
      responseType: "blob",
    });
    return data;
  } catch (error) {
    return handlerErrorMessage(error);
  }
};

const uploadFileExcel = async ({ model, type, files, query = "" }) => {
  try {
    const formData = new FormData();
    files.forEach((file) => formData.append("file", file));

    let { data } = await axiosInstance.put(
      `/${model}/${type}${query}`,
      formData
    );
    return data;
  } catch (error) {
    return handlerErrorMessage(
      i18n.t("An error occurred while trying to upload the file")
    );
  }
};

const downloadFile = async ({
  model,
  model_id,
  file_id,
  extension,
  filename,
}) => {
  try {
    let { data } = await axiosInstance2.get(
      `/${model}/${model_id}/file/${file_id}`,
      { responseType: "blob" }
    );
    const contentType = contentTypes.find((ct) => ct.extension === extension);
    const blob = new Blob([data], { type: contentType.mime });
    const url = window.URL || window.webkitURL;
    const link = url.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("download", filename);
    a.setAttribute("href", link);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {
    return handlerErrorMessage(error);
  }
};

export default {
  getFileModelField,
  postFileModel,
  postFileModelWithProgress,
  postFileModelIdWithProgress,
  postFileModelField,
  deleteFileModel,
  deleteFileModelField,
  downloadFileExcel,
  uploadFileExcel,
  downloadFile,
};

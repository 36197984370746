<template>
  <v-card
    ref="timeTracker"
    class="d-flex time-tracker"
    elevation="3"
    style="width: 298px"
  >
    <div class="pointer px-2 py-6" @mousedown="dragMouseDown">
      <v-icon color="neutral lighten-4">mdi-dots-grid</v-icon>
    </div>
    <v-divider vertical />
    <div class="d-flex pa-1 align-center">
      <button-custom
        class="ml-1"
        color="error"
        :label="$t('time_tracking.start_time')"
        icon="mdi-play"
        @click="stopAndPrevent"
        v-if="$store.getters['timeTracker/pauseTime']"
      />
      <button-custom
        class="ml-1"
        color="error"
        :label="$t('time_tracking.stop')"
        icon="mdi-stop"
        @click="stopAndPrevent"
        v-else
      />
      <h3 class="ml-4 neutral--text text--darken-1">{{ formattedTime }}</h3>
    </div>
  </v-card>
</template>

<script>
import ButtonCustom from "@/components/buttons/ButtonCustom.vue";
import moment from "moment";
import { mapActions } from "vuex";
import jobrecordService from "@/services/jobrecords";

let timeInterval;
let updateTimeInterval;
export default {
  name: "TimeTracker",

  components: { ButtonCustom },

  data: () => ({
    currentTime: Date.now(),
    timeData: [],
  }),

  computed: {
    diff() {
      const startMoment = moment(this.$store.getters["timeTracker/startTime"]);
      const pauseMoment = moment(this.$store.getters["timeTracker/pauseTime"]);
      if (pauseMoment.isValid()) {
        return pauseMoment.diff(startMoment);
      }
      const currentMoment = moment(this.currentTime);
      return currentMoment.diff(startMoment);
    },
    formattedTime() {
      return moment.utc(this.diff).format("HH : mm : ss");
    },
  },

  methods: {
    ...mapActions({
      toggleWork: "timeTracker/toggleWork",
      updateWork: "timeTracker/updateWork",
      preventDialog: "timeTracker/preventDialog",
      pauseWork: "timeTracker/pauseWork",
    }),

    async stopAndPrevent() {
      this.preventDialog();
      this.toggleWork();
    },

    dragMouseDown(event) {
      event.preventDefault();
      let clientX = event.clientX;
      let clientY = event.clientY;

      document.onmousemove = (event) => {
        event.preventDefault();
        const movementX = clientX - event.clientX;
        const movementY = clientY - event.clientY;
        clientX = event.clientX;
        clientY = event.clientY;

        this.$refs.timeTracker.$el.style.top =
          this.$refs.timeTracker.$el.offsetTop - movementY + "px";
        this.$refs.timeTracker.$el.style.left =
          this.$refs.timeTracker.$el.offsetLeft - movementX + "px";
      };

      document.onmouseup = () => {
        document.onmouseup = null;
        document.onmousemove = null;
      };
    },
  },

  async created() {
    const checkStopped = async () => {
      const timeData = await jobrecordService.dailyTime();
      if (timeData.id.length < 0 || timeData.status === "stop") {
        this.preventDialog();
        this.pauseWork();
        return true;
      }
      return false;
    };
    if (await checkStopped()) {
      return;
    }

    const endTime = moment(this.$store.getters["timeTracker/startTime"]).add(
      this.$store.getters["timeTracker/dayMinutes"],
      "minutes"
    );
    timeInterval = setInterval(() => {
      if (
        this.$store.getters["timeTracker/pauseTime"] === null &&
        moment(this.currentTime).isAfter(endTime)
      ) {
        this.stopAndPrevent();
      }
      this.currentTime = Date.now();
    }, 1000);
    updateTimeInterval = setInterval(() => {
      this.updateWork();
    }, 60 * 1000);
  },

  beforeDestroy() {
    clearInterval(timeInterval);
    clearInterval(updateTimeInterval);
  },
};
</script>

<style lang="scss" scoped>
.time-tracker {
  z-index: 999;
  position: fixed;
  right: 80px;
  top: 9px;
}
</style>

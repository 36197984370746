import axiosInstance from "@/plugins/axios";
import store from "@/store";
import { getIdsFromFilter, requestFilters } from "@/utils";

export default {
  async getPermissionsUsers() {
    try {
      const { data } = await axiosInstance.get("/permits");
      const array = [];
      const keys = Object.keys(data);
      for (let i = 0; i < keys.length; i++) {
        const key = `${keys[i]}`;
        array.push({
          ...data[key],
          model: key,
        });
      }
      await store.dispatch("permissions/setPermissionsList", array);
    } catch (error) {
      throw new Error(error);
    }
  },
  addRules(data) {
    return axiosInstance.post("/permission", data);
  },
  async getActionPermissionIds(search) {
    try {
      const value = [search];
      const filters = requestFilters.like("name", value);
      const { data } = await axiosInstance.put(
        "action",
        { filters },
        { params: { size: -1, fields: "permission_ids" } }
      );
      const values = getIdsFromFilter(data.items, "permission_ids");
      return requestFilters.in("id", values[0]);
    } catch (error) {
      throw new Error(error);
    }
  },
};

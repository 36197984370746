export const getters = {
  navLinks: (state) => {
    return state.navLinks;
  },

  navGroups: (state) => {
    return state.navGroups;
  },

  navSimpleGroups: (state) => {
    return state.navSimpleGroups;
  },
};

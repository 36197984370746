export const mutations = {
  /**
   * @param State : state
   * @param {} budget
   */
  setBudgetSelected: (state, budget) => {
    state.budgetSelected = budget;
  },
  /**
   * @param State : state
   * @param {} boolean
   */
  setBudgetWrapper: (state, budgetWrapper) => {
    state.budgetWrapper = budgetWrapper;
  },
  /**
   * @param State : state
   * @param amount
   */
  setBudgetNotificationAmount: (state, amount) => {
    state.budgetNotificationAmount = amount;
  },
  /**
   * @param State : state
   * @param amount
   */
  setBudgetCurrentTab: (state, currentTab) => {
    state.budgetCurrentTab = currentTab;
  },
  /**
   * @param State : state
   * @param amount
   */
  setBudgetMonthlyData: (state, data) => {
    state.budgetMonthlyData = data;
  },
  /**
   * @param State : state
   * @param amount
   */
  setBudgetMonthlyProjectsData: (state, data) => {
    state.budgetMonthlyProjectData = data;
  },
};

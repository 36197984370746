<template>
  <v-main class="cont-card">
    <ForgotPasswordForm />
  </v-main>
</template>

<script>
import ForgotPasswordForm from "@/components/auth/ForgotPasswordForm";

export default {
  name: "ForgotPasswordView",
  components: { ForgotPasswordForm },
  methods: {},
};
</script>

<style scoped lang="scss"></style>

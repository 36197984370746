<template>
  <v-form
    ref="form"
    class="cont-form-login d-flex flex-column align-center"
    v-model="valid"
  >
    <h1 class="text-uppercase neutral--text text--darken-1">
      {{ $t("auth.login") }}
    </h1>
    <v-container
      class="pa-0 ma-0 d-flex flex-column align-center justify-start"
    >
      <text-field-custom
        v-model="form.username"
        class="text-field-custom pt-4"
        :rules="emailRules"
        :label="$t('auth.business_email')"
        type="email"
      />
      <text-field-custom
        v-model="form.password"
        class="text-field-custom pt-4"
        :label="$t('auth.password')"
        :rules="passwordRules"
        type="password"
      />
    </v-container>

    <router-link class="link-app" :to="'/forgot-password'"
      >{{ $t("auth.forget_password") }}
    </router-link>
    <div class="cont-btn">
      <button-custom block :label="$t('auth.enter')" @click="onLogin" />
    </div>
  </v-form>
</template>

<script>
import jobrecordService from "@/services/jobrecords";
import { mapActions } from "vuex";
import auth from "@/services/auth";
import genericModel from "@/services/genericModels";
import ButtonCustom from "@/components/buttons/ButtonCustom.vue";
import TextFieldCustom from "@/components/forms/TextFieldCustom.vue";
import { rulesForm } from "@/utils/rulesForm.utils";

const { required, email } = rulesForm();

export default {
  data: () => ({
    form: {
      username: "",
      password: "",
    },
    valid: false,
    emailRules: [required, email],
    passwordRules: [required],
  }),
  components: {
    ButtonCustom,
    TextFieldCustom,
  },
  methods: {
    ...mapActions({
      openSnackbar: "states/openSnackbar",
      setAuth: "auth/setAuth",
      setMessage: "states/setMessage",
      openWelcomeMessage: "states/openWelcomeMessage",
      preventDialog: "timeTracker/preventDialog",
      startTimer: "timeTracker/startTimer",
    }),
    /**
     * onLogin() : void
     * When user clicks "Ingresar / Login" button
     */
    async onLogin() {
      const isValid = this.$refs.form.validate();
      if (!isValid) return;
      // Uncomment this when api is online, and comment mock login.
      try {
        const { data } = await auth.login(this.form);
        this.setAuth(data); // If it is OK, save response on Vuex.
        const timeData = await jobrecordService.dailyTime();
        if (timeData.id.length > 0) {
          this.preventDialog();
          this.startTimer(timeData);
        }
        this.openSnackbar({
          title: this.$t("You have successfully logged in"),
          type: "success",
        });
        const filters = {
          filters: [
            {
              field: "user_id",
              op: "==",
              value: data.user.id,
            },
          ],
          sort_by: [
            {
              field: "updated_at",
              desc: true,
            },
          ],
        };
        const history = await genericModel.get(
          "history",
          {
            filters,
          },
          true
        );
        if (history.success) {
          if (history.data.total <= 2) {
            this.openWelcomeMessage();
          }
        }
        await this.$router.push({ name: `${data.user.user_role}.dashboard` }); // Redirect user to dashboard.
      } catch (error) {
        let errorMsg = error.detail
          ? error.detail
          : error.message
          ? error.message
          : this.$t("Error");
        if (error?.response?.data?.detail) {
          errorMsg = this.$t(error.response.data.detail);
        }
        this.openSnackbar({
          title: this.$t("There’s something wrong"),
          message: errorMsg,
          type: "error",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.text-field-custom {
  width: 454px;
  // margin: 20px 0;
  @media (max-width: 650px) {
    width: 70vw;
  }
}

.cont-btn {
  width: 456px;
  height: 44px !important;
  margin: 20px 0;
  font-size: 16px;
  line-height: 24px;
  @media (max-width: 650px) {
    width: 70vw;
  }
}

.cont-form-login {
  width: 552px;
  height: 423px;
  background: var(--v-neutral-lighten1);
  border-radius: 2px;
  @media (max-width: 650px) {
    width: 90vw;
  }

  h1 {
    padding-top: 54px;
    padding-bottom: 37px;
    font-size: 24px;
    font-family: $primary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }

  .link-app {
    font-family: $secondary-font;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-decoration-line: underline;
    color: var(--v-neutral-darken1);
    margin: 24px 0 19px 0;
  }
}
</style>

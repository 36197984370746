export const MONTHS = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];

export const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const ESTIMATION_MONTHS = [
  "estimation_jan",
  "estimation_feb",
  "estimation_mar",
  "estimation_apr",
  "estimation_may",
  "estimation_jun",
  "estimation_jul",
  "estimation_aug",
  "estimation_sep",
  "estimation_oct",
  "estimation_nov",
  "estimation_dec",
];

export const mutations = {
  /**
   * @param State : state
   * @param {} project
   */

  setProjectViewSelected: (state, project) => {
    state.projectViewSelected = project;
  },

  setProjectEdit: (state, project) => {
    state.projectEdit = project;
  },
  setHasProjectsPending: (state, hasPending) => {
    state.hasProjectsPending = hasPending;
  },
  setHasProjectsUnassignAccount: (state, hasPending) => {
    state.hasProjectsUnassignAccount = hasPending;
  },
};

import { MODEL, requestFilters, handlerErrorMessage } from "@/utils";
import axiosInstance from "@/plugins/axios";
import genericModels from "@/services/genericModels";
import helperData from "@/views/director/teams/employee_detail_report";
import store from "@/store";
import i18n from "@/plugins/i18n";

export default {
  /**
   * This function creates a jobrecord.
   * Used when user clicks on start timer and choose a work mode.
   * @param payload : { work_mode: Enum "remote work" | "on-site" | "outside" }
   * @returns Promise<void>
   */
  async start(payload) {
    try {
      if (!payload) return;
      const { data } = await axiosInstance.post(MODEL.JOBRECORD, payload);
      return data;
    } catch (error) {
      return handlerErrorMessage(error);
    }
  },
  async dailyTime() {
    try {
      const { data } = await axiosInstance.post(
        `${MODEL.JOBRECORD}/model_op/daily_time`
      );
      return data;
    } catch (error) {
      return handlerErrorMessage(error);
    }
  },
  /**
   * This function stops the jobrecord timer.
   * Used when user clicks on stop timer
   * @param payload : { jobRecordIds: uuid[] }
   * @returns Promise<void>
   */
  async stop(payload) {
    try {
      if (!payload) return;
      const { jobRecordIds } = payload;
      const { data } = await axiosInstance.post(
        `${MODEL.JOBRECORD}/op/set_stop?model_ids=${jobRecordIds}`
      );
      return data;
    } catch (error) {
      return handlerErrorMessage(error);
    }
  },
  /**
   * This function gets data of different graphics of the general dashboard
   * Used to get the data of Activity Graphics.
   * @param payload : {plots: Enum["activity" | "working_days" | "detail_table"],start_date: string"2022-11-01",end_date: string "2022-12-31", user_id?: uuid}
   * @returns Promise<{ data: any, success: true }>
   */
  async graphics(payload) {
    if (!payload) return;
    const { data } = await genericModels.postModelOperation(
      MODEL.JOBRECORD,
      "plot",
      payload
    );
    return data;
  },
  /**
   * Get Data Table General
   * @param startDate
   * @param endDate
   * @returns {Promise<any>}
   */
  async getDataTableDetail({ userId, startDate, endDate }) {
    const payloadPostOp = {
      ...helperData.operations,
    };

    let payload = {
      plots: ["detail_table"],
      start_date: startDate,
      end_date: endDate,
    };

    if (userId) {
      payload = {
        user_id: userId,
        plots: ["detail_table"],
        start_date: startDate,
        end_date: endDate,
      };
    }

    const { data } = await genericModels.postModelOperation(
      payloadPostOp.model,
      payloadPostOp.operationType,
      payload
    );
    return data;
  },
  /**
   * Get Data Table General
   * @param startDate
   * @param endDate
   * @returns {Promise<any>}
   */
  async getDirectorDataTableDetail(startDate, endDate) {
    const payloadPostOp = {
      ...helperData.operations,
    };
    const { data } = await genericModels.postModelOperation(
      payloadPostOp.model,
      payloadPostOp.operationDirectorTable,
      {
        start_date: startDate,
        end_date: endDate,
      }
    );
    return data;
  },
  /**
   * Get Data Table Detail By Month
   * @param startDate
   * @param endDate
   * @returns {Promise<any>}
   */
  async getDataTableDetailByMonth({ userId, year, month, pagination, page }) {
    const payloadPostOp = {
      ...helperData.operations,
    };
    const { data } = await genericModels.postModelOperation(
      payloadPostOp.model,
      payloadPostOp.operationTypeGet,
      {
        user_id: userId,
        year: year,
        month: month,
        pagination: pagination,
        page: page,
      }
    );
    return data;
  },
  /**
   * This function gets the kpi of the dashboard and the absences views
   * @returns Promise<{ user_schedule: [ { label: string, value: string} ] }>
   */
  async kpi() {
    const payload = {
      plots: ["user_schedule"],
      temporality: "this year",
    };
    return await this.graphics(payload);
  },
  /**
   * Get Job Records By Ids
   * @param ids
   * @returns {Promise<*>}
   */
  async getJobRecordsByIds(ids) {
    try {
      const payloadPostOp = {
        ...helperData.operations,
      };
      const filters = [...requestFilters.equal("id", ids)];
      const { data } = await axiosInstance.put(payloadPostOp.model, {
        filters,
      });
      return data.items;
    } catch (error) {
      await store.dispatch("states/openSnackbar", {
        title: i18n.t("There’s something wrong"),
        message: error.message || i18n.t("Error"),
        type: "error",
      });
    }
  },
};

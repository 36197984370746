export const actions = {
  /**
   * @param context : ActionContext<Store>
   * @param {} store
   */
  SET_YEARSTATISTIC_SELECTED: (context, yearstatistic) => {
    context.commit("setYearstatisticSelected", yearstatistic);
  },
  /**
   * @param context : ActionContext<Store>
   * @param {} store
   */
  SET_MONTHLYSALE_SELECTED: (context, monthlySale) => {
    context.commit("setMonthlySaleSelected", monthlySale);
  },
  /**
   * @param context : ActionContext<Store>
   * @param dataGraphics : Array
   */
  SET_DATA_GRAPHICS: (context, dataGraphics) => {
    context.commit("setDataGraphics", dataGraphics);
  },
};

const state = {
  snackbar: false,
  timeout: 4000,
  title: null,
  message: null,
  loading: false,
  type: "success",
  showWelcome: false,
  showWorkDayAlmostOver: false,
};

const mutations = {
  OPEN_SNACKBAR(state, payload) {
    state.snackbar = payload.snackbar;
    state.timeout = payload.timeout;
    state.type = payload.type;
    state.title = payload.title;
    state.message = payload.message;
  },
  CLOSE_SNACKBAR(state) {
    state.snackbar = false;
    state.message = null;
  },
  SET_MESSAGE(state, payload) {
    state.message = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  OPEN_WELCOME_MESSAGE(state) {
    state.showWelcome = true;
  },
  CLOSE_WELCOME_MESSAGE(state) {
    state.showWelcome = false;
  },
  OPEN_WORKDAY_ALMOST_OVER(state) {
    state.showWorkDayAlmostOver = true;
  },
  CLOSE_WORKDAY_ALMOST_OVER(state) {
    state.showWorkDayAlmostOver = false;
  },
};

const actions = {
  openSnackbar({ commit }, payload) {
    const {
      snackbar = true,
      timeout = 4000,
      type = "success",
      title = null,
      message = null,
    } = payload;

    commit(
      "OPEN_SNACKBAR",
      (payload = {
        snackbar: snackbar,
        timeout: timeout,
        type: type,
        title: title,
        message: message,
      })
    );

    if (payload.timeout > 0) {
      setTimeout(() => {
        commit("CLOSE_SNACKBAR");
      }, payload.timeout);
    }
  },
  closeSnackbar({ commit }) {
    commit("CLOSE_SNACKBAR");
  },
  setMessage({ commit }, payload) {
    commit("SET_MESSAGE", payload);
  },
  setLoading({ commit }, payload) {
    commit("SET_LOADING", payload);
  },
  openWelcomeMessage({ commit }) {
    commit("OPEN_WELCOME_MESSAGE");
  },
  closeWelcomeMessage({ commit }) {
    commit("CLOSE_WELCOME_MESSAGE");
  },
  openWorkDayAlmostOver({ commit }) {
    commit("OPEN_WORKDAY_ALMOST_OVER");
  },
  closeWorkDayAlmostOver({ commit }) {
    commit("CLOSE_WORKDAY_ALMOST_OVER");
  },
};

const getters = {
  showSnackbar: (state) => {
    return state.snackbar;
  },
  titleSnackbar: (state) => {
    return state.title;
  },
  messageSnackbar: (state) => {
    return state.message;
  },
  timeoutSnackbar: (state) => {
    return state.timeout;
  },
  typeSnackbar: (state) => {
    return state.type;
  },
  showLoading: (state) => {
    return state.loading;
  },
  showWelcomeMessage: (state) => {
    return state.showWelcome;
  },
  showWorkDayAlmostOver: (state) => {
    return state.showWorkDayAlmostOver;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export const contentTypes = [
  {
    extension: "x3d",
    mime: "application/vnd.hzn-3d-crossword",
  },
  {
    extension: "3gp",
    mime: "video/3gpp",
  },
  {
    extension: "3g2",
    mime: "video/3gpp2",
  },
  {
    extension: "mseq",
    mime: "application/vnd.mseq",
  },
  {
    extension: "pwn",
    mime: "application/vnd.3m.post-it-notes",
  },
  {
    extension: "plb",
    mime: "application/vnd.3gpp.pic-bw-large",
  },
  {
    extension: "psb",
    mime: "application/vnd.3gpp.pic-bw-small",
  },
  {
    extension: "pvb",
    mime: "application/vnd.3gpp.pic-bw-var",
  },
  {
    extension: "tcap",
    mime: "application/vnd.3gpp2.tcap",
  },
  {
    extension: "7z",
    mime: "application/x-7z-compressed",
  },
  {
    extension: "abw",
    mime: "application/x-abiword",
  },
  {
    extension: "ace",
    mime: "application/x-ace-compressed",
  },
  {
    extension: "acc",
    mime: "application/vnd.americandynamics.acc",
  },
  {
    extension: "acu",
    mime: "application/vnd.acucobol",
  },
  {
    extension: "atc",
    mime: "application/vnd.acucorp",
  },
  {
    extension: "adp",
    mime: "audio/adpcm",
  },
  {
    extension: "aab",
    mime: "application/x-authorware-bin",
  },
  {
    extension: "aam",
    mime: "application/x-authorware-map",
  },
  {
    extension: "aas",
    mime: "application/x-authorware-seg",
  },
  {
    extension: "air",
    mime: "application/vnd.adobe.air-application-installer-package+zip",
  },
  {
    extension: "swf",
    mime: "application/x-shockwave-flash",
  },
  {
    extension: "fxp",
    mime: "application/vnd.adobe.fxp",
  },
  {
    extension: "pdf",
    mime: "application/pdf",
  },
  {
    extension: "ppd",
    mime: "application/vnd.cups-ppd",
  },
  {
    extension: "dir",
    mime: "application/x-director",
  },
  {
    extension: "xdp",
    mime: "application/vnd.adobe.xdp+xml",
  },
  {
    extension: "xfdf",
    mime: "application/vnd.adobe.xfdf",
  },
  {
    extension: "aac",
    mime: "audio/x-aac",
  },
  {
    extension: "ahead",
    mime: "application/vnd.ahead.space",
  },
  {
    extension: "azf",
    mime: "application/vnd.airzip.filesecure.azf",
  },
  {
    extension: "azs",
    mime: "application/vnd.airzip.filesecure.azs",
  },
  {
    extension: "azw",
    mime: "application/vnd.amazon.ebook",
  },
  {
    extension: "ami",
    mime: "application/vnd.amiga.ami",
  },
  {
    extension: "N/A",
    mime: "application/andrew-inset",
  },
  {
    extension: "IANA - Andrew Inset",
    mime: "N/A",
  },
  {
    extension: "apk",
    mime: "application/vnd.android.package-archive",
  },
  {
    extension: "cii",
    mime: "application/vnd.anser-web-certificate-issue-initiation",
  },
  {
    extension: "fti",
    mime: "application/vnd.anser-web-funds-transfer-initiation",
  },
  {
    extension: "atx",
    mime: "application/vnd.antix.game-component",
  },
  {
    extension: "dmg",
    mime: "application/x-apple-diskimage",
  },
  {
    extension: "mpkg",
    mime: "application/vnd.apple.installer+xml",
  },
  {
    extension: "aw",
    mime: "application/applixware",
  },
  {
    extension: "les",
    mime: "application/vnd.hhe.lesson-player",
  },
  {
    extension: "arc",
    mime: "application/x-freearc",
  },
  {
    extension: "swi",
    mime: "application/vnd.aristanetworks.swi",
  },
  {
    extension: "s",
    mime: "text/x-asm",
  },
  {
    extension: "atomcat",
    mime: "application/atomcat+xml",
  },
  {
    extension: "atomsvc",
    mime: "application/atomsvc+xml",
  },
  {
    extension: "atom, .xml",
    mime: "application/atom+xml",
  },
  {
    extension: "ac",
    mime: "application/pkix-attr-cert",
  },
  {
    extension: "aif",
    mime: "audio/x-aiff",
  },
  {
    extension: "avi",
    mime: "video/x-msvideo",
  },
  {
    extension: "aep",
    mime: "application/vnd.audiograph",
  },
  {
    extension: "dxf",
    mime: "image/vnd.dxf",
  },
  {
    extension: "dwf",
    mime: "model/vnd.dwf",
  },
  {
    extension: "avif",
    mime: "image/avif",
  },
  {
    extension: "par",
    mime: "text/plain-bas",
  },
  {
    extension: "bcpio",
    mime: "application/x-bcpio",
  },
  {
    extension: "bin",
    mime: "application/octet-stream",
  },
  {
    extension: "bmp",
    mime: "image/bmp",
  },
  {
    extension: "torrent",
    mime: "application/x-bittorrent",
  },
  {
    extension: "cod",
    mime: "application/vnd.rim.cod",
  },
  {
    extension: "mpm",
    mime: "application/vnd.blueice.multipass",
  },
  {
    extension: "bmi",
    mime: "application/vnd.bmi",
  },
  {
    extension: "sh",
    mime: "application/x-sh",
  },
  {
    extension: "btif",
    mime: "image/prs.btif",
  },
  {
    extension: "rep",
    mime: "application/vnd.businessobjects",
  },
  {
    extension: "bz",
    mime: "application/x-bzip",
  },
  {
    extension: "bz2",
    mime: "application/x-bzip2",
  },
  {
    extension: "csh",
    mime: "application/x-csh",
  },
  {
    extension: "c",
    mime: "text/x-c",
  },
  {
    extension: "cdxml",
    mime: "application/vnd.chemdraw+xml",
  },
  {
    extension: "css",
    mime: "text/css",
  },
  {
    extension: "cda",
    mime: "application/x-cdf",
  },
  {
    extension: "cdx",
    mime: "chemical/x-cdx",
  },
  {
    extension: "cml",
    mime: "chemical/x-cml",
  },
  {
    extension: "csml",
    mime: "chemical/x-csml",
  },
  {
    extension: "cdbcmsg",
    mime: "application/vnd.contact.cmsg",
  },
  {
    extension: "cla",
    mime: "application/vnd.claymore",
  },
  {
    extension: "c4g",
    mime: "application/vnd.clonk.c4group",
  },
  {
    extension: "sub",
    mime: "image/vnd.dvb.subtitle",
  },
  {
    extension: "cdmia",
    mime: "application/cdmi-capability",
  },
  {
    extension: "cdmic",
    mime: "application/cdmi-container",
  },
  {
    extension: "cdmid",
    mime: "application/cdmi-domain",
  },
  {
    extension: "cdmio",
    mime: "application/cdmi-object",
  },
  {
    extension: "cdmiq",
    mime: "application/cdmi-queue",
  },
  {
    extension: "c11amc",
    mime: "application/vnd.cluetrust.cartomobile-config",
  },
  {
    extension: "c11amz",
    mime: "application/vnd.cluetrust.cartomobile-config-pkg",
  },
  {
    extension: "ras",
    mime: "image/x-cmu-raster",
  },
  {
    extension: "dae",
    mime: "model/vnd.collada+xml",
  },
  {
    extension: "csv",
    mime: "text/csv",
  },
  {
    extension: "cpt",
    mime: "application/mac-compactpro",
  },
  {
    extension: "wmlc",
    mime: "application/vnd.wap.wmlc",
  },
  {
    extension: "cgm",
    mime: "image/cgm",
  },
  {
    extension: "ice",
    mime: "x-conference/x-cooltalk",
  },
  {
    extension: "cmx",
    mime: "image/x-cmx",
  },
  {
    extension: "xar",
    mime: "application/vnd.xara",
  },
  {
    extension: "cmc",
    mime: "application/vnd.cosmocaller",
  },
  {
    extension: "cpio",
    mime: "application/x-cpio",
  },
  {
    extension: "clkx",
    mime: "application/vnd.crick.clicker",
  },
  {
    extension: "clkk",
    mime: "application/vnd.crick.clicker.keyboard",
  },
  {
    extension: "clkp",
    mime: "application/vnd.crick.clicker.palette",
  },
  {
    extension: "clkt",
    mime: "application/vnd.crick.clicker.template",
  },
  {
    extension: "clkw",
    mime: "application/vnd.crick.clicker.wordbank",
  },
  {
    extension: "wbs",
    mime: "application/vnd.criticaltools.wbs+xml",
  },
  {
    extension: "cryptonote",
    mime: "application/vnd.rig.cryptonote",
  },
  {
    extension: "cif",
    mime: "chemical/x-cif",
  },
  {
    extension: "cmdf",
    mime: "chemical/x-cmdf",
  },
  {
    extension: "cu",
    mime: "application/cu-seeme",
  },
  {
    extension: "cww",
    mime: "application/prs.cww",
  },
  {
    extension: "curl",
    mime: "text/vnd.curl",
  },
  {
    extension: "dcurl",
    mime: "text/vnd.curl.dcurl",
  },
  {
    extension: "mcurl",
    mime: "text/vnd.curl.mcurl",
  },
  {
    extension: "scurl",
    mime: "text/vnd.curl.scurl",
  },
  {
    extension: "car",
    mime: "application/vnd.curl.car",
  },
  {
    extension: "pcurl",
    mime: "application/vnd.curl.pcurl",
  },
  {
    extension: "cmp",
    mime: "application/vnd.yellowriver-custom-menu",
  },
  {
    extension: "dssc",
    mime: "application/dssc+der",
  },
  {
    extension: "xdssc",
    mime: "application/dssc+xml",
  },
  {
    extension: "deb",
    mime: "application/x-debian-package",
  },
  {
    extension: "uva",
    mime: "audio/vnd.dece.audio",
  },
  {
    extension: "uvi",
    mime: "image/vnd.dece.graphic",
  },
  {
    extension: "uvh",
    mime: "video/vnd.dece.hd",
  },
  {
    extension: "uvm",
    mime: "video/vnd.dece.mobile",
  },
  {
    extension: "uvu",
    mime: "video/vnd.uvvu.mp4",
  },
  {
    extension: "uvp",
    mime: "video/vnd.dece.pd",
  },
  {
    extension: "uvs",
    mime: "video/vnd.dece.sd",
  },
  {
    extension: "uvv",
    mime: "video/vnd.dece.video",
  },
  {
    extension: "dvi",
    mime: "application/x-dvi",
  },
  {
    extension: "seed",
    mime: "application/vnd.fdsn.seed",
  },
  {
    extension: "dtb",
    mime: "application/x-dtbook+xml",
  },
  {
    extension: "res",
    mime: "application/x-dtbresource+xml",
  },
  {
    extension: "ait",
    mime: "application/vnd.dvb.ait",
  },
  {
    extension: "svc",
    mime: "application/vnd.dvb.service",
  },
  {
    extension: "eol",
    mime: "audio/vnd.digital-winds",
  },
  {
    extension: "djvu",
    mime: "image/vnd.djvu",
  },
  {
    extension: "dtd",
    mime: "application/xml-dtd",
  },
  {
    extension: "mlp",
    mime: "application/vnd.dolby.mlp",
  },
  {
    extension: "wad",
    mime: "application/x-doom",
  },
  {
    extension: "dpg",
    mime: "application/vnd.dpgraph",
  },
  {
    extension: "dra",
    mime: "audio/vnd.dra",
  },
  {
    extension: "dfac",
    mime: "application/vnd.dreamfactory",
  },
  {
    extension: "dts",
    mime: "audio/vnd.dts",
  },
  {
    extension: "dtshd",
    mime: "audio/vnd.dts.hd",
  },
  {
    extension: "dwg",
    mime: "image/vnd.dwg",
  },
  {
    extension: "geo",
    mime: "application/vnd.dynageo",
  },
  {
    extension: "es",
    mime: "application/ecmascript",
  },
  {
    extension: "mag",
    mime: "application/vnd.ecowin.chart",
  },
  {
    extension: "mmr",
    mime: "image/vnd.fujixerox.edmics-mmr",
  },
  {
    extension: "rlc",
    mime: "image/vnd.fujixerox.edmics-rlc",
  },
  {
    extension: "exi",
    mime: "application/exi",
  },
  {
    extension: "mgz",
    mime: "application/vnd.proteus.magazine",
  },
  {
    extension: "epub",
    mime: "application/epub+zip",
  },
  {
    extension: "eml",
    mime: "message/rfc822",
  },
  {
    extension: "nml",
    mime: "application/vnd.enliven",
  },
  {
    extension: "xpr",
    mime: "application/vnd.is-xpr",
  },
  {
    extension: "xif",
    mime: "image/vnd.xiff",
  },
  {
    extension: "xfdl",
    mime: "application/vnd.xfdl",
  },
  {
    extension: "emma",
    mime: "application/emma+xml",
  },
  {
    extension: "ez2",
    mime: "application/vnd.ezpix-album",
  },
  {
    extension: "ez3",
    mime: "application/vnd.ezpix-package",
  },
  {
    extension: "fst",
    mime: "image/vnd.fst",
  },
  {
    extension: "fvt",
    mime: "video/vnd.fvt",
  },
  {
    extension: "fbs",
    mime: "image/vnd.fastbidsheet",
  },
  {
    extension: "fe_launch",
    mime: "application/vnd.denovo.fcselayout-link",
  },
  {
    extension: "f4v",
    mime: "video/x-f4v",
  },
  {
    extension: "flv",
    mime: "video/x-flv",
  },
  {
    extension: "fpx",
    mime: "image/vnd.fpx",
  },
  {
    extension: "npx",
    mime: "image/vnd.net-fpx",
  },
  {
    extension: "flx",
    mime: "text/vnd.fmi.flexstor",
  },
  {
    extension: "fli",
    mime: "video/x-fli",
  },
  {
    extension: "ftc",
    mime: "application/vnd.fluxtime.clip",
  },
  {
    extension: "fdf",
    mime: "application/vnd.fdf",
  },
  {
    extension: "f",
    mime: "text/x-fortran",
  },
  {
    extension: "mif",
    mime: "application/vnd.mif",
  },
  {
    extension: "fm",
    mime: "application/vnd.framemaker",
  },
  {
    extension: "fh",
    mime: "image/x-freehand",
  },
  {
    extension: "fsc",
    mime: "application/vnd.fsc.weblaunch",
  },
  {
    extension: "fnc",
    mime: "application/vnd.frogans.fnc",
  },
  {
    extension: "ltf",
    mime: "application/vnd.frogans.ltf",
  },
  {
    extension: "ddd",
    mime: "application/vnd.fujixerox.ddd",
  },
  {
    extension: "xdw",
    mime: "application/vnd.fujixerox.docuworks",
  },
  {
    extension: "xbd",
    mime: "application/vnd.fujixerox.docuworks.binder",
  },
  {
    extension: "oas",
    mime: "application/vnd.fujitsu.oasys",
  },
  {
    extension: "oa2",
    mime: "application/vnd.fujitsu.oasys2",
  },
  {
    extension: "oa3",
    mime: "application/vnd.fujitsu.oasys3",
  },
  {
    extension: "fg5",
    mime: "application/vnd.fujitsu.oasysgp",
  },
  {
    extension: "bh2",
    mime: "application/vnd.fujitsu.oasysprs",
  },
  {
    extension: "spl",
    mime: "application/x-futuresplash",
  },
  {
    extension: "fzs",
    mime: "application/vnd.fuzzysheet",
  },
  {
    extension: "g3",
    mime: "image/g3fax",
  },
  {
    extension: "gmx",
    mime: "application/vnd.gmx",
  },
  {
    extension: "gtw",
    mime: "model/vnd.gtw",
  },
  {
    extension: "txd",
    mime: "application/vnd.genomatix.tuxedo",
  },
  {
    extension: "ggb",
    mime: "application/vnd.geogebra.file",
  },
  {
    extension: "ggt",
    mime: "application/vnd.geogebra.tool",
  },
  {
    extension: "gdl",
    mime: "model/vnd.gdl",
  },
  {
    extension: "gex",
    mime: "application/vnd.geometry-explorer",
  },
  {
    extension: "gxt",
    mime: "application/vnd.geonext",
  },
  {
    extension: "g2w",
    mime: "application/vnd.geoplan",
  },
  {
    extension: "g3w",
    mime: "application/vnd.geospace",
  },
  {
    extension: "gsf",
    mime: "application/x-font-ghostscript",
  },
  {
    extension: "bdf",
    mime: "application/x-font-bdf",
  },
  {
    extension: "gtar",
    mime: "application/x-gtar",
  },
  {
    extension: "texinfo",
    mime: "application/x-texinfo",
  },
  {
    extension: "gnumeric",
    mime: "application/x-gnumeric",
  },
  {
    extension: "kml",
    mime: "application/vnd.google-earth.kml+xml",
  },
  {
    extension: "kmz",
    mime: "application/vnd.google-earth.kmz",
  },
  {
    extension: "gpx",
    mime: "application/gpx+xml",
  },
  {
    extension: "gqf",
    mime: "application/vnd.grafeq",
  },
  {
    extension: "gif",
    mime: "image/gif",
  },
  {
    extension: "gv",
    mime: "text/vnd.graphviz",
  },
  {
    extension: "gac",
    mime: "application/vnd.groove-account",
  },
  {
    extension: "ghf",
    mime: "application/vnd.groove-help",
  },
  {
    extension: "gim",
    mime: "application/vnd.groove-identity-message",
  },
  {
    extension: "grv",
    mime: "application/vnd.groove-injector",
  },
  {
    extension: "gtm",
    mime: "application/vnd.groove-tool-message",
  },
  {
    extension: "tpl",
    mime: "application/vnd.groove-tool-template",
  },
  {
    extension: "vcg",
    mime: "application/vnd.groove-vcard",
  },
  {
    extension: "gz",
    mime: "application/gzip",
  },
  {
    extension: "h261",
    mime: "video/h261",
  },
  {
    extension: "h263",
    mime: "video/h263",
  },
  {
    extension: "h264",
    mime: "video/h264",
  },
  {
    extension: "hpid",
    mime: "application/vnd.hp-hpid",
  },
  {
    extension: "hps",
    mime: "application/vnd.hp-hps",
  },
  {
    extension: "hdf",
    mime: "application/x-hdf",
  },
  {
    extension: "rip",
    mime: "audio/vnd.rip",
  },
  {
    extension: "hbci",
    mime: "application/vnd.hbci",
  },
  {
    extension: "jlt",
    mime: "application/vnd.hp-jlyt",
  },
  {
    extension: "pcl",
    mime: "application/vnd.hp-pcl",
  },
  {
    extension: "hpgl",
    mime: "application/vnd.hp-hpgl",
  },
  {
    extension: "hvs",
    mime: "application/vnd.yamaha.hv-script",
  },
  {
    extension: "hvd",
    mime: "application/vnd.yamaha.hv-dic",
  },
  {
    extension: "hvp",
    mime: "application/vnd.yamaha.hv-voice",
  },
  {
    extension: "sfd-hdstx",
    mime: "application/vnd.hydrostatix.sof-data",
  },
  {
    extension: "stk",
    mime: "application/hyperstudio",
  },
  {
    extension: "hal",
    mime: "application/vnd.hal+xml",
  },
  {
    extension: "html",
    mime: "text/html",
  },
  {
    extension: "irm",
    mime: "application/vnd.ibm.rights-management",
  },
  {
    extension: "sc",
    mime: "application/vnd.ibm.secure-container",
  },
  {
    extension: "ics",
    mime: "text/calendar",
  },
  {
    extension: "icc",
    mime: "application/vnd.iccprofile",
  },
  {
    extension: "ico",
    mime: "image/x-icon",
  },
  {
    extension: "igl",
    mime: "application/vnd.igloader",
  },
  {
    extension: "ief",
    mime: "image/ief",
  },
  {
    extension: "ivp",
    mime: "application/vnd.immervision-ivp",
  },
  {
    extension: "ivu",
    mime: "application/vnd.immervision-ivu",
  },
  {
    extension: "rif",
    mime: "application/reginfo+xml",
  },
  {
    extension: "3dml",
    mime: "text/vnd.in3d.3dml",
  },
  {
    extension: "spot",
    mime: "text/vnd.in3d.spot",
  },
  {
    extension: "igs",
    mime: "model/iges",
  },
  {
    extension: "i2g",
    mime: "application/vnd.intergeo",
  },
  {
    extension: "cdy",
    mime: "application/vnd.cinderella",
  },
  {
    extension: "xpw",
    mime: "application/vnd.intercon.formnet",
  },
  {
    extension: "fcs",
    mime: "application/vnd.isac.fcs",
  },
  {
    extension: "ipfix",
    mime: "application/ipfix",
  },
  {
    extension: "cer",
    mime: "application/pkix-cert",
  },
  {
    extension: "pki",
    mime: "application/pkixcmp",
  },
  {
    extension: "crl",
    mime: "application/pkix-crl",
  },
  {
    extension: "pkipath",
    mime: "application/pkix-pkipath",
  },
  {
    extension: "igm",
    mime: "application/vnd.insors.igm",
  },
  {
    extension: "rcprofile",
    mime: "application/vnd.ipunplugged.rcprofile",
  },
  {
    extension: "irp",
    mime: "application/vnd.irepository.package+xml",
  },
  {
    extension: "jad",
    mime: "text/vnd.sun.j2me.app-descriptor",
  },
  {
    extension: "jar",
    mime: "application/java-archive",
  },
  {
    extension: "class",
    mime: "application/java-vm",
  },
  {
    extension: "jnlp",
    mime: "application/x-java-jnlp-file",
  },
  {
    extension: "ser",
    mime: "application/java-serialized-object",
  },
  {
    extension: "java",
    mime: "text/x-java-source,java",
  },
  {
    extension: "js",
    mime: "application/javascript",
  },
  {
    extension: "mjs",
    mime: "text/javascript",
  },
  {
    extension: "mjs",
    mime: "text/javascript",
  },
  {
    extension: "json",
    mime: "application/json",
  },
  {
    extension: "joda",
    mime: "application/vnd.joost.joda-archive",
  },
  {
    extension: "jpm",
    mime: "video/jpm",
  },
  {
    extension: "jpeg",
    mime: "image/jpeg",
  },
  {
    extension: "jpg",
    mime: "image/jpg",
  },
  {
    extension: "pjpeg",
    mime: "image/pjpeg",
  },
  {
    extension: "jpgv",
    mime: "video/jpeg",
  },
  {
    extension: "jsonld",
    mime: "application/ld+json",
  },
  {
    extension: "ktz",
    mime: "application/vnd.kahootz",
  },
  {
    extension: "mmd",
    mime: "application/vnd.chipnuts.karaoke-mmd",
  },
  {
    extension: "karbon",
    mime: "application/vnd.kde.karbon",
  },
  {
    extension: "chrt",
    mime: "application/vnd.kde.kchart",
  },
  {
    extension: "kfo",
    mime: "application/vnd.kde.kformula",
  },
  {
    extension: "flw",
    mime: "application/vnd.kde.kivio",
  },
  {
    extension: "kon",
    mime: "application/vnd.kde.kontour",
  },
  {
    extension: "kpr",
    mime: "application/vnd.kde.kpresenter",
  },
  {
    extension: "ksp",
    mime: "application/vnd.kde.kspread",
  },
  {
    extension: "kwd",
    mime: "application/vnd.kde.kword",
  },
  {
    extension: "htke",
    mime: "application/vnd.kenameaapp",
  },
  {
    extension: "kia",
    mime: "application/vnd.kidspiration",
  },
  {
    extension: "kne",
    mime: "application/vnd.kinar",
  },
  {
    extension: "sse",
    mime: "application/vnd.kodak-descriptor",
  },
  {
    extension: "lasxml",
    mime: "application/vnd.las.las+xml",
  },
  {
    extension: "latex",
    mime: "application/x-latex",
  },
  {
    extension: "lbd",
    mime: "application/vnd.llamagraphics.life-balance.desktop",
  },
  {
    extension: "lbe",
    mime: "application/vnd.llamagraphics.life-balance.exchange+xml",
  },
  {
    extension: "jam",
    mime: "application/vnd.jam",
  },
  {
    extension: "123",
    mime: "application/vnd.lotus-1-2-3",
  },
  {
    extension: "apr",
    mime: "application/vnd.lotus-approach",
  },
  {
    extension: "pre",
    mime: "application/vnd.lotus-freelance",
  },
  {
    extension: "nsf",
    mime: "application/vnd.lotus-notes",
  },
  {
    extension: "org",
    mime: "application/vnd.lotus-organizer",
  },
  {
    extension: "scm",
    mime: "application/vnd.lotus-screencam",
  },
  {
    extension: "lwp",
    mime: "application/vnd.lotus-wordpro",
  },
  {
    extension: "lvp",
    mime: "audio/vnd.lucent.voice",
  },
  {
    extension: "m3u",
    mime: "audio/x-mpegurl",
  },
  {
    extension: "m4v",
    mime: "video/x-m4v",
  },
  {
    extension: "hqx",
    mime: "application/mac-binhex40",
  },
  {
    extension: "portpkg",
    mime: "application/vnd.macports.portpkg",
  },
  {
    extension: "mgp",
    mime: "application/vnd.osgeo.mapguide.package",
  },
  {
    extension: "mrc",
    mime: "application/marc",
  },
  {
    extension: "mrcx",
    mime: "application/marcxml+xml",
  },
  {
    extension: "mxf",
    mime: "application/mxf",
  },
  {
    extension: "nbp",
    mime: "application/vnd.wolfram.player",
  },
  {
    extension: "ma",
    mime: "application/mathematica",
  },
  {
    extension: "mathml",
    mime: "application/mathml+xml",
  },
  {
    extension: "mbox",
    mime: "application/mbox",
  },
  {
    extension: "mc1",
    mime: "application/vnd.medcalcdata",
  },
  {
    extension: "mscml",
    mime: "application/mediaservercontrol+xml",
  },
  {
    extension: "cdkey",
    mime: "application/vnd.mediastation.cdkey",
  },
  {
    extension: "mwf",
    mime: "application/vnd.mfer",
  },
  {
    extension: "mfm",
    mime: "application/vnd.mfmp",
  },
  {
    extension: "msh",
    mime: "model/mesh",
  },
  {
    extension: "mads",
    mime: "application/mads+xml",
  },
  {
    extension: "mets",
    mime: "application/mets+xml",
  },
  {
    extension: "mods",
    mime: "application/mods+xml",
  },
  {
    extension: "meta4",
    mime: "application/metalink4+xml",
  },
  {
    extension: "mcd",
    mime: "application/vnd.mcd",
  },
  {
    extension: "flo",
    mime: "application/vnd.micrografx.flo",
  },
  {
    extension: "igx",
    mime: "application/vnd.micrografx.igx",
  },
  {
    extension: "es3",
    mime: "application/vnd.eszigno3+xml",
  },
  {
    extension: "mdb",
    mime: "application/x-msaccess",
  },
  {
    extension: "asf",
    mime: "video/x-ms-asf",
  },
  {
    extension: "exe",
    mime: "application/x-msdownload",
  },
  {
    extension: "cil",
    mime: "application/vnd.ms-artgalry",
  },
  {
    extension: "cab",
    mime: "application/vnd.ms-cab-compressed",
  },
  {
    extension: "ims",
    mime: "application/vnd.ms-ims",
  },
  {
    extension: "application",
    mime: "application/x-ms-application",
  },
  {
    extension: "clp",
    mime: "application/x-msclip",
  },
  {
    extension: "mdi",
    mime: "image/vnd.ms-modi",
  },
  {
    extension: "eot",
    mime: "application/vnd.ms-fontobject",
  },
  {
    extension: "xls",
    mime: "application/vnd.ms-excel",
  },
  {
    extension: "xlam",
    mime: "application/vnd.ms-excel.addin.macroenabled.12",
  },
  {
    extension: "xlsb",
    mime: "application/vnd.ms-excel.sheet.binary.macroenabled.12",
  },
  {
    extension: "xltm",
    mime: "application/vnd.ms-excel.template.macroenabled.12",
  },
  {
    extension: "xlsm",
    mime: "application/vnd.ms-excel.sheet.macroenabled.12",
  },
  {
    extension: "chm",
    mime: "application/vnd.ms-htmlhelp",
  },
  {
    extension: "crd",
    mime: "application/x-mscardfile",
  },
  {
    extension: "lrm",
    mime: "application/vnd.ms-lrm",
  },
  {
    extension: "mvb",
    mime: "application/x-msmediaview",
  },
  {
    extension: "mny",
    mime: "application/x-msmoney",
  },
  {
    extension: "pptx",
    mime: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  },
  {
    extension: "sldx",
    mime: "application/vnd.openxmlformats-officedocument.presentationml.slide",
  },
  {
    extension: "ppsx",
    mime: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  },
  {
    extension: "potx",
    mime: "application/vnd.openxmlformats-officedocument.presentationml.template",
  },
  {
    extension: "xlsx",
    mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  },
  {
    extension: "xltx",
    mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  },
  {
    extension: "docx",
    mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },
  {
    extension: "dotx",
    mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  },
  {
    extension: "obd",
    mime: "application/x-msbinder",
  },
  {
    extension: "thmx",
    mime: "application/vnd.ms-officetheme",
  },
  {
    extension: "onetoc",
    mime: "application/onenote",
  },
  {
    extension: "pya",
    mime: "audio/vnd.ms-playready.media.pya",
  },
  {
    extension: "pyv",
    mime: "video/vnd.ms-playready.media.pyv",
  },
  {
    extension: "ppt",
    mime: "application/vnd.ms-powerpoint",
  },
  {
    extension: "ppam",
    mime: "application/vnd.ms-powerpoint.addin.macroenabled.12",
  },
  {
    extension: "sldm",
    mime: "application/vnd.ms-powerpoint.slide.macroenabled.12",
  },
  {
    extension: "pptm",
    mime: "application/vnd.ms-powerpoint.presentation.macroenabled.12",
  },
  {
    extension: "ppsm",
    mime: "application/vnd.ms-powerpoint.slideshow.macroenabled.12",
  },
  {
    extension: "potm",
    mime: "application/vnd.ms-powerpoint.template.macroenabled.12",
  },
  {
    extension: "mpp",
    mime: "application/vnd.ms-project",
  },
  {
    extension: "pub",
    mime: "application/x-mspublisher",
  },
  {
    extension: "scd",
    mime: "application/x-msschedule",
  },
  {
    extension: "xap",
    mime: "application/x-silverlight-app",
  },
  {
    extension: "stl",
    mime: "application/vnd.ms-pki.stl",
  },
  {
    extension: "cat",
    mime: "application/vnd.ms-pki.seccat",
  },
  {
    extension: "vsd",
    mime: "application/vnd.visio",
  },
  {
    extension: "vsdx",
    mime: "application/vnd.visio2013",
  },
  {
    extension: "wm",
    mime: "video/x-ms-wm",
  },
  {
    extension: "wma",
    mime: "audio/x-ms-wma",
  },
  {
    extension: "wax",
    mime: "audio/x-ms-wax",
  },
  {
    extension: "wmx",
    mime: "video/x-ms-wmx",
  },
  {
    extension: "wmd",
    mime: "application/x-ms-wmd",
  },
  {
    extension: "wpl",
    mime: "application/vnd.ms-wpl",
  },
  {
    extension: "wmz",
    mime: "application/x-ms-wmz",
  },
  {
    extension: "wmv",
    mime: "video/x-ms-wmv",
  },
  {
    extension: "wvx",
    mime: "video/x-ms-wvx",
  },
  {
    extension: "wmf",
    mime: "application/x-msmetafile",
  },
  {
    extension: "trm",
    mime: "application/x-msterminal",
  },
  {
    extension: "doc",
    mime: "application/msword",
  },
  {
    extension: "docm",
    mime: "application/vnd.ms-word.document.macroenabled.12",
  },
  {
    extension: "dotm",
    mime: "application/vnd.ms-word.template.macroenabled.12",
  },
  {
    extension: "wri",
    mime: "application/x-mswrite",
  },
  {
    extension: "wps",
    mime: "application/vnd.ms-works",
  },
  {
    extension: "xbap",
    mime: "application/x-ms-xbap",
  },
  {
    extension: "xps",
    mime: "application/vnd.ms-xpsdocument",
  },
  {
    extension: "midi",
    mime: "audio/midi",
  },
  {
    extension: "mid",
    mime: "audio/midi",
  },
  {
    extension: "mpy",
    mime: "application/vnd.ibm.minipay",
  },
  {
    extension: "afp",
    mime: "application/vnd.ibm.modcap",
  },
  {
    extension: "rms",
    mime: "application/vnd.jcp.javame.midlet-rms",
  },
  {
    extension: "tmo",
    mime: "application/vnd.tmobile-livetv",
  },
  {
    extension: "prc",
    mime: "application/x-mobipocket-ebook",
  },
  {
    extension: "mbk",
    mime: "application/vnd.mobius.mbk",
  },
  {
    extension: "dis",
    mime: "application/vnd.mobius.dis",
  },
  {
    extension: "plc",
    mime: "application/vnd.mobius.plc",
  },
  {
    extension: "mqy",
    mime: "application/vnd.mobius.mqy",
  },
  {
    extension: "msl",
    mime: "application/vnd.mobius.msl",
  },
  {
    extension: "txf",
    mime: "application/vnd.mobius.txf",
  },
  {
    extension: "daf",
    mime: "application/vnd.mobius.daf",
  },
  {
    extension: "fly",
    mime: "text/vnd.fly",
  },
  {
    extension: "mpc",
    mime: "application/vnd.mophun.certificate",
  },
  {
    extension: "mpn",
    mime: "application/vnd.mophun.application",
  },
  {
    extension: "mj2",
    mime: "video/mj2",
  },
  {
    extension: "mpga",
    mime: "audio/mpeg",
  },
  {
    extension: "ts",
    mime: "video/mp2t",
  },
  {
    extension: "mxu",
    mime: "video/vnd.mpegurl",
  },
  {
    extension: "mpeg",
    mime: "video/mpeg",
  },
  {
    extension: "m21",
    mime: "application/mp21",
  },
  {
    extension: "mp4a",
    mime: "audio/mp4",
  },
  {
    extension: "mp4",
    mime: "video/mp4",
  },
  {
    extension: "mp4",
    mime: "application/mp4",
  },
  {
    extension: "m3u8",
    mime: "application/vnd.apple.mpegurl",
  },
  {
    extension: "mus",
    mime: "application/vnd.musician",
  },
  {
    extension: "msty",
    mime: "application/vnd.muvee.style",
  },
  {
    extension: "mxml",
    mime: "application/xv+xml",
  },
  {
    extension: "ngdat",
    mime: "application/vnd.nokia.n-gage.data",
  },
  {
    extension: "n-gage",
    mime: "application/vnd.nokia.n-gage.symbian.install",
  },
  {
    extension: "ncx",
    mime: "application/x-dtbncx+xml",
  },
  {
    extension: "nc",
    mime: "application/x-netcdf",
  },
  {
    extension: "nlu",
    mime: "application/vnd.neurolanguage.nlu",
  },
  {
    extension: "dna",
    mime: "application/vnd.dna",
  },
  {
    extension: "nnd",
    mime: "application/vnd.noblenet-directory",
  },
  {
    extension: "nns",
    mime: "application/vnd.noblenet-sealer",
  },
  {
    extension: "nnw",
    mime: "application/vnd.noblenet-web",
  },
  {
    extension: "rpst",
    mime: "application/vnd.nokia.radio-preset",
  },
  {
    extension: "rpss",
    mime: "application/vnd.nokia.radio-presets",
  },
  {
    extension: "n3",
    mime: "text/n3",
  },
  {
    extension: "edm",
    mime: "application/vnd.novadigm.edm",
  },
  {
    extension: "edx",
    mime: "application/vnd.novadigm.edx",
  },
  {
    extension: "ext",
    mime: "application/vnd.novadigm.ext",
  },
  {
    extension: "gph",
    mime: "application/vnd.flographit",
  },
  {
    extension: "ecelp4800",
    mime: "audio/vnd.nuera.ecelp4800",
  },
  {
    extension: "ecelp7470",
    mime: "audio/vnd.nuera.ecelp7470",
  },
  {
    extension: "ecelp9600",
    mime: "audio/vnd.nuera.ecelp9600",
  },
  {
    extension: "oda",
    mime: "application/oda",
  },
  {
    extension: "ogx",
    mime: "application/ogg",
  },
  {
    extension: "oga",
    mime: "audio/ogg",
  },
  {
    extension: "ogv",
    mime: "video/ogg",
  },
  {
    extension: "dd2",
    mime: "application/vnd.oma.dd2+xml",
  },
  {
    extension: "oth",
    mime: "application/vnd.oasis.opendocument.text-web",
  },
  {
    extension: "opf",
    mime: "application/oebps-package+xml",
  },
  {
    extension: "qbo",
    mime: "application/vnd.intu.qbo",
  },
  {
    extension: "oxt",
    mime: "application/vnd.openofficeorg.extension",
  },
  {
    extension: "osf",
    mime: "application/vnd.yamaha.openscoreformat",
  },
  {
    extension: "weba",
    mime: "audio/webm",
  },
  {
    extension: "webm",
    mime: "video/webm",
  },
  {
    extension: "odc",
    mime: "application/vnd.oasis.opendocument.chart",
  },
  {
    extension: "otc",
    mime: "application/vnd.oasis.opendocument.chart-template",
  },
  {
    extension: "odb",
    mime: "application/vnd.oasis.opendocument.database",
  },
  {
    extension: "odf",
    mime: "application/vnd.oasis.opendocument.formula",
  },
  {
    extension: "odft",
    mime: "application/vnd.oasis.opendocument.formula-template",
  },
  {
    extension: "odg",
    mime: "application/vnd.oasis.opendocument.graphics",
  },
  {
    extension: "otg",
    mime: "application/vnd.oasis.opendocument.graphics-template",
  },
  {
    extension: "odi",
    mime: "application/vnd.oasis.opendocument.image",
  },
  {
    extension: "oti",
    mime: "application/vnd.oasis.opendocument.image-template",
  },
  {
    extension: "odp",
    mime: "application/vnd.oasis.opendocument.presentation",
  },
  {
    extension: "otp",
    mime: "application/vnd.oasis.opendocument.presentation-template",
  },
  {
    extension: "ods",
    mime: "application/vnd.oasis.opendocument.spreadsheet",
  },
  {
    extension: "ots",
    mime: "application/vnd.oasis.opendocument.spreadsheet-template",
  },
  {
    extension: "odt",
    mime: "application/vnd.oasis.opendocument.text",
  },
  {
    extension: "odm",
    mime: "application/vnd.oasis.opendocument.text-master",
  },
  {
    extension: "ott",
    mime: "application/vnd.oasis.opendocument.text-template",
  },
  {
    extension: "ktx",
    mime: "image/ktx",
  },
  {
    extension: "sxc",
    mime: "application/vnd.sun.xml.calc",
  },
  {
    extension: "stc",
    mime: "application/vnd.sun.xml.calc.template",
  },
  {
    extension: "sxd",
    mime: "application/vnd.sun.xml.draw",
  },
  {
    extension: "std",
    mime: "application/vnd.sun.xml.draw.template",
  },
  {
    extension: "sxi",
    mime: "application/vnd.sun.xml.impress",
  },
  {
    extension: "sti",
    mime: "application/vnd.sun.xml.impress.template",
  },
  {
    extension: "sxm",
    mime: "application/vnd.sun.xml.math",
  },
  {
    extension: "sxw",
    mime: "application/vnd.sun.xml.writer",
  },
  {
    extension: "sxg",
    mime: "application/vnd.sun.xml.writer.global",
  },
  {
    extension: "stw",
    mime: "application/vnd.sun.xml.writer.template",
  },
  {
    extension: "otf",
    mime: "application/x-font-otf",
  },
  {
    extension: "opus",
    mime: "audio/opus",
  },
  {
    extension: "osfpvg",
    mime: "application/vnd.yamaha.openscoreformat.osfpvg+xml",
  },
  {
    extension: "dp",
    mime: "application/vnd.osgi.dp",
  },
  {
    extension: "pdb",
    mime: "application/vnd.palm",
  },
  {
    extension: "p",
    mime: "text/x-pascal",
  },
  {
    extension: "paw",
    mime: "application/vnd.pawaafile",
  },
  {
    extension: "pclxl",
    mime: "application/vnd.hp-pclxl",
  },
  {
    extension: "efif",
    mime: "application/vnd.picsel",
  },
  {
    extension: "pcx",
    mime: "image/x-pcx",
  },
  {
    extension: "psd",
    mime: "image/vnd.adobe.photoshop",
  },
  {
    extension: "prf",
    mime: "application/pics-rules",
  },
  {
    extension: "pic",
    mime: "image/x-pict",
  },
  {
    extension: "chat",
    mime: "application/x-chat",
  },
  {
    extension: "p10",
    mime: "application/pkcs10",
  },
  {
    extension: "p12",
    mime: "application/x-pkcs12",
  },
  {
    extension: "p7m",
    mime: "application/pkcs7-mime",
  },
  {
    extension: "p7s",
    mime: "application/pkcs7-signature",
  },
  {
    extension: "p7r",
    mime: "application/x-pkcs7-certreqresp",
  },
  {
    extension: "p7b",
    mime: "application/x-pkcs7-certificates",
  },
  {
    extension: "p8",
    mime: "application/pkcs8",
  },
  {
    extension: "plf",
    mime: "application/vnd.pocketlearn",
  },
  {
    extension: "pnm",
    mime: "image/x-portable-anymap",
  },
  {
    extension: "pbm",
    mime: "image/x-portable-bitmap",
  },
  {
    extension: "pcf",
    mime: "application/x-font-pcf",
  },
  {
    extension: "pfr",
    mime: "application/font-tdpfr",
  },
  {
    extension: "pgn",
    mime: "application/x-chess-pgn",
  },
  {
    extension: "pgm",
    mime: "image/x-portable-graymap",
  },
  {
    extension: "png",
    mime: "image/png",
  },
  {
    extension: "png",
    mime: "image/x-citrix-png",
  },
  {
    extension: "png",
    mime: "image/x-png",
  },
  {
    extension: "ppm",
    mime: "image/x-portable-pixmap",
  },
  {
    extension: "pskcxml",
    mime: "application/pskc+xml",
  },
  {
    extension: "pml",
    mime: "application/vnd.ctc-posml",
  },
  {
    extension: "ai",
    mime: "application/postscript",
  },
  {
    extension: "pfa",
    mime: "application/x-font-type1",
  },
  {
    extension: "pbd",
    mime: "application/vnd.powerbuilder6",
  },
  {
    extension: "pgp",
    mime: "application/pgp-encrypted",
  },
  {
    extension: "pgp",
    mime: "application/pgp-signature",
  },
  {
    extension: "box",
    mime: "application/vnd.previewsystems.box",
  },
  {
    extension: "ptid",
    mime: "application/vnd.pvi.ptid1",
  },
  {
    extension: "pls",
    mime: "application/pls+xml",
  },
  {
    extension: "str",
    mime: "application/vnd.pg.format",
  },
  {
    extension: "ei6",
    mime: "application/vnd.pg.osasli",
  },
  {
    extension: "dsc",
    mime: "text/prs.lines.tag",
  },
  {
    extension: "psf",
    mime: "application/x-font-linux-psf",
  },
  {
    extension: "qps",
    mime: "application/vnd.publishare-delta-tree",
  },
  {
    extension: "wg",
    mime: "application/vnd.pmi.widget",
  },
  {
    extension: "qxd",
    mime: "application/vnd.quark.quarkxpress",
  },
  {
    extension: "esf",
    mime: "application/vnd.epson.esf",
  },
  {
    extension: "msf",
    mime: "application/vnd.epson.msf",
  },
  {
    extension: "ssf",
    mime: "application/vnd.epson.ssf",
  },
  {
    extension: "qam",
    mime: "application/vnd.epson.quickanime",
  },
  {
    extension: "qfx",
    mime: "application/vnd.intu.qfx",
  },
  {
    extension: "qt",
    mime: "video/quicktime",
  },
  {
    extension: "rar",
    mime: "application/x-rar-compressed",
  },
  {
    extension: "ram",
    mime: "audio/x-pn-realaudio",
  },
  {
    extension: "rmp",
    mime: "audio/x-pn-realaudio-plugin",
  },
  {
    extension: "rsd",
    mime: "application/rsd+xml",
  },
  {
    extension: "rm",
    mime: "application/vnd.rn-realmedia",
  },
  {
    extension: "bed",
    mime: "application/vnd.realvnc.bed",
  },
  {
    extension: "mxl",
    mime: "application/vnd.recordare.musicxml",
  },
  {
    extension: "musicxml",
    mime: "application/vnd.recordare.musicxml+xml",
  },
  {
    extension: "rnc",
    mime: "application/relax-ng-compact-syntax",
  },
  {
    extension: "rdz",
    mime: "application/vnd.data-vision.rdz",
  },
  {
    extension: "rdf",
    mime: "application/rdf+xml",
  },
  {
    extension: "rp9",
    mime: "application/vnd.cloanto.rp9",
  },
  {
    extension: "jisp",
    mime: "application/vnd.jisp",
  },
  {
    extension: "rtf",
    mime: "application/rtf",
  },
  {
    extension: "rtx",
    mime: "text/richtext",
  },
  {
    extension: "link66",
    mime: "application/vnd.route66.link66+xml",
  },
  {
    extension: "rss, .xml",
    mime: "application/rss+xml",
  },
  {
    extension: "shf",
    mime: "application/shf+xml",
  },
  {
    extension: "st",
    mime: "application/vnd.sailingtracker.track",
  },
  {
    extension: "svg",
    mime: "image/svg+xml",
  },
  {
    extension: "sus",
    mime: "application/vnd.sus-calendar",
  },
  {
    extension: "sru",
    mime: "application/sru+xml",
  },
  {
    extension: "setpay",
    mime: "application/set-payment-initiation",
  },
  {
    extension: "setreg",
    mime: "application/set-registration-initiation",
  },
  {
    extension: "sema",
    mime: "application/vnd.sema",
  },
  {
    extension: "semd",
    mime: "application/vnd.semd",
  },
  {
    extension: "semf",
    mime: "application/vnd.semf",
  },
  {
    extension: "see",
    mime: "application/vnd.seemail",
  },
  {
    extension: "snf",
    mime: "application/x-font-snf",
  },
  {
    extension: "spq",
    mime: "application/scvp-vp-request",
  },
  {
    extension: "spp",
    mime: "application/scvp-vp-response",
  },
  {
    extension: "scq",
    mime: "application/scvp-cv-request",
  },
  {
    extension: "scs",
    mime: "application/scvp-cv-response",
  },
  {
    extension: "sdp",
    mime: "application/sdp",
  },
  {
    extension: "etx",
    mime: "text/x-setext",
  },
  {
    extension: "movie",
    mime: "video/x-sgi-movie",
  },
  {
    extension: "ifm",
    mime: "application/vnd.shana.informed.formdata",
  },
  {
    extension: "itp",
    mime: "application/vnd.shana.informed.formtemplate",
  },
  {
    extension: "iif",
    mime: "application/vnd.shana.informed.interchange",
  },
  {
    extension: "ipk",
    mime: "application/vnd.shana.informed.package",
  },
  {
    extension: "tfi",
    mime: "application/thraud+xml",
  },
  {
    extension: "shar",
    mime: "application/x-shar",
  },
  {
    extension: "rgb",
    mime: "image/x-rgb",
  },
  {
    extension: "slt",
    mime: "application/vnd.epson.salt",
  },
  {
    extension: "aso",
    mime: "application/vnd.accpac.simply.aso",
  },
  {
    extension: "imp",
    mime: "application/vnd.accpac.simply.imp",
  },
  {
    extension: "twd",
    mime: "application/vnd.simtech-mindmapper",
  },
  {
    extension: "csp",
    mime: "application/vnd.commonspace",
  },
  {
    extension: "saf",
    mime: "application/vnd.yamaha.smaf-audio",
  },
  {
    extension: "mmf",
    mime: "application/vnd.smaf",
  },
  {
    extension: "spf",
    mime: "application/vnd.yamaha.smaf-phrase",
  },
  {
    extension: "teacher",
    mime: "application/vnd.smart.teacher",
  },
  {
    extension: "svd",
    mime: "application/vnd.svd",
  },
  {
    extension: "rq",
    mime: "application/sparql-query",
  },
  {
    extension: "srx",
    mime: "application/sparql-results+xml",
  },
  {
    extension: "gram",
    mime: "application/srgs",
  },
  {
    extension: "grxml",
    mime: "application/srgs+xml",
  },
  {
    extension: "ssml",
    mime: "application/ssml+xml",
  },
  {
    extension: "skp",
    mime: "application/vnd.koan",
  },
  {
    extension: "sgml",
    mime: "text/sgml",
  },
  {
    extension: "sdc",
    mime: "application/vnd.stardivision.calc",
  },
  {
    extension: "sda",
    mime: "application/vnd.stardivision.draw",
  },
  {
    extension: "sdd",
    mime: "application/vnd.stardivision.impress",
  },
  {
    extension: "smf",
    mime: "application/vnd.stardivision.math",
  },
  {
    extension: "sdw",
    mime: "application/vnd.stardivision.writer",
  },
  {
    extension: "sgl",
    mime: "application/vnd.stardivision.writer-global",
  },
  {
    extension: "sm",
    mime: "application/vnd.stepmania.stepchart",
  },
  {
    extension: "sit",
    mime: "application/x-stuffit",
  },
  {
    extension: "sitx",
    mime: "application/x-stuffitx",
  },
  {
    extension: "sdkm",
    mime: "application/vnd.solent.sdkm+xml",
  },
  {
    extension: "xo",
    mime: "application/vnd.olpc-sugar",
  },
  {
    extension: "au",
    mime: "audio/basic",
  },
  {
    extension: "wqd",
    mime: "application/vnd.wqd",
  },
  {
    extension: "sis",
    mime: "application/vnd.symbian.install",
  },
  {
    extension: "smi",
    mime: "application/smil+xml",
  },
  {
    extension: "xsm",
    mime: "application/vnd.syncml+xml",
  },
  {
    extension: "bdm",
    mime: "application/vnd.syncml.dm+wbxml",
  },
  {
    extension: "xdm",
    mime: "application/vnd.syncml.dm+xml",
  },
  {
    extension: "sv4cpio",
    mime: "application/x-sv4cpio",
  },
  {
    extension: "sv4crc",
    mime: "application/x-sv4crc",
  },
  {
    extension: "sbml",
    mime: "application/sbml+xml",
  },
  {
    extension: "tsv",
    mime: "text/tab-separated-values",
  },
  {
    extension: "tiff",
    mime: "image/tiff",
  },
  {
    extension: "tao",
    mime: "application/vnd.tao.intent-module-archive",
  },
  {
    extension: "tar",
    mime: "application/x-tar",
  },
  {
    extension: "tcl",
    mime: "application/x-tcl",
  },
  {
    extension: "tex",
    mime: "application/x-tex",
  },
  {
    extension: "tfm",
    mime: "application/x-tex-tfm",
  },
  {
    extension: "tei",
    mime: "application/tei+xml",
  },
  {
    extension: "txt",
    mime: "text/plain",
  },
  {
    extension: "dxp",
    mime: "application/vnd.spotfire.dxp",
  },
  {
    extension: "sfs",
    mime: "application/vnd.spotfire.sfs",
  },
  {
    extension: "tsd",
    mime: "application/timestamped-data",
  },
  {
    extension: "tpt",
    mime: "application/vnd.trid.tpt",
  },
  {
    extension: "mxs",
    mime: "application/vnd.triscape.mxs",
  },
  {
    extension: "t",
    mime: "text/troff",
  },
  {
    extension: "tra",
    mime: "application/vnd.trueapp",
  },
  {
    extension: "ttf",
    mime: "application/x-font-ttf",
  },
  {
    extension: "ttl",
    mime: "text/turtle",
  },
  {
    extension: "umj",
    mime: "application/vnd.umajin",
  },
  {
    extension: "uoml",
    mime: "application/vnd.uoml+xml",
  },
  {
    extension: "unityweb",
    mime: "application/vnd.unity",
  },
  {
    extension: "ufd",
    mime: "application/vnd.ufdl",
  },
  {
    extension: "uri",
    mime: "text/uri-list",
  },
  {
    extension: "utz",
    mime: "application/vnd.uiq.theme",
  },
  {
    extension: "ustar",
    mime: "application/x-ustar",
  },
  {
    extension: "uu",
    mime: "text/x-uuencode",
  },
  {
    extension: "vcs",
    mime: "text/x-vcalendar",
  },
  {
    extension: "vcf",
    mime: "text/x-vcard",
  },
  {
    extension: "vcd",
    mime: "application/x-cdlink",
  },
  {
    extension: "vsf",
    mime: "application/vnd.vsf",
  },
  {
    extension: "wrl",
    mime: "model/vrml",
  },
  {
    extension: "vcx",
    mime: "application/vnd.vcx",
  },
  {
    extension: "mts",
    mime: "model/vnd.mts",
  },
  {
    extension: "vtu",
    mime: "model/vnd.vtu",
  },
  {
    extension: "vis",
    mime: "application/vnd.visionary",
  },
  {
    extension: "viv",
    mime: "video/vnd.vivo",
  },
  {
    extension: "ccxml",
    mime: "application/ccxml+xml,",
  },
  {
    extension: "vxml",
    mime: "application/voicexml+xml",
  },
  {
    extension: "src",
    mime: "application/x-wais-source",
  },
  {
    extension: "wbxml",
    mime: "application/vnd.wap.wbxml",
  },
  {
    extension: "wbmp",
    mime: "image/vnd.wap.wbmp",
  },
  {
    extension: "wav",
    mime: "audio/x-wav",
  },
  {
    extension: "davmount",
    mime: "application/davmount+xml",
  },
  {
    extension: "woff",
    mime: "application/x-font-woff",
  },
  {
    extension: "wspolicy",
    mime: "application/wspolicy+xml",
  },
  {
    extension: "webp",
    mime: "image/webp",
  },
  {
    extension: "wtb",
    mime: "application/vnd.webturbo",
  },
  {
    extension: "wgt",
    mime: "application/widget",
  },
  {
    extension: "hlp",
    mime: "application/winhlp",
  },
  {
    extension: "wml",
    mime: "text/vnd.wap.wml",
  },
  {
    extension: "wmls",
    mime: "text/vnd.wap.wmlscript",
  },
  {
    extension: "wmlsc",
    mime: "application/vnd.wap.wmlscriptc",
  },
  {
    extension: "wpd",
    mime: "application/vnd.wordperfect",
  },
  {
    extension: "stf",
    mime: "application/vnd.wt.stf",
  },
  {
    extension: "wsdl",
    mime: "application/wsdl+xml",
  },
  {
    extension: "xbm",
    mime: "image/x-xbitmap",
  },
  {
    extension: "xpm",
    mime: "image/x-xpixmap",
  },
  {
    extension: "xwd",
    mime: "image/x-xwindowdump",
  },
  {
    extension: "der",
    mime: "application/x-x509-ca-cert",
  },
  {
    extension: "fig",
    mime: "application/x-xfig",
  },
  {
    extension: "xhtml",
    mime: "application/xhtml+xml",
  },
  {
    extension: "xml",
    mime: "application/xml",
  },
  {
    extension: "xdf",
    mime: "application/xcap-diff+xml",
  },
  {
    extension: "xenc",
    mime: "application/xenc+xml",
  },
  {
    extension: "xer",
    mime: "application/patch-ops-error+xml",
  },
  {
    extension: "rl",
    mime: "application/resource-lists+xml",
  },
  {
    extension: "rs",
    mime: "application/rls-services+xml",
  },
  {
    extension: "rld",
    mime: "application/resource-lists-diff+xml",
  },
  {
    extension: "xslt",
    mime: "application/xslt+xml",
  },
  {
    extension: "xop",
    mime: "application/xop+xml",
  },
  {
    extension: "xpi",
    mime: "application/x-xpinstall",
  },
  {
    extension: "xspf",
    mime: "application/xspf+xml",
  },
  {
    extension: "xul",
    mime: "application/vnd.mozilla.xul+xml",
  },
  {
    extension: "xyz",
    mime: "chemical/x-xyz",
  },
  {
    extension: "yaml",
    mime: "text/yaml",
  },
  {
    extension: "yang",
    mime: "application/yang",
  },
  {
    extension: "yin",
    mime: "application/yin+xml",
  },
  {
    extension: "zir",
    mime: "application/vnd.zul",
  },
  {
    extension: "zip",
    mime: "application/zip",
  },
  {
    extension: "zmm",
    mime: "application/vnd.handheld-entertainment+xml",
  },
  {
    extension: "zaz",
    mime: "application/vnd.zzazz.deck+xml",
  },
];

export default function auth({ next, router, from }) {
  if (!localStorage.getItem("access_token")) {
    if (from.name === "login") {
      return false;
    } else {
      return router.push({ name: "login" });
    }
  }

  return next();
}

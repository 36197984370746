<template>
  <div class="avatar">
    <div v-if="status" class="point" :class="colorClass"></div>
    <v-avatar :size="size" :color="color" :rounded="rounded">
      <img v-if="image" data-avatar="image" alt="Avatar" :src="image" />
      <span v-else data-avatar="text" class="white--text text-uppercase">{{
        firstLetterText
      }}</span>
    </v-avatar>
  </div>
</template>

<script>
import { PropTypes } from "@/types";

export default {
  name: "IconAvatar",
  props: {
    image: {
      default: null,
    },
    text: {
      default: null,
    },
    size: {
      default: "40px",
    },
    color: {
      default: null,
    },
    status: {
      default: null,
    },
    rounded: PropTypes.boolean,
  },
  computed: {
    firstLetterText() {
      return this.text ? this.text.substr(0, 2) : "NU";
    },

    colorClass() {
      return this.status === "on hold" ? "on-hold" : this.status;
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  position: relative;
}
.point {
  position: absolute;
  right: 4%;
  z-index: 1;
  width: 12px;
  height: 12px;
  background-color: var(--v-success-lighten1);
  border: 2px solid var(--v-neutral-lighten1);
  border-radius: 50%;
}
.active {
  background-color: var(--v-success-lighten1);
}
.on-hold {
  background-color: var(--v-secondary-base);
}
.archived {
  background-color: var(--v-error-base);
}
.disable {
  background-color: var(--v-neutral-lighten3);
}

.v-avatar {
  background-color: $color-neutral-900-2;

  span {
    font-weight: 500;
  }
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "../views/LoginView.vue";
import ForgotPasswordView from "@/views/ForgotPasswordView";
import admin from "@/router/admin";
import docs from "@/router/docs";
import director from "@/router/director";
import employee from "@/router/employee";
import ccm_manager from "@/router/ccm-manager";
import budget from "@/router/budget";
import tenant from "@/router/tenant";
import schedule from "@/router/schedule";
import files from "./files";
import suppliers from "./suppliers";
import ResetPasswordView from "@/views/ResetPasswordView";
import ConfirmationResetPasswordView from "@/views/ConfirmationResetPasswordView";
import { middlewareRoute } from "./middlewareRoute";
import InvalidTokenView from "@/views/InvalidTokenView";
import Page404View from "@/views/Page404View";
import content_management from "@/router/content-management";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: (to) => {
      return { name: "login", query: to.query };
    },
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: ForgotPasswordView,
  },
  {
    path: "/recover-password",
    name: "resetPassword",
    component: ResetPasswordView,
  },
  {
    path: "/confirmation-reset-password",
    name: "confirmationResetPassword",
    component: ConfirmationResetPasswordView,
  },
  {
    path: "/invalid-token",
    name: "invalidToken",
    component: InvalidTokenView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "*",
    name: "page404",
    component: Page404View,
  },
  ...admin,
  ...ccm_manager,
  ...director,
  ...employee,
  ...docs,
  ...budget,
  ...tenant,
  ...schedule,
  ...files,
  ...suppliers,
  ...content_management,
];

const router = new VueRouter({
  routes,
  mode: "history",
});

middlewareRoute(router);

export default router;

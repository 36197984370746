<template>
  <layout-background>
    <LoginForm />
  </layout-background>
</template>

<script>
import LoginForm from "@/components/auth/LoginForm.vue";
import LayoutBackground from "@/components/general/LayoutBackground";

export default {
  components: {
    LoginForm,
    LayoutBackground,
  },
};
</script>

<style scoped lang="scss"></style>

export const state = {
  dayMinutes: localStorage.getItem("TIME_TRACKER_DAY_MINUTES") || 0,
  startTime: +localStorage.getItem("TIME_TRACKER_START") || null,
  pauseTime: +localStorage.getItem("TIME_TRACKER_PAUSE") || null,
  workingMode: localStorage.getItem("TIME_TRACKER_WORKING_MODE") || null,
  timeLeft: null,
  timeToShowMessage:
    localStorage.getItem("TIME_TRACKER_TIME_LEFT_TO_SHOW_MESSAGE") || 0,
  timeToCloseSession:
    localStorage.getItem("TIME_TRACKER_TIME_LEFT_FINISH_WORK") || 0,
  preventTimerDialog: false,
  jobrecord:
    JSON.parse(localStorage.getItem("TIME_TRACKER_JOB_RECORD")) || null,
};

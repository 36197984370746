export const actions = {
  setNavLinks: (context, navLinks) => {
    context.commit("setNavLinks", navLinks);
  },

  setNavGroups: (context, navGroups) => {
    context.commit("setNavGroups", navGroups);
  },

  setNavSimpleGroups: (context, navSimpleGroups) => {
    context.commit("setNavSimpleGroups", navSimpleGroups);
  },
};

<template>
  <v-main class="cont-card">
    <section class="confirmation-reset-password-wrapper">
      <v-card elevation="0" class="confirmation-reset-password-wrapper__card">
        <v-container>
          <v-icon
            class="confirmation-reset-password-wrapper__card__icon mb-5"
            color="green darken-2"
            x-large
            >mdi-check-circle-outline</v-icon
          >
          <h2 class="confirmation-reset-password-wrapper__card__h2">
            {{ $t("views.password_reset") }}
          </h2>
          <p class="confirmation-reset-password-wrapper__card__text">
            {{ $t("views.password_has_confirmed_successfully") }}
          </p>
          <v-row>
            <v-col cols="12" md="12">
              <button-custom
                block
                :label="$t('views.login')"
                @click="goToLogin"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </section>
  </v-main>
</template>

<script>
import ButtonCustom from "@/components/buttons/ButtonCustom";

export default {
  name: "ConfirmationResetPasswordView",
  components: {
    ButtonCustom,
  },
  methods: {
    goToLogin() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style scoped lang="scss">
.confirmation-reset-password-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  &__card {
    width: 550px;
    padding: 30px;
    &__icon {
      margin: 0px auto;
      display: flex !important;
    }
    &__h2 {
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    &__text {
      text-align: center;
    }
  }
}
</style>

<template>
  <layout-background>
    <page-404 />
  </layout-background>
</template>

<script>
import LayoutBackground from "@/components/general/LayoutBackground";
import Page404 from "@/components/general/Page404";
export default {
  name: "Page404View",
  components: {
    LayoutBackground,
    Page404,
  },
  props: {},
};
</script>

<style lang="scss" scoped></style>

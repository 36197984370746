export const adjustColor = (hexadecimal, amount = 0) => {
  if (typeof hexadecimal === "string") {
    try {
      return (
        "#" +
        hexadecimal.replace(/^#/, "").replace(/../g, (hexadecimal) => {
          return (
            "0" +
            Math.min(
              255,
              Math.max(0, parseInt(hexadecimal, 16) + amount)
            ).toString(16)
          ).substr(-2);
        })
      );
    } catch (error) {
      throw new Error(error);
    }
  }
  throw new Error("The parameter is not a hexadecimal");
};

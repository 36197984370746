<template>
  <v-card
    width="245px"
    height="100%"
    outlined
    rounded="0"
    style="z-index: 0 !important"
    class="v-card-main-navigation-drawer"
  >
    <v-navigation-drawer permanent>
      <template v-slot:prepend>
        <section
          class="c-list-item-profile-info d-flex flex-row align-center flex-column"
        >
          <main-logo class="mt-4" />
          <v-list-item two-line class="px-2" style="max-width: 227px">
            <v-list-item-avatar>
              <icon-avatar :text="user.name" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                class="subtitle-1 white--text font-weight-bold"
              >
                {{ $t("navigation.hi") }} {{ user.name }}!
              </v-list-item-title>
              <v-list-item-subtitle
                class="subtitle-2 white--text font-italic text-capitalize"
              >
                {{ user.user_role }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-menu v-model="isOptionsMenuProfile">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon class="white--text">mdi-menu-down</v-icon>
                  </v-btn>
                </template>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
          <transition>
            <div class="c-options-menu-profile" v-if="isOptionsMenuProfile">
              <v-list-item data-logout="logout" @click="onLogout()">
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{
                  $t("navigation.log_out")
                }}</v-list-item-title>
              </v-list-item>
            </div>
          </transition>
        </section>
      </template>

      <v-list>
        <template v-for="item in navLinks">
          <v-list-item
            v-if="!item.links && item.rolesAllowed.includes(user_role)"
            :key="item.t"
            :to="{ name: item.to }"
            link
            exact
          >
            <v-list-item-icon>
              <v-icon class="ml-0">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{ $t(item.t) }}</v-list-item-title>
          </v-list-item>
          <v-list-group
            v-if="item.links && item.rolesAllowed.includes(user_role)"
            :key="item.t"
            :prepend-icon="item.icon"
            v-model="item.active"
            no-action
            active-class="ac"
            @click="
              onClickList(item);
              redirect(item.to);
            "
          >
            <template v-slot:activator>
              <v-list-item-title>{{ $t(item.t) }}</v-list-item-title>
            </template>
            <v-list-item
              v-for="item in item.links"
              :key="item.t"
              :to="{ name: item.to }"
              link
              exact
            >
              <v-list-item-title>
                {{ $t(item.t) }}
                <v-chip v-if="item.showChip" small label pill>{{
                  item.valueChip
                }}</v-chip>
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>

        <div v-for="(item, i) in navGroups" :key="i">
          <v-divider class="my-4" />
          <div class="group-title text-uppercase">{{ item.title }}</div>
          <div
            v-for="(section, indexSection) in item.sections"
            :key="section.t"
          >
            <v-list-group
              v-if="section.links && section.rolesAllowed.includes(user_role)"
              :id="`nav-group-${indexSection}`"
              v-model="section.active"
              :prepend-icon="section.icon"
              no-action
              active-class="ac"
              @click="
                onClickList(section);
                redirect(section.to);
              "
            >
              <template v-slot:activator>
                <v-list-item-title>{{ $t(section.t) }}</v-list-item-title>
              </template>
              <v-list-item
                v-for="section in section.links"
                :key="section.t"
                :to="{ name: section.to }"
                link
                exact
              >
                <v-list-item-title>
                  {{ $t(section.t) }}
                  <v-chip v-if="section.showChip" small label pill>{{
                    section.valueChip
                  }}</v-chip>
                </v-list-item-title>
              </v-list-item>
            </v-list-group>
          </div>
        </div>

        <div v-for="group in navSimpleGroups" :key="group.id">
          <v-divider class="my-4" />
          <div class="group-title">{{ $t(group.title).toUpperCase() }}</div>
          <v-list-item-group v-model="group.active" color="primary">
            <v-list-item
              v-for="(section, i) in group.sections"
              :key="i"
              :to="{ name: section.to }"
            >
              <v-list-item-icon>
                <v-icon v-text="section.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="$t(section.t)"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </div>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import IconAvatar from "@/components/icons/IconAvatar";
import MainLogo from "@/components/general/Logo.vue";
import authService from "@/services/auth";
import {
  CREATED_MODEL,
  getChipsNotifications,
  getPendingAbsenceNotification,
} from "@/utils";
import absenceService from "@/services/absence";
const { label } = CREATED_MODEL.PENDINGABSENCE;

export default {
  name: "MainNavigationDrawer",
  components: { IconAvatar, MainLogo },
  data() {
    return {
      isOptionsMenuProfile: false,
      budgetNotification: 0,
    };
  },
  computed: {
    ...mapState("project", ["hasProjectsPending"]),
    ...mapState("budget", ["budgetNotificationAmount"]),
    ...mapState("generic", [label]),
    ...mapGetters({
      user: "auth/user",
      user_role: "auth/user_role",
      budgetNotificationAmount: "budget/budgetNotificationAmount",
      navigationNavLinks: "navigationOptions/navLinks",
      navigationNavGroups: "navigationOptions/navGroups",
      navigationNavSimpleGroups: "navigationOptions/navSimpleGroups",
    }),
    navLinks() {
      const parent = "budget.budget_project";
      const children = [
        {
          t: "budget.budget_report",
          value: this.budgetNotificationAmount,
        },
        {
          t: "budget.projects",
          value: this.hasProjectsPending,
        },
      ];
      const navLinkCopy = JSON.parse(JSON.stringify(this.navigationNavLinks));
      const links = getChipsNotifications(navLinkCopy, parent, children);

      return links;
    },
    navGroups() {
      // Used to get how many absence notifications do we have pending.
      const navigationGroupCopy = getPendingAbsenceNotification(
        this.navigationNavGroups,
        this[label]
      );
      // Filter by user role
      return navigationGroupCopy.filter((group) =>
        group.rolesAllowed ? group.rolesAllowed.includes(this.user_role) : group
      );
    },
    navSimpleGroups() {
      return this.navigationNavSimpleGroups.filter((group) =>
        group.rolesAllowed ? group.rolesAllowed.includes(this.user_role) : group
      );
    },
  },
  methods: {
    ...mapActions({
      setClearUser: "auth/setClearUser",
      setClearTimer: "timeTracker/clearTimer",
      getBudgetPending: "budget/get_budgets_pending",
      getPendingProjects: "project/getPendingProjects",
      setNavLinks: "navigationOptions/setNavLinks",
      setNavGroups: "navigationOptions/setNavGroups",
      setSimpleGroups: "navigationOptions/setNavSimpleGroups",
      stopWork: "timeTracker/stopWork",
    }),
    async onLogout() {
      try {
        await this.stopWork();
        await authService.logout();
      } finally {
        await this.setClearUser();
        this.setClearTimer();
        await this.$router.push("/login");
      }
    },
    async redirect(url) {
      if (url) {
        await this.$router.push({ name: url }).catch(() => {});
      }
    },
    onClickList(item) {
      this.setPreviousItemState(item);
    },
    /**
     * When user cicks on a list, let's save that list and mark it to active so it will be saved if user reloads the page.
     * @param item : Object:  navLink | navGroup
     * @returns void
     */
    setPreviousItemState(item) {
      if (!item || !item.id) return;
      let active = true;
      let indexToFind = -1;
      if (item.active) {
        active = false;
      }
      const newItem = { ...item, active: active };
      indexToFind = this.navigationNavLinks.findIndex(
        (element) => element.id === item.id
      );
      // Nothing was found on navLinkss, so let's search by navGroups...
      if (indexToFind === -1) {
        let indexTitle = -1;
        let indexSection = -1;
        this.navigationNavGroups.forEach((navGroup, indexA) => {
          navGroup.sections.forEach((element, indexB) => {
            // Elements were found
            if (element.id === item.id) {
              indexTitle = indexA;
              indexSection = indexB;
            }
          });
        });
        // Elements were not found
        if (indexSection === -1 || indexTitle === -1) return;
        let navGroups = [...this.navigationNavGroups];
        navGroups[indexTitle].sections[indexSection] = newItem;
        setTimeout(() => {
          this.setNavGroups(navGroups);
        }, 500);
        return;
      }
      let navLinks = [...this.navigationNavLinks];
      navLinks[indexToFind] = newItem;
      setTimeout(() => {
        this.setNavLinks(navLinks);
      }, 500);
    },
  },
  async mounted() {
    await Promise.all([
      this.getPendingProjects(),
      this.getBudgetPending(),
      absenceService.getPendingAbsences(),
    ]);
  },
  created() {},
};
</script>

<style lang="scss">
.v-list-group__items > .v-list-item {
  padding-left: 50px !important;
}

.v-chip.v-size--small {
  position: absolute !important;
  right: 15px !important;
  top: 12px !important;
}

.v-card-main-navigation-drawer {
  /* Neutros/200 */
  border: 1px solid $color-neutral-500 !important;
  position: relative;
  z-index: +999 !important;
  .v-navigation-drawer__prepend {
    .c-list-item-profile-info {
      background: $color-neutral-900-4 !important;
      padding: 8px;
      gap: 16px;
      .subtitle-2 {
        /* Neutros/300 */
        color: $color-neutral-900-1 !important;
        font-size: 12px !important;
      }
    }

    .c-options-menu-profile {
      width: 100%;
      box-shadow: 0px 4px 4px rgba(85, 89, 109, 0.2);
      border-radius: 0px 0px 2px 2px;
      background: $color-neutral-900-4;
      .v-list-item {
        .v-list-item__title {
          color: $color-neutral-100;
          font-size: 14px;
        }
        .v-list-item__icon {
          align-self: auto;
          margin: 0 10px 0 0;
          i {
            color: $color-neutral-100;
          }
        }
      }
    }
  }

  .v-navigation-drawer__content {
    .v-list-item {
      padding: 0 10px;
      border-radius: 0px;
      /* Neutros/400 */
      color: $color-neutral-900-2 !important;

      &::before {
        border-radius: 0px;
      }

      .v-list-item__title {
        font-weight: 400;
        font-size: 16px;
        color: $color-neutral-900-2;
      }
      .v-list-item__icon {
        margin-right: 10px;
        margin-left: 0px;
      }
    }
    .v-list-group__items {
      padding-left: 25px;
      a {
        border-left: 1px solid var(--v-neutral-base);
      }
    }
    a.v-list-item--active {
      /* Primario/500 */
      border-left: 1px solid $color-primary-500;
      background: rgba(245, 238, 225, 0.5);

      /* Primario/500 */
      color: $color-primary-500 !important;

      .v-list-item__title {
        font-weight: 500;
      }
    }
  }
}

.group-title {
  margin-left: 16px;
  font-family: "Heebo";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $color-neutral-900-2;
}
.v-list-item__content {
  overflow: inherit !important;
}

.v-list-item__action {
  margin-left: 2px !important;
}
.theme--light.v-btn.v-btn--icon {
  margin-left: 2px !important;
}
</style>

<template>
  <div class="v-100">
    <slot></slot>
  </div>
</template>
<script>
import permissions from "@/services/permissions";
import auth from "@/services/auth";
export default {
  name: "WrapperLayout",
  created() {
    auth.getUserInfo();
    permissions.getPermissionsUsers();
  },
};
</script>

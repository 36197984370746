<template>
  <v-select
    @input="onInputSelect"
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
    item-text="name"
    append-icon="mdi-chevron-down"
  />
</template>

<script>
export default {
  name: "SelectDefault",
  props: ["value"],
  methods: {
    onInputSelect(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style></style>

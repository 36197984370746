import { verifyLocalStorage } from "./auth";

const state = {
  actionSelected: verifyLocalStorage("actionSelected") || {},
};

const getters = {
  actionSelected: (state) => {
    return state.actionSelected;
  },
  userIds: (state) => {
    return state.actionSelected?.user_ids?.map((value) => value[0]);
  },
};

const mutations = {
  /**
   * @param State : state
   * @param {} auth
   */
  setActionSelected: (state, actionSelected) => {
    state.actionSelected = actionSelected;
    if (actionSelected)
      localStorage.setItem("actionSelected", JSON.stringify(actionSelected));
  },
};

const actions = {
  /**
   * @param context : ActionContext<Store>
   * @param {} action
   */
  set_action_selected: (context, user) => {
    context.commit("setActionSelected", user);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export const getters = {
  newsSelected: (state) => {
    return state.newsSelected;
  },
  newsWrapper: (state) => {
    return state.newsWrapper;
  },
  notifications: (state) => {
    return state.notifications;
  },
  newsletters: (state) => {
    return state.newsletters;
  },
  news: (state) => {
    return state.newsletters.filter((item) => !item.is_deleted);
  },
  hasNotReadNews: (state) => {
    return state.newsletters
      .filter((item) => !item.is_deleted)
      .find((item) => item.is_read === false)
      ? true
      : false;
  },
  carousel: (state) => {
    const newsletters = state.newsletters.filter(
      (item) => item.show_in_carrusel
    );
    return newsletters;
  },
};

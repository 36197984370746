import genericModels from "@/services/genericModels";

export const actions = {
  /**
   * @param context : ActionContext<Store>
   * @param {} accountingmove
   */
  set_accountingmove_selected: (context, accountingmove) => {
    context.commit("setAccountingmoveSelected", accountingmove);
  },
  /**
   * @param context : ActionContext<Store>
   * @param {} accountingmove
   */
  get_accountingmove_data: (context, procede) => {
    genericModels
      .get(
        "budget",
        {
          filters: {
            filters: [
              {
                field: "invoice_id",
                op: "==",
                value: procede,
              },
            ],
          },
        },
        true
      )
      .then((data) => {
        if (data.success) {
          context.commit("setBudgetNotificationAmount", data.data.total);
        }
      });
  },
};

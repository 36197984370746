export const actions = {
  /**
   * @param context : ActionContext<Store>
   * @param {} tenant
   */
  set_tenant_selected: (context, tenant) => {
    context.commit("setTenantSelected", tenant);
  },
  /**
   * @param context : ActionContext<Store>
   * @param monthlyBudgetGraphics : Array.
   */
  set_monthly_budget_graphics: (context, monthlyBudgetGraphics) => {
    context.commit("setMonthlBudgetGraphics", monthlyBudgetGraphics);
  },
  /**
   * @param context : ActionContext<Store>
   * @param monthlyBudgetGraphicsAllTenants : Array.
   */
  set_monthly_budget_graphics_all_tenants: (
    context,
    monthlyBudgetGraphicsAllTenants
  ) => {
    context.commit(
      "setMonthlBudgetGraphicsAllTenants",
      monthlyBudgetGraphicsAllTenants
    );
  },
  get_tenants: (context) => {
    context.commit("getTenants");
  },
};

import navigation from "@/navigation/admin";
export const state = {
  navLinks:
    JSON.parse(localStorage.getItem("NAV_LINKS")) || navigation.navLinks,
  navGroups:
    JSON.parse(localStorage.getItem("NAV_GROUPS")) || navigation.navGroups,
  navSimpleGroups:
    JSON.parse(localStorage.getItem("NAV_SIMPLE_GROUPS")) ||
    navigation.navSimpleGroups,
};

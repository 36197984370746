<template>
  <v-snackbar
    :timeout="timeoutSnackbar"
    :value="showSnackbar"
    tile
    :color="typeSnackbar === 'success' ? 'neutral darken-2' : 'error lighten-4'"
  >
    <v-row no-gutters class="snackbar-wrapper">
      <v-col cols="1" sm="1" md="2">
        <v-icon v-if="typeSnackbar == 'success'" dark right>
          mdi-checkbox-marked-circle
        </v-icon>
        <v-icon v-else dark right> mdi-close-circle </v-icon>
      </v-col>
      <v-col cols="9" md="9">
        <v-row no-gutters>
          <h4 class="snackbar-wrapper__title subtitle2">
            {{ titleSnackbar }}
          </h4>
          <p class="snackbar-wrapper__message">{{ messageSnackbar }}</p>
        </v-row>
      </v-col>
      <v-col cols="1" sm="1" md="1">
        <v-icon dark right size="14" @click="close"> mdi-close </v-icon>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";

export default {
  name: "MainSnackbar",
  computed: {
    ...mapGetters({
      showSnackbar: "states/showSnackbar",
      titleSnackbar: "states/titleSnackbar",
      messageSnackbar: "states/messageSnackbar",
      timeoutSnackbar: "states/timeoutSnackbar",
      typeSnackbar: "states/typeSnackbar",
    }),
  },
  methods: {
    async close() {
      await store.dispatch("states/closeSnackbar");
    },
  },
};
</script>

<style scoped lang="scss">
.snackbar-wrapper {
  display: flex;
  align-items: center;
  &__title {
    font-weight: bold;
    margin-bottom: 5px;
    width: 100%;
  }
  &__message {
    width: 100%;
    margin-bottom: 0px;
  }
}
</style>

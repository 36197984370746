export const addZeroToDecimal = (number) => {
  let amount = number || "0.00";
  const amountString = amount.toString();
  const separation = amountString.split(".");
  if (separation.length > 1) {
    const decimal = separation[1];
    if (decimal.length == 1) {
      return number + "0";
    }
  } else {
    return number + ".00";
  }
  return number;
};

export const unformattedCurrency = (currency) => {
  try {
    const splitValue = currency.split(",");
    const decimal = splitValue[1];
    const number = splitValue[0].split(".").join("");
    const value = parseFloat(`${number}.${decimal}`);
    return value;
  } catch (error) {
    throw new Error("Invalid Value");
  }
};

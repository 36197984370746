<template>
  <section class="loading-wrapper" v-if="showLoading">
    <lottie
      :options="defaultOptions"
      :height="220"
      :width="180"
      v-on:animCreated="handleAnimation"
    />
  </section>
</template>

<script>
import * as animationData from "@/assets/loading_SGI.json";
import LottieAnim from "@/components/general/LottieAnim";
import { mapGetters } from "vuex";
export default {
  name: "LoadingDefault",
  components: {
    lottie: LottieAnim,
  },
  data() {
    return {
      defaultOptions: { animationData: animationData },
      animationSpeed: 1,
    };
  },
  computed: {
    ...mapGetters({
      showLoading: "states/showLoading",
    }),
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    play: function () {
      this.anim.play();
    },
  },
};
</script>

<style scoped lang="scss">
.loading-wrapper {
  width: 100%;
  height: 100vh;
  background: rgba(41, 45, 69, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: +1000;
}
</style>

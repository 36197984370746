const role = ["administrator", "finance"];
const layout = "admin";
import authMiddleware from "@/middlewares/auth";
import roleMiddleware from "@/middlewares/role";

const metaContentManagement = {
  role,
  layout,
  middleware: [authMiddleware, roleMiddleware],
};

const content_management = [
  {
    path: "/content-management/dashboard",
    name: "content_management.dashboard",
    component: () => import("@/views/content-management/DashboardView.vue"),
    meta: metaContentManagement,
  },
];

export default content_management;

import jobrecordService from "@/services/jobrecords";
import deepClone from "lodash.clonedeep";
import moment from "moment/moment";

export const actions = {
  updateWork: async (context) => {
    const timeData = await jobrecordService.dailyTime();

    if (timeData.id.length > 0) {
      // si hay un registro de trabajo, es porque no esta pausado, entonces iniciamos el trabajo
      context.commit("setJobRecord", { id: timeData.id[0] });
      context.commit("startWork", {
        workingMode: timeData["work mode"][0],
        dailyTime: timeData,
      });
    } else {
      //sino, simplemenmte actualizamos el tiempo
      context.commit("updateWork", {
        dailyTime: timeData,
      });
    }
  },
  startTimer: async (context, timeData) => {
    context.commit("setJobRecord", { id: timeData.id[0] });
    context.commit("startWork", {
      workingMode: timeData["work mode"][0],
      dailyTime: timeData,
    });
  },
  startWork: async (context, workingMode) => {
    const timeData = await jobrecordService.dailyTime();
    const startData = await jobrecordService.start({ work_mode: workingMode });

    // Send a request to the backend
    // Only after sending a request we set it in the store
    context.commit("setJobRecord", startData); // Save the jobrecord on Vuex.
    context.commit("startWork", {
      workingMode,
      dailyTime: timeData,
    });
  },
  stopWork: async (context) => {
    const state = deepClone(context.state); // Clone the state
    context.commit("stopWork"); // Clear the store
    if (state.pauseTime === null) {
      const payload = { jobRecordIds: state?.jobrecord.id };
      await jobrecordService.stop(payload);
    }
  },
  toggleWork: async (context) => {
    if (context.state.pauseTime) {
      context.commit("resumeWork");
      const startData = await jobrecordService.start({
        work_mode: context.state.workingMode,
      });
      context.commit("setJobRecord", startData);
    } else {
      context.commit("pauseWork");
      const payload = { jobRecordIds: context.state?.jobrecord.id };
      await jobrecordService.stop(payload);
    }
  },
  pauseWork: async (context) => {
    if (context.state.pauseTime) return;
    context.commit("pauseWork");
    const payload = { jobRecordIds: context.state?.jobrecord.id };
    await jobrecordService.stop(payload);
  },
  getDailyWork: async (context) => {
    const payload = {
      plots: ["daily_activity"],
    };
    const { daily_activity } = await jobrecordService.graphics(payload);
    const timeLeft = daily_activity[0]?.value[2].value;
    context.commit(
      "timeLeftToFinishWork",
      moment.duration(timeLeft).asMinutes()
    );
  },
  preventDialog: (context) => {
    context.commit("preventDialog");
  },
  clearTimer: (context) => {
    context.commit("clearState");
  },
};

<template>
  <wrapper-dialog
    :title="$t('time_tracking.working_timer')"
    :value="true"
    height="216px"
    maxWidth="344px"
    closable
    v-bind="$attrs"
    v-on="$listeners"
  >
    <select-default
      class="mt-2"
      v-model="workingMode"
      :items="workingModes"
      :label="$t('time_tracking.select_working_mode')"
    />
    <div class="text-right mt-4">
      <button-custom
        class="ml-4"
        :label="$t('time_tracking.accept')"
        :disabled="!workingMode"
        @click="startWork(workingMode)"
      />
    </div>
  </wrapper-dialog>
</template>

<script>
import ButtonCustom from "@/components/buttons/ButtonCustom.vue";
import WrapperDialog from "@/components/general/WrapperDialog";
import SelectDefault from "@/components/forms/SelectDefault.vue";
import { WORKING_MODES_LIST } from "@/utils/constants/workingModes";
import { mapActions } from "vuex";

export default {
  name: "DialogWorkingTimer",
  components: {
    ButtonCustom,
    WrapperDialog,
    SelectDefault,
  },

  data: () => ({
    workingModes: WORKING_MODES_LIST,
    workingMode: "",
    close: false,
  }),

  methods: {
    ...mapActions({
      startWork: "timeTracker/startWork",
    }),
  },
};
</script>

<style>
.v-card__title {
  word-break: break-word !important;
}
</style>

export const getters = {
  budgetSelected: (state) => {
    return state.budgetSelected;
  },
  budgetWrapper: (state) => {
    return state.budgetWrapper;
  },
  budgetNotificationAmount: (state) => {
    return state.budgetNotificationAmount;
  },
  budgetCurrentTab: (state) => {
    return state.budgetCurrentTab;
  },
  budgetMonthlyData: (state) => {
    return state.budgetMonthlyData;
  },
  budgetMonthlyProjectData: (state) => {
    return state.budgetMonthlyProjectData;
  },
};

import { USER_ROLE } from "@/utils/constants";
const navLinks = [
  {
    id: 1,
    t: "Dashboard",
    icon: "mdi-home",
    to: "administrator.dashboard",
    rolesAllowed: [USER_ROLE.ADM, USER_ROLE.EMP],
  },
  {
    id: 2,
    t: "tenant.app_name",
    icon: "mdi-store",
    rolesAllowed: [USER_ROLE.ADM, USER_ROLE.EMP],
    links: [
      {
        t: "tenant.stores",
        to: "shared.tenant.stores",
      },
      {
        t: "tenant.app_name",
        to: "shared.tenant.dashboard",
      },
    ],
  },
  {
    id: 3,
    t: "budget.budget_project",
    icon: "mdi-receipt",
    rolesAllowed: [USER_ROLE.ADM, USER_ROLE.EMP],
    to: "shared.budget.dashboard",
    links: [
      {
        t: "budget.budget_report",
        to: "shared.budget.report",
      },
      {
        t: "budget.projects",
        to: "shared.budget.project",
      },
      {
        t: "tax_base.expenses",
        to: "finances.expenses",
      },
      // comments on request 1837
      // {
      //   t: "budget.invoice",
      //   to: "shared.budget.invoice",
      // },
    ],
  },
  {
    id: 4,
    t: "ccm_manager.title",
    icon: "mdi-domain",
    to: "ccm_manager.dashboard",
    rolesAllowed: [USER_ROLE.ADM],
  },
  {
    id: 5,
    t: "suppliers.suppliers",
    icon: "mdi-cart",
    to: "suppliers.dashboard",
    rolesAllowed: [USER_ROLE.EMP, USER_ROLE.DIR, USER_ROLE.ADM],
  },
];

const navGroups = [
  {
    title: "personal",
    rolesAllowed: [USER_ROLE.EMP, USER_ROLE.DIR, USER_ROLE.ADM],
    sections: [
      /*
      {
        id: 7,
        t: "schedule.schedule_name",
        icon: "mdi-account-multiple",
        rolesAllowed: [USER_ROLE.EMP, USER_ROLE.DIR, USER_ROLE.ADM],
        links: [
          {
            t: "teams.time_report",
            to: "schedule.time_report",
          },
          {
            t: "schedule.absences_record",
            to: "schedule.absences-record",
          },
        ],
      },
      */
      {
        id: 7,
        t: "files.files",
        icon: "mdi-folder-outline",
        rolesAllowed: [USER_ROLE.EMP, USER_ROLE.DIR, USER_ROLE.ADM],
        links: [
          {
            t: "files.shared_files",
            to: "files.shared_files",
          },
          {
            t: "files.my_files",
            to: "files.my_files",
          },
        ],
      },
      {
        id: 8,
        t: "Users manager",
        icon: "mdi-account-cog",
        rolesAllowed: [USER_ROLE.ADM],
        links: [
          {
            t: "user.users",
            to: "admin.users",
          },
          {
            t: "user.categories",
            to: "admin.users.categories",
          },
          {
            t: "user.actions",
            to: "admin.users.actions",
          },
          // {
          //   t: "user.permissions",
          //   to: "admin.users.permissions",
          // },
          // {
          //   t: "user.rules",
          //   to: "admin.users.rules",
          // },
        ],
      },
    ],
  },
  // TEAM MANAGER FOR ADMIN AND DIRECTOR
  {
    title: "team manager",
    rolesAllowed: [USER_ROLE.ADM, USER_ROLE.DIR],
    sections: [
      {
        id: 9,
        t: "teams.teams", // Don't change this t string, this is used on getPendingAbsenceNotification function
        icon: "mdi-account-multiple",
        rolesAllowed: [USER_ROLE.ADM, USER_ROLE.DIR],
        links: [
          {
            t: "teams.time_report",
            to: "teams.time_report",
          },
          {
            t: "schedule.absences", // Don't change this t string, this is used on getPendingAbsenceNotification function
            to: "schedule.absences",
            showChip: true,
            valueChip: 0,
          },
          {
            t: "teams.absences_record",
            to: "schedule.absences-record",
          },
        ],
      },
    ],
  },
  //TEAM MANAGER FOR EMPLOYEE
  {
    title: "team manager",
    rolesAllowed: [USER_ROLE.EMP],
    sections: [
      {
        id: 10,
        t: "teams.teams",
        icon: "mdi-account-multiple",
        rolesAllowed: [USER_ROLE.EMP],
        links: [
          {
            t: "teams.time_report",
            to: "teams.user_time_report_year",
          },
          {
            t: "schedule.absences_record",
            to: "schedule.absences-record",
          },
        ],
      },
    ],
  },
];

const navSimpleGroups = [
  {
    title: "content management",
    active: true,
    rolesAllowed: [USER_ROLE.ADM, USER_ROLE.DIR],
    sections: [
      {
        id: 11,
        t: "content_management.news",
        icon: "mdi-account-multiple",
        rolesAllowed: [USER_ROLE.ADM, USER_ROLE.DIR],
        to: "content_management.dashboard",
      },
    ],
  },
];

export default {
  navLinks,
  navGroups,
  navSimpleGroups,
};

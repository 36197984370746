const navLinks = [
  {
    t: "Budget",
    icon: "mdi-receipt",
    links: [
      {
        t: "budget.budget_report",
        to: "shared.budget.report",
      },
      {
        t: "budget.projects",
        to: "shared.budget.project",
      },
      {
        t: "tax_base.expenses",
        to: "finances.expenses",
      },
      // comments on request 1837
      // {
      //   t: "budget.invoice",
      //   to: "shared.budget.invoice",
      // },
    ],
  },
];

export default {
  navLinks,
};

/* eslint-disable prettier/prettier */
export default {
  global: {
    newsletter: "Boletin informativo",
    download_template: "Descargar plantilla",
    upload_file_csv: "Subir archivo CSV",
    the_token_expired: "El token ha expirado",
    please_try_to_reset_the_password:
      "Intente restablecer la contraseña nuevamente en unos minutos.",
    login: "Login",
    edit_month: "Editar mes #",
    estimated_amount: "Monto estimado",
    cancel: "Cancelar",
    save: "Guardar",
    create: "Crear",
    advanced_filters: "Filtros avanzados",
    category: "Categoría",
    download_report: "Descargar informe",
    business_unit: "Unidad de negocio",
    department: "Departamento",
    status: "Estado",
    apply_filters: "Aplicar filtros",
    search_by_name: "Buscar por nombre",
    detail: "Detalles",
    movements: "Movimientos",
    attachments_and_files: "Adjuntos y archivos",
    all: "Todo",
    pending: "Pendiente",
    upload: "Upload",
    company: "Compañía",
    cif: "CIF",
    year: "Año",
    information: "Información",
    contact: "Contacto",
    financial: "Financiera",
    email: "Correo",
    area_code: "Código de área",
    phone: "Teléfono",
    add_file: "Agregar archivo",
    phone_number: "Número de teléfono",
    consumption: "Consumido",
    image_editor: "Editor de imagen",
    post_detail: "Detalles del post",
    back: "Regresar",
    view_details: "Ver detalles",
    pending_request: "Peticiones pendientes",
    workday_almost_over: "Jornada laboral próxima a finalizar",
    workday_almost_over_message: "{start_hour} - {end_hour}. Hasta pronto {name}",
    ok_got_it: "Vale, lo tengo",
    hours: "hora",
    hour: "horas",
    hours_left: "horas que quedan",
    hour_left: "hora que queda",
    vacations_days: "días de vacaciones",
  },
  content_management: {
    news: "Noticias",
    title: "Administración de contenido",
    search_by_name: "Buscar por nombre",
    show_in_carousel: "Mostrar en carousel",
    hide_in_carousel: "Ocultar en carousel",
    add_post: "Agregar post",
    create_post: "Crear post",
    edit: "Editar",
    preview: "Previsualizar",
    publish: "Publicar",
    delete: "Eliminar",
    name: "Título",
    status: "Estado",
    message_again: "No volver a mostrar este mensaje",
    cancel: "Cancelar",
    yes_publish: "si, publicar",
    last_update: "Última actualización",
    newsletter: "Newsletter",
    delete_this_post: "¿Está seguro de que desea eliminar esta publicación?",
    yes_delete: "si, eliminar",
    you_re_about_to_publish_this_post: "Estás a punto de publicar este post",
    When_publish_news:
      "Cuando publicas una noticia ya no puedes editarla. También se enviará a la bandeja de entrada de notificaciones de todos los empleados.",
    draft: "Borrador",
    make_as_draft: "Marcar como borrador",
    form: {
      title: "Escribe el título para el banner.",
      description: "Escribe una corta descripción",
      attach_file: "Adjuntar una imagen",
      link: "Link (Opcional)",
      add_to_post_carousel: "Agregar post al carousel",
      preview_post: "Previsualizar el post",
      publish: "Publicar",
    },
  },
  newsletter: {
    delete_all: "Borrar todos",
    pending_notification: "notificación pendiente",
    pending_notifications: "notificaciones pendientes",
    no_notifications: "No hay notificaciones en tu bandeja de entrada",
    notifications: "Notificaciones",
    news_published: "Noticia publicada",
    news_marked_as_draft: "Noticia marcada en borrador",
  },
  dashboard: {
    daily_activity: "Actividad diaria",
    record: "Registro",
  },
  status: {
    active: "Activo",
    archived: "Archivado",
    disable: "Deshabilitado",
    not_approved: "No aprobado",
    not_asigned: "No asignado",
    not_paid: "No pagado",
    pending: "Pendiente",
    open: "Abierto",
    approved: "Aprobado",
    deleted: "Eliminado",
    finished: "Finalizado",
    rejected: "Rechazado",
    cancelled: "Cancelado",
    canceled: "Cancelado",
    denied: "Denegado",
    returned: "Devuelto",
    absent: "Ausente",
    extraordinary: "Extraordinario",
    under_negociation: "En negociación",
    unpaid: "No pagado",
    paid: "Pagado",
    pending_accept: "Pendiente por aceptar",
    pending_cancel: "Pendiente por Cancelar",
    assigned: "Asignado",
    empty: "Vacío",
    rented: "Alquilado",
    signed: "Firmado",
    disabled: "Deshabilitado",
    solved: "Resuelto",
    loi: "LOI",
    draft: "Borrador",
    published: "Publicado",
    not_assigned: "Sin asignar",
  },
  ccm_manager: {
    title: "Gerente de MCP",
    department: {
      search_by_name: "Buscar por nombre",
      title: "Departamentos",
      creation_date: "Fecha de creación",
      new_department: "Nuevo departamento",
      rename_department: "Renombrar departamento",
      write_department_name: "Escriba el nombre del departamento",
      deactivate_department: "Desactivar departamento",
      activate_department: "Activar departamento",
      status: "Estado",
      department_name: "Nombre del departamento",
    },
  },
  absence: {
    type: "tipo de ausencia",
    days: "Días de ausencia",
    starting_date: "Fecha de inicio",
    ending_date: "Fecha de finalización",
    state: "Status",
    team_member: "Miembro del equipo",
    business_unit: "Unidad de negocio",
    departament: "Departamento",
    search_by_name: "Buscar por nombre",
    search_by_member_team: "Buscar por miembro del equipo",
    absence_type: "Tipo de ausencia",
    approval_status: "Estado de aprobación",
    select_starting_date: "Seleccionar fecha de inicio",
    select_ending_date: "Seleccionar fecha de finalización",
    absence_detail: "Detalle de ausencia",
    information: "Información",
    history: "Historia",
    comment: "Comentario",
    absence_record: "Registro de ausencias",
    days_off: "Días de descanso",
    days_of_leave: "Días de licencia",
    absence_request: "Solicitud de ausencia",
    total_absences: "Total de ausencias",
    status: "Estado",
    deny: "Denegar",
    approve: "Aprobar",
    error_date: "La fecha de inicio no puede ser mayor que la fecha de finalización"
  },
  user: {
    personal_information: "Informacion personal",
    first_name: "Nombre",
    last_name: "Apellido",
    email: "E-mail",
    phone: "Teléfono",
    business_information: "Información de la empresa",
    business_position: "Cargo",
    department: "Departamento",
    user_type: "Tipo de usuario",
    leader: "Líder",
    leadership: "Liderazgo",
    role: "Rol",
    manage_permissions: "Administrar permisos",
    select_category: "Seleccionar categoría",
    find_action_name: "Buscar nombre de acción",
    selected_actions: "Acciones seleccionadas",
    personal_informations: "Información personal",
    business_informations: "Información de la empresa",
    select_departament: "Seleccionar departamento",
    select_user_type: "Seleccionar tipo de usuario",
    select_leader: "Seleccionar líder",
    select_role: "Seleccionar rol",
    continue: "Continuar",
    back: "Atrás",
    search_actions: "Buscar acciones",
    category: "Categoría",
    categories: "Categories",
    action: "Seleccionar acción",
    model: "Seleccione el modelo",
    is_this_employee_a_leader: "¿Es este empleado un líder?",
    add_user: "Agregar usuario",
    yes: "Si",
    no: "No",
    search_by_name: "Buscar por nombre",
    user_name: "Nombre de usuario",
    user_role: "Rol de usuario",
    user_detail: "Detalle de usuario",
    user_edit: "Editar usuario",
    added_by: "Agregado por",
    date_admission: "Fecha de ingreso",
    information: "Información",
    permissions: "Permisos",
    history: "Historial",
    advanced_filters: "Filtros avanzados",
    are_want_delete: "¿Está seguro de que quiere eliminar este {model}?",
    will_be_visible_members:
      "El {model} ya no será visible para otros miembros en la plataforma",
    confirm_archive: "Sí, archivo",
    action_only: "Accion",
    search_by: "Buscar por",
    status: "Status",
    clean_filter: "Limpiar filtro",
    apply_filters: "Aplicar filtros",
    log_history: "Historial de registro",
    last_upgrade: "Ultima actualización",
    users: "Usuarios",
    last_access: "Último acceso",
    view: "Ver",
    edit: "Editar",
    archive: "Archivo",
    action_user: "Acción",
    actions: "Acciones",
    rules: "Reglas",
    accepted: "Accepted",
  },
  category: {
    name: "Nombre de la categoría",
    new_category: "Nueva categoría",
    rename_category: "Renombrar categoría",
    are_want_delete: "¿Está seguro de que quiere eliminar este {model}?",
    will_be_visible_members:
      "El {model} ya no será visible para otros miembros en la plataforma",
    category_name: "Nombre de la categoría",
    creation_date: "Fecha de creación",
    delete: "Eliminar",
  },
  actions: {
    confirm_delete: "Sí, borrar",
    edit_action: "Editar acción",
    are_want_delete: "¿Está seguro de que quiere eliminar este {model}?",
    will_be_visible_members:
      "El {model} ya no será visible para otros miembros en la plataforma",
    add_action: "Agregar acción",
    new_action: "Nueva acción",
    action_name: "Nombre de la acción",
    select_category: "Seleccionar categoría",
    category: "Categoria",
    edit: "Editar",
    read_users: "Leer usuarios",
    delete: "Eliminar",
    new_register: "Nuevo registro",
    add_register: "Añadir registro",
  },
  permissions: {
    add_permission: "Agregar permiso",
    edit: "Editar el permiso",
    information: "Información sobre permisos",
    are_want_delete: "¿Está seguro de que quiere eliminar este {model}?",
    will_be_visible_members:
      "El {model} ya no será visible para otros miembros en la plataforma",
    select_action: "Seleccione la acción",
    back: "Atrás",
    model: "Modelo",
  },
  rules: {
    required: "El campo es obligatorio|Todos los campos son obligatorios",
    url: "La URL debe empezar por http:// o https://",
    min: "El campo debe contener al menos {min} caracteres",
    max: "El campo debe contener un máximo de {max} caracteres",
    email: "Introduzca una dirección de correo electrónico válida",
    rules: "Reglas",
    add_rule: "Agregar regla",
    select_action: "Seleccione la acción",
    model: "Modelo",
    domain: "Dominio",
    back: "Atrás",
    edit: "Editar regla",
    are_want_delete: "¿Está seguro de que quiere eliminar este {model}?",
    will_be_visible_members:
      "El {model} ya no será visible para otros miembros en la plataforma",
    editr: "editar",
    delete: "eliminar",
  },
  auth: {
    enter_email: "Ingrese su correo electrónico",
    we_send_change_password:
      "Le enviaremos un correo electrónico para iniciar el proceso de cambio de contraseña",
    business_email: "Correo electrónico de la empresa",
    login: "Iniciar sesión",
    password: "Contraseña",
    forget_password: "He olvidado mi contraseña",
    enter: "Enter",
    set_new_password: "Establecer nueva contraseña",
    requiriments_new_password:
      "Su nueva contraseña debe tener 8 caracteres, 1 mayúscula y 1 símbolo",
    confirm_password: "Confirmar contraseña",
  },
  budget: {
    pending_approval: "Pending approval",
    budget_project: "Presupuesto y proyecto",
    budget_options: "Opciones de presupuesto",
    budget: "Presupuesto",
    budget_report: "Informe de presupuesto",
    invoice: "Factura",
    projects: "Proyectos",
    you_dont_have_yet_accounting_move:
      "Todavía no tienes ningún movimiento contable",
    create_first_platform_accounting_move:
      "Crea tu primer movimiento contable en la plataforma",
    budget_id: "ID de presupuesto",
    new_budget: "Nuevo presupuesto",
    edit_budget: "Editar presupuesto",
    search_by_budget_name: "Buscar por nombre de presupuesto",
    approve_budget: "Aprobar presupuesto",
    cancel_budget: "Cancelar presupuesto",
    monthly_budget: "Presupuesto mensual",
    budget_name: "Nombre del presupuesto",
    search_by_month: "Buscar por mes",
    month: "Mes",
    estimate_amount: "Cantidad estimada",
    real_amount: "Cantidad real",
    difference: "Diferencia",
    project_name: "Nombre del proyecto",
    search_by_name: "Buscar por nombre",
    invoice_date: "Fecha de la factura",
    download: "Descargar",
    will_rejected_finance_review:
      "La factura será rechazada al departamento de finanzas para su revisión",
    reject: "Rechazar",
    approve: "Aprobar",
    search_by_project_id: "Buscar por ID de proyecto",
    department: "Departamento",
    approval_status: "Estado de aprobación",
    assigned_status: "Estado asignado",
    assign_project: "Asignar proyecto",
    search_by_invoice_id: "Buscar por ID de factura",
    select_project: "Seleccionar proyecto",
    add_monthly_amount: "Agregar cantidad mensual",
    create_estimate_amount: "Crear cantidad estimada",
    new_project: "Nuevo proyecto",
    new_project_lower_case: "nuevo proyecto",
    edit_project: "Editar proyecto",
    tag_manager: "Administrador de etiquetas",
    project_options: "Opciones de proyecto",
    project_information: "Información del proyecto",
    project_description: "Descripción del proyecto",
    select_files: "Seleccionar archivos",
    starting_date: "Fecha de inicio",
    ending_date: "Fecha de finalización",
    requester_information: "Información del solicitante",
    financial_information: "Información financiera",
    estimated_amount: "Cantidad estimada",
    total_estimated_amount: "Cantidad estimada total",
    department_budget_available: "Presupuesto disponible del departamento",
    write_project_description: "Escriba una descripción del proyecto",
    attach_file: "Adjuntar archivo",
    select_year: "Seleccione el año",
    assign_tenant: "Asignar inquilino",
    there_no_records_yet: "Aún no hay registros",
    record_the_estimated_monthly_amount:
      "Registre la cantidad mensual estimada",
    tags: "Etiquetas",
    source: "Fuente",
    status: "Estado",
    file_uploaded: "Archivo subido satisfactoriamente",
    upload_file_report: "Upload file",
    budget_revenue: "Este presupuesto es de ingresos",
    select_source: "Seleccionar fuente",
    select_tenant: "Seleccionar Tenant",
    message_roject_Tenant:
      "Este proyecto es parte del presupuesto mensual del Inquilino",
    message_kpi_store_amount:
      "Este proyecto es un KPI del monto de la tienda de alquiler",
    message_kpi_storage_amount:
      "Este proyecto es un KPI de cantidad de almacenamiento",
    message_kpi_charge_amount:
      "Este proyecto es un KPI del monto del cargo por servicio",
  },
  project: {
    project_name: "Nombre del proyecto",
    project_information: "Información del proyecto",
    business_unit: "Unidad de negocio",
    department: "Departamento",
    source: "Fuente",
    starting_date: "Fecha de inicio",
    ending_date: "Fecha de finalización",
    project_description: "Descripción del proyecto",
    tags: "Etiquetas",
    files: "Archivos",
    financial_information: "Información financiera",
    amount: "Cantidad",
    department_budget: "Presupuesto del departamento",
    requester_information: "Información del solicitante",
    select_year: "Seleccionar año",
    select_supplier: "Seleccionar proovedor",
    assign_supplier: "Asignar proovedor",
  },
  reports: {
    tag_Manager: "Administrador de etiquetas",
    budget_options: "Opciones de presupuesto",
    edit_budget: "Editar presupuesto",
    are_want_cancel: " ¿Está seguro de que quiere cancelar este {model}?",
    yes_cancel: "Sí, cancelar",
    no: "No",
    once_cancelled_not_activate:
      "Una vez cancelado este {model} no podrás volver a activarlo",
    once_rejected_not_activate:
      "Una vez que este {model} sea rechazado no podrá volver a activarlo",
    reason_for_canceling: "Escriba el motivo de la cancelación del proyecto",
    yes_reject: "Sí, rechazar",
    are_want_reject: "¿Está seguro de que quiere rechazar este {model}?",
    reason_for_rejecting: "Escriba el motivo del rechazo del proyecto",
    are_want_return: "¿Estás seguro de que quieres devolver este {model}?",
    yes_return: "Sí, volver",
    once_returned_not_activate:
      "Una vez que este {model} es devuelto no podrás activarlo de nuevo",
    reason_for_returning: "Escriba el motivo de la devolución del presupuesto",
    advanced_filters: "Filtros avanzados",
    department: "Departamento",
    business_unit: "Unidad de negocio",
    status: "Estado",
    tags: "Etiquetas",
    select_starting_date: "Seleccione la fecha de inicio",
    select_ending_date: "Seleccione la fecha de finalización",
    clean_filter: "Limpiar filtro",
    apply_filters: "Aplicar filtros",
    reason_for_canceling_project:
      "Escriba el motivo de la cancelación del proyecto",
    reason_for_rejecting_project: "Escriba el motivo del rechazo del proyecto",
    budget_name: "Nombre del presupuesto",
    select_business_unit: "Seleccione la unidad de negocio",
    amount: "Cantidad",
    description: "Descripción",
    budget_history: "Historial de presupuestos",
    budgets: "Presupuestos",
    budget_detail: "Detalle del presupuesto",
    overall_budget: "Presupuesto general",
    budget_information: "Información del presupuesto",
    starting_date: "Fecha de inicio",
    ending_date: "Fecha de finalización",
    budget_description: "Descripción del presupuesto",
    there_are_no_tags: "No hay etiquetas",
    real_amount_vs_estimated: "Importe real frente a la estimación",
  },
  buttons: {
    advanced_filters: "Filtros avanzados",
    clean_filter: "Limpiar filtro",
    apply_filters: "Aplicar filtros",
    change_user_status: "Cambiar estado del usuario",
    are_want_change_status:
      "¿Estás seguro de que quieres cambiar el estado del usuario?",
    yes: "Sí",
    no: "No",
  },
  departments: {
    select_department: "Seleccione el departamento",
  },
  dialog: {
    yes_cancel: "Si, cancelar",
    are_want_cancel: "¿Estás seguro de que quieres cancelar esto {model}?",
    once_cancelled_not_activate:
      "Una vez que se cancele este {model}, no podrá volver a activarlo",
    reason_for_canceling: "Escribe el motivo de la cancelación {model}",
    are_want_reject: "¿Estás seguro de que quieres rechazar esto {model}?",
    once_rejected_not_activate:
      "Una vez que se rechace este {model}, no podrá volver a activarlo",
    reason_for_rejected: "Escriba la razón por la cual rechazó {model}",
    yes_return: "Si, volver",
    are_want_return: "¿Estás seguro de que quieres devolver esto {model}?",
    once_returned_not_activate:
      "Una vez que se devuelva este {model}, no podrá volver a activarlo",
    reason_for_returning: "Escribe el motivo de la devolución {model}",
    folder_name: "Nombre de la carpeta",
    folder_description: "Descripción de la carpeta (Opcional)",
    share_item: "Compartido {0}",
    select_business_unit: "Seleccionar unidad de negocio",
    select_departments: "Seleccionar departamentos",
    search_by_user_name: "Buscar por nombre de usuario",
    move_file_to: "Mover archivo a",
    select_folder: "Seleccione la carpeta",
    move: "mover",
    uploading_files: "Subiendo 1 archivo | Cargando {count} archivos",
    yes_solve: "Sí, resolver",
    are_want_solve: "¿Estás seguro de resolver este {model}?",
    once_resolve_not_activate:
      "Una vez que se resuelva este {model}, no podrá volver a activarlo",
    reason_for_resolving: "Escriba una razón para resolver este {model}",
  },
  files: {
    files: "Archivos",
    file_information: "Informacion del archivo",
    file_name: "Nombre del archivo",
    owner: "Dueño",
    last_modified: "Última modificación",
    last_access: "Ultimo acceso",
    by: "Por",
    created_on: "Creado en",
    folder_information: "Información de la carpeta",
    shared_with: "Compartido con",
    not_shared: "No compartido",
    manage_access: "Administrar acceso",
    folder_name: "Nombre de la carpeta",
    description: "Descripción",
    business_unit: "Unidad de negocio",
    department: "Departamento",
    advanced_filters: "Filtros avanzados",
    search_by_user_name: "Buscar por nombre de usuario",
    apply: "aplicar",
    my_files: "Mis archivos",
    upload_file: "Subir archivo",
    new_folder: "Nueva carpeta",
    new: "Nuevo",
    you_dont_have_uploaded_files_yet: "Aún no has subido archivos",
    name: "Nombre",
    are_you_sure_you_want_to_delete_this:
      "¿Estás seguro de que quieres eliminar {0}",
    all_assigned_files_will_be_deleted_automatically:
      "Todos los archivos asignados se eliminarán automáticamente",
    shared_files: "Archivos compartidos",
    accept_terms: "Aceptar términos",
    accepted_terms: "He leído y acepto los términos y condiciones del contrato",
    mandatory_files: "Archivos obligatorios",
    users_agree: "Usuarios de acuerdo",
    approval_status: "Estatus de aprobación",
    agreed_users: "Usuarios de acuerdo",
    pending_users: "Usuarios pendientes"
  },
  finances: {
    assign_to_project: "Asignar al proyecto",
    select_project: "Seleccionar proyecto",
    expense_detail: "detalle de gastos",
    business_unit: "Unidad de negocio",
    department: "Departamento",
    company: "Compañía",
    concept: "Concepto",
    cost_center: "centro de costo",
    taxbase: "Base impuesto",
    invoice_name: "Nombre de la factura",
  },
  tax_base: {
    department: "Departamento",
    assign_to_project: "Asignar al proyecto",
    select_project: "Seleccionar proyecto",
    expenses: "Gastos",
    invoice_detail: "Detalle de la factura",
    detail: "Detalle",
    accounting_notes: "Notas contables",
    concept: "Concepto",
    project: "Proyecto",
    cost_center: "centro de costo",
    bussines_unit: "Unidad de negocio",
    invoice_type: "Tipo de factura",
    company: "Compañia",
    tax_base: "Base impuesto",
    search_by_concept: "Buscar por concepto",
    date: "Fecha",
    status: "Estado",
    amount: "Cantidad",
    payment_method: "Método de pago",
    invoice_number: "Número de factura",
    invoice_date: "Fecha de la factura",
    vat_id: "VAT ID",
    country: "País",
    tax: "Impuesto",
    pending_amount: "Monto pendiente",
    total: "Total",
    invoice_file: "Archivo de factura",
    company_information: "Información de la empresa",
    invoice_information: "Información de la factura",
  },
  navigation: {
    hi: "Hola",
    my_profile: "Mi perfil",
    settings: "Ajustes",
    log_out: "Cerrar sesión",
  },
  teams: {
    teams: "Equipos",
    month_report: "Informe mensual {month}",
    absences: "Ausencias",
    employee_detail: "Detalle del empleado",
    search_by_day: "Buscar por día",
    working_day: "Días laborables",
    working_days: "Días laborables",
    activity: "Actividad",
    days: "Días",
    no_employee_activity_log: "No hay registro de actividad de los empleados",
    time_report: "Informe de tiempo",
    employee_status: "Estado del Empleado",
    pending_request: "Solicitud pendiente",
    view_all: "Ver todo",
    search_by_user_name: "Buscar por nombre de usuario",
    great: "Excelente",
    no_pending_requests: "Sin solicitudes pendientes",
    absences_record: "Registro de ausencias",
  },
  tenant: {
    edit_tenant_data: "Editar datos de inquilinos",
    app_name: "Inquilinos",
    tenant_list: "Lista de inquilinos",
    area_local: "Superficie de tienda sqm",
    company: "Compañía",
    category: "Categoría",
    floor: "piso",
    advanced_filters: "Filtros avanzados",
    local_name: "Nombre local",
    search_by_local: "Buscar por nombre local",
    search_by_company: "Buscar por nombre de empresa",
    search_by_brand_name: "Buscar por nombre de marca",
    status: "Estado",
    tentant_detail: "Detalle del inquilino",
    tentant_id: "Identificación",
    warehouse: "Almacén",
    warehouse_area: "Área de depósito sqm",
    place_information: "Detalle",
    invoice: "Factura",
    invoicing_terms: "Términos de facturación",
    alerts: "Alertas",
    crm: "CRM",
    attachments_files: "Adjuntos y archivos",
    monthly_budget: "Monthly budget",
    history: "Historia",
    search_by_alert: "Buscar por alerta",
    alert_created_by: "Alerta creada por",
    notified_date: "Fecha de notificación",
    new_alert: "Nueva alerta",
    create_alert: "Crear alerta",
    search_by_name: "Buscar por nombre",
    responsible_usuario: "Usuario responsable",
    area_mi: "Mínimo",
    area_max: "Máximo.",
    user: "Usuario",
    action: "Acción",
    date: "Fecha",
    search_by_file: "Buscar por archivo",
    file_size: "Tamaño del archivo",
    message_no_files: "No se cargaron archivos",
    view_tenant: "Ver inquilino",
    complete_information: "Información completa",
    edit_tenant: "Editar inquilino",
    assign_store: "Asignar tienda",
    store_information: "Almacenar información",
    tenant_information: "Información del inquilino",
    company_information: "Información de la empresa",
    nif: "NIF",
    address: "Dirección fiscal",
    comercial_address: "Dirección comercial",
    contact_information: "Información de contacto",
    contact_name: "Nombre de contacto",
    financial_information: "Información financiera",
    aval: "Aval",
    additional_warranty: "Garantía adicional",
    return_good: "Devolver el buen desempeño de las obras",
    good_performance: "Buen desempeño de las Obras",
    IBI_refacturable: "IBI refacturable",
    IBI: "IBI",
    owners_contribution: "Contribución de los propietarios",
    warehouse_amount: "Cantidad de almacén",
    service_charge_amount: "Importe del cargo por servicio",
    fixed_rent_amount: "Cantidad de Renta Mínima Garantizada",
    equities: "acciones",
    ibi_amount: "Importe del IBI",
    guaranteed_minimum_incom: "Ren mínima garantizada",
    fixed_Rent: "Renta Mínima Garantizada",
    observation: "Observación",
    rent_amount: "Cantidad del alquiler",
    contract_signing_date: "Fecha de firma del contrato",
    contract_starting_date: "Fecha de inicio",
    ending_date: "Fecha de finalización",
    hiring_period: "Período de contratación",
    contract_duration: "Duración del contrato",
    equity_observations: "Observaciones de las acciones",
    information: "Información",
    no: "No",
    yes: "si",
    opening_date: "Fecha de apertura",
    contract_ending_date: "Fecha de finalización del contrato",
    clean_filter: "Limpiar filtros",
    apply_filters: "Aplicar filtros",
    alert_information: "Información de alerta",
    alert_title: "Título de la alerta",
    alert_description: "Descripción de la alerta",
    copy_link: "Copiar un enlace",
    period: "Período",
    select_date: "Seleccionar fecha",
    write_number: "Escribir número",
    user_responsible_aler: "Usuario responsable de la alerta",
    search_by: "Buscar por",
    yes_create: "sí, crear",
    going_create_alert: "Vas a crear una alerta",
    show_message: "Este mensaje será mostrado a {fullName}",
    edit_alert: "Editar alerta",
    attach_file: "Adjuntar un archivo",
    select_period: "Seleccionar periodo",
    are_want_delete_file: "¿Está seguro de que desea eliminar este archivo?",
    upload_brand_imag: "Subir imagen de marca",
    company_name: "Nombre de la empresa",
    social_reason: "Razón social",
    Email: "Correo electrónico",
    area_code: "Código de área",
    business_position: "Puesto de negocio",
    local: "Locales",
    select_local: "Seleccionar tienda",
    starting_date: "Fecha de inicio",
    clean_filters: "Limpiar filtros",
    movements: "Movimientos",
    month: "Mes",
    estimated_amount: "Cantidad estimada",
    real_amount: "Cantidad real",
    difference: "Diferencia",
    search_by_concept: "Buscar por concepto",
    stores: "Tiendas",
    kpi: "KPI's",
    financial: "Finanzas",
    contract: "Contrato",
    contract_information: "Information Contracto",
    contact: "Contacto",
    rent_store: "Monto de la renta",
    storage_rent: "Renta almacén",
    rent_per_mt: "Renta por metro cuadrado del almacén",
    rent_per_mt_store: "Renta por metro cuadrado de la tienda",
    service_charge: "Recargo por servicios",
    net_sales: "Ventas netas",
    worker_information: "Información del trabajador",
    change_store_status: "Cambiar estatus de la tienda",
    difference_percentage: "Diferencia %",
    search_by_type_of_event: "Buscar por tipo de evento",
    worker_assigned: "Trabajador asignado",
    event: "Evento",
    department: "Departamento",
    contact_person: "Persona de contacto",
    creation_date: "Fecha de creación",
    last_update: "Última actualización",
    new_event: "Nuevo evento",
    event_detail: "Detalle del evento",
    edit: "Editar",
    delete: "Borrar",
    create_event: "Crear evento",
    event_information: "Información del evento",
    select_event_date: "Seleccionar fecha del evento",
    write_event_type: "Escribir tipo de evento",
    select_worker: "Seleccionar trabajadora asignada",
    event_description: "Descripción del evento (Opcional)",
    contact_person_information: "Información de la persona de contacto",
    full_name: "Nombre completo",
    contact_email: "Email de contacto",
    phone_number: "Número de teléfono",
    title_dialog_close:
      "¿Estás seguro de que estás a punto de cerrar el contrato?",
    content_dialog_close:
      "Cuando se cierra el contrato, la información del arrendatario se guarda y la tienda asignada quedará vacía.",
    delete_dialog_close: "Si, cerrar contrato",
    view_alert_detail: "Detalle de la alerta",
    alert_history: "Historial",
    alert_file: "Archivo",
    alert_link: "Links",
    alert_calendar: "Creado en",
    are_want_delete_event: "¿Está seguro de que desea eliminar este evento?",
    tenant_net_sales_per_sqm: "Ventas netas del arrendatario por sqm",
    monthly_sale: "Ventas mensual",
    add_monthly_amount: "Agregar cantidad mensual",
    monthly_sale_history: "Historial de ventas mensual",
    tenants: "Arrendatarios",
    created_at: "Fecha de creación",
    month_detail: "Detalle del mes",
    monthly_sale_amount: "Cantidad de ventas mensual",
    difference_item: "Diferencia %",
    file: "Archivo",
    save: "Guardar",
    select_a_month_and_year: "Seleccionar un mes y año",
    write_real_amount: "Escribir cantidad real",
    attach_a_file_Optional: "Adjuntar un archivo (Opcional)",
    select_contract: "Seleccionar contracto",
    requester_information: "Información del solicitante",
    reason: "Razón",
    alert_name: "Nombre de la alerta",
    programmed_by: "Programado por",
    event_type: "Tipo de evento",
    departament: "Departamento",
    attached_files: "Archivos adjuntos",
    name: "Nombre",
    select_store: "Seleccionar tienda",
    edit_monthly_amount: "Editar cantidad mensual",
    store_detail: "detalles de la tienda",
    add_contact_details: "Agregar detalles de contacto",
    store_assigned_to_contract: "Almacenes asignados al contrato",
    tenant_options: "Opciones de arrendatario",
    assign_ledger_account: "Asignar cuenta contable",
    ledger_accounts_assigned: "Cuentas contables asignadas",
    commercial_adress: "Dirección comercial"
  },
  detail: {
    upload_brand_image: "Subir imagen de marca",
    brand_name: "Nombre de la marca",
    name_contact: "Nombre de contacto",
    select_category: "Selecciona una categoría",
    company: "Compañía",
    nif: "NIF",
    fiscal_address: "Dirección física",
    email: "Email",
    phone_number: "Número de teléfono",
    search_contact: "Buscar por contacto",
    business_position: "Posición empresarial",
    contact_email: "Email de contacto",
    area_code: "Codigo de AREA",
    aval: "Aval",
    additional_warranty: "Garantía adicional",
    good_performance: "Buen desempeño de trabajo",
    select_ibi: "Seleccionar IBI",
    owners_contribution: "Contribución de los propietarios",
    storage_amount: "Cantidad de almacenamiento",
    service_charge_amount: "Importe del cargo por servicio",
    fixed_rent_amount: "Renta Mínima Garantizada",
    equities: "Renta variable",
    ibi_amount: "Importe del IBI",
    guarenteed_minimum_income: "Renta mínima garantizada",
    comments: "Comentarios",
    rent_amount: "Precio del alquiler",
    contract_signing_date: "Fecha de firma del contrato",
    opening_date: "Fecha de apertura",
    starting_date: "Fecha de inicio",
    ending_date: "Fecha de finalización",
    phone: "Teléfono",
    delivery_date_of_store: "Entrega de locales",
  },
  invoicing: {
    add_terms: "Agregar términos",
    add_terms_uppercase: "AGREGAR TÉRMINOS",
    select_rent_start_date: "Seleccione la fecha de inicio del alquiler",
    select_rent_update: "Seleccionar actualización de alquiler",
    choose_date: "Elige una fecha",
    bail_amount: "Monto de la fianza",
    invoice_amount: "Monto de la factura",
    attach_file: "Adjuntar un archivo",
    terms_description: "Descripción de los términos",
    contract_information: "Información del contrato",
    description_terms: "Descripción términos",
    files: "Archivos",
    rent_update: "Actualización de alquiler",
    annual_billing_forecast: "Previsión de facturación anual",
    next_invoice: "Próxima factura",
  },
  store: {
    floor_information: "Información del piso",
    floor: "Piso 1",
    local_information: "Información del local",
    technical_information: "Información técnica",
    electric_meter_number: "Número de metros eléctricos",
    area_local: "Área del local",
    warehouse: "Almacén",
    area: "Área",
    water_meter_number: "Metros de tubería de agua",
    climate_ring_counter_number: "Controlador de clina",
    rated_ring_flow: "Rated ring flow",
    assigned_electrical_power: "Potencia eléctrica asignada",
    add_store: "Añadir tienda",
    search_store_name: "Buscar por nombre de tienda",
    advanced_filters: "Filtros avanzados",
    edit_store: "Editar tienda",
    store_detail: "Detalle de la tienda",
    information: "Información",
    available: "Disponible",
    not_available: "No disponible",
    upload_plane_image: "Subir imagen de plano (Opcional)",
    write_electric_meter_number: "Escriba el número del medidor eléctrico",
    write_water_meter_number: "Escriba el número del medidor de agua",
    assigned_flow_climate_ring: "Anillo climático de flujo asignado",
    upload_location_image: "Subir imagen de ubicación",
    local_name: "Nombre local",
    floor_title: "Piso",
    clean_filters: "Limpiar filtros",
    apply_filters: "Aplicar filtros",
    select_store: "Seleccionar tienda",
    status: "Estado",
    select_local: "Seleccionar tiendas",
    accept: "Aceptar",
    ok: "Ok",
    assigne_store: "asignar la tienda",
    are_assigne_store:
      "Está a punto de asignar la tienda {store_name} con el {company_name}",
    store_information: "Store information",
    change_store_status: "Change store status",
    edit_local: "Edit local",
    assign_ledger_account: "Assign ledger account",
    register_company:
      "Registrar la(s) marca(s) de la(s) empresa(s) que están en negociación",
    status_change: "cambio de estado",
    brand_name: "Nombre de la marca",
    change_to: "Cambiar a",
    add_brand: "Añadir marca",
    consumption_history: "Histórico consumido",
    electrical_consumption: "Electricidad consumida",
    water_consumption: "Agua consumida",
    climate_ring_consumption: "Anillo climático consumido",
    camera_ring_consumption: "Anillo de la cámara consumido",
    assign_flow_chamber_ring: "Assigned flow chamber ring",
    assign_hood_flow: "Assigned hood flow",
    write_counter_ring_cameras: "Write counter ring cameras",
    total_sqm_store: "Total sqm de la Tienda",
    total_sqm_warehouse: "Total sqm del depósito",
    select_definitive_tenant: "Selecciona el inquilino definitivo",
    write_the_brand_name_from_the_definitive_tenant:
      "Escriba la marca del inquilino definitivo",
    status_was_changed: "El estatus fue cambiado",
    delete: "Eliminar",
  },
  time_tracking: {
    create_absence: "Crear ausencia",
    select_absence_type: "Seleccionar tipo de ausencia",
    days_of_leave: "Dias de licencia",
    vacations: "vacaciones",
    starting_date: "Fecha de inicio",
    ending_date: "Fecha de finalización",
    comment: "Comentario",
    optional: "Opcional",
    working_timer: "Temporizador de trabajo",
    select_working_mode: "Seleccionar modo de trabajo",
    absence_created: "Absence created",
    accept: "aceptar",
    stop: "deténgase",
    start_time: "Hora de inicio",
  },
  docs: {
    new_action: "Nueva acción",
    action_name: "Nombre de la acción",
    select_departament: "Seleccione un departamento",
    are_want_delete_action: "¿Está seguro de que desea eliminar esta acción?",
    action_will_no_visible:
      "La acción ya no será visible para otros miembros en la plataforma.",
    hi: "Hola",
    role: "Role",
  },
  graphics: {
    overall_budget: "Presupuesto general",
    budget: "Presupuesto",
    budget_source: "Presupuesto por fuente",
    budget_business_unit: "Presupuesto por unidad de negocio",
    department_budget: "Presupuesto del Departamento",
    total_expenses: "Gastos totales",
  },
  schedule: {
    schedule_name: "Calendario",
    absences_record: "Registro de ausencias",
    create_absence: "Crear ausencia",
    time_report: "Informe de tiempo",
    activity: "Actividad",
    not_assigned: "No asignado",
    hours_left: "queda 1 hora | {count} horas restantes",
    days_left: "falta 1 día | {count} Días restantes",
    last_year: "El año pasado",
    daily_work: "Trabajo diario",
    daily_activity: "Actividad diaria",
    days_claimed: "Días reclamados",
    next_vacation_day: "Próximo día de vacaciones",
    working_days: "Días laborables",
    absences: "Ausencias",
    general: "General",
    pending: "Pendiente",
  },
  views: {
    password_has_confirmed_successfully:
      "Su contraseña ha sido confirmada con éxito",
    password_reset: "Restablecimiento de contraseña",
    login: "Acceso",
    token_has_expired: "The token has expired",
    try_reset_password_again:
      "Intente restablecer la contraseña nuevamente en unos minutos",
  },
  empty: "Vacío",
  rented: "Rentado",
  signed: "Firmado",
  not_assigned: "Sin asignar",
  hours_left: "1 hora restante | {count} horas restantes",
  days_left: "1 día restante | {count} días restantes",
  last_year: "Último año",
  daily_work: "Trabajo diario",
  daily_activity: "Actividad diaria",
  days_claimed: "Días reclamados",
  next_vacation_day: "Siguiente día de vacaciones",
  shared_files: "Archivos compartidos",
  my_files: "Mis archivos",
  upload_file: "Cargar archivo",
  new_folder: "Nueva carpeta",
  new: "Nuevo",
  you_dont_have_uploaded_files_yet: "Aún no tienes archivos cargados",
  name: "Nombre",
  shared_with: "Compartido con",
  last_modified: "Última modificación",
  Assigned: "Asignado",
  Extraordinary: "Extraordinario",
  Unpaid: "Sin pagar",
  Paid: "Pagado",
  "Not approved": "No aprobado",
  "Not asigned": "No asignado",
  team_manager: "Administrador de equipo",
  uploading_files: "Cargando 1 archivo | Cargando {count} archivos",
  private: "Privado",
  folder_name: "Nombre de archivo",
  folder_description: "Descripción de carpeta (Opcional)",
  new_folder_created: "Nueva carpeta creada",
  details: "Detalles",
  share: "Compartir",
  edit_name: "Editar nombre",
  download: "Descargar",
  delete: "Eliminar",
  move_to: "Mover a",
  Open: "Abrir",
  "Opening date": "Fecha de apertura",
  you_must_move_this_file:
    "Debes mover este archivo \na una carpeta para compartirlo",
  "Not available": "No disponible",
  folder_information: "Información de carpeta",
  not_shared: "No compartido",
  manage_access: "Administrar acceso",
  description: "Descripción",
  owner: "Dueño",
  last_access: "Último acceso",
  by: "Por",
  created_on: "Creado en",
  share_item: "Compartir {0}",
  done: "Hecho",
  select_business_unit: "Seleccione unidad de negocio",
  select_departments: "Seleccione departamentos",
  edited_folder: "Carpeta editada",
  edit_folder: "Editar carpeta",
  save: "Guardar",
  are_you_sure_you_want_to_delete_this: "Seguro que desea eliminar este {0}",
  all_assigned_files_will_be_deleted_automatically:
    "Todos los archivos asignados se eliminarán automáticamente",
  folder_deleted: "Carpeta eliminada",
  file_deleted: "Archivo eliminado",
  file_information: "Información del archivo",
  file_name: "Nombre del archivo",
  move_file_to: "Mover archivo a",
  select_folder: "Seleccionar carpeta",
  move: "Mover",
  moved_file: "Archivo movido",
  apply: "Aplicar",
  created_date: "Fecha de creación",
  business_unit: "Unidad de negocio",
  department: "Departamento",
  shared_by: "Compartido por",
  shared: "Compartido",
  folder_options: "Opciones de carpeta",
  upload_files: "Subir archivos",
  supplier: "Proveedor",
  general: {
    floor: "Piso",
    add_new_tag: "Agregar una nueva etiqueta",
    tag_lis: "Lista de etiquetas",
    add_tags: "Agregar etiquetas",
    create_new_tag: "Crear nueva etiqueta",
    are_you_want_reject: "¿Estás seguro de que quieres rechazar {model}?",
    once_this_rejected_will_activate:
      "Una vez que se rechace este {model}, no podrá volver a activarlo",
    write_reason_rejecting: "Escriba la razón por la cual rechazó {model}",
    business_unit: "Unidad de negocio",
    create_first_platform: "Crea tu primer {model} en la plataforma",
    we_cannot_find_any_results_parameter:
      "No podemos encontrar ningún resultado con este parámetro. Borre la búsqueda y vuelva a intentarlo.",
    clear_search: "Borrar búsqueda",
    error_404: "Error 404",
    page_not_found: "Página no encontrada",
    private: "privada",
    yes: "Si",
    no: "No",
    welcome: "Bienvenidos",
    start_explore: "Empezar a explorar",
    canalejas_SGI: "A Canalejas SGI",
    you_dont_have_yet: " Aún no tienes ningún {model}",
  },
  months: {
    jan: "Ene",
    feb: "Feb",
    mar: "Mar",
    apr: "Abr",
    may: "May",
    jun: "Jun",
    jul: "Jul",
    aug: "Ago",
    sep: "Sep",
    oct: "Oct",
    nov: "Nov",
    dec: "Dic",
  },
  suppliers: {
    suppliers: "Proveedores",
    options: "Opciones de proveedor",
    search_by_supplier_name: "Buscar por nombre del proveedor",
    edit_information: "Editar información",
    archive: "Archivar",
    enable_supplier: "Activar proveedor",
    disable_supplier: "Desactivar proveedor",
    fiscal_address: "Dirección fiscal",
    comercial_address: "Dirección comercial",
    complete_information: "Completar información",
    business_information: "Información de negocios",
    contact_information: "Información de contacto",
    financial_information: "Información financiera",
    payment_method: "Método de pago",
    id_type: "Tipo de ID",
    bank: "Banco",
    account_holder: "Titular de la cuenta",
    id_number: "Número de identificación",
    account_type: "Tipo de cuenta",
    account_number: "Número de cuenta",
    files: "Archivos",
    last_modified: "Última modificación",
    search_by_file: "Buscar por archivo",
    view_details: "Ver detalles",
    file_information: "Información del archivo",
    file_name: "Nombre del archivo",
    owner: "Dueño",
    last_access: "Último acceso",
    created_on: "Creado en",
    fiscal_business_name: "Nombre fiscal",
    edit_supplier: "Editar proveedor",
    contact_full_name: "Nombre completo del contacto",
    contact_business_position: "Cargo del contacto",
  },
  ledger_account: {
    find_ledger_account_by_number: "Buscar cuenta contable por número",
    select_ledger_account_by_name: "Seleccionar cuenta contable por nombre",
    select_cost_center: "Seleccionar centro de costos",
    assign_to_ledger_accounts: "Asignar a cuentas contables",
    edit_ledger_accounts: "Editar cuentas contables",
  },
  Archived: "Archivado",
  Available: "Disponible",
  "Last access": "Último acceso",
  "Password changed": "Contraseña cambiada",
  "Password not changed": "Contraseña no cambiada",
  "Please, check your mail and try it again":
    "Por favor, compruebe su correo y vuelva a intentarlo",
  "There’s something wrong": "Hay algo que está mal",
  "There is already an accepted budget, with the same department and in the same year":
    "Ya hay un presupuesto aceptado, con el mismo departamento y en el mismo año",
  "Your password hasn’t change. Please try it again in a few minutes":
    "Su contraseña no ha cambiado. Por favor, inténtelo de nuevo en unos minutos",
  "Your role has to be director to accept a budget":
    "Su rol tiene que ser director para aceptar un presupuesto",
  "The {} have been created successfully": "El {model} se ha creado con éxito",
  "The {} have been updated successfully":
    "El {model} se ha actualizado con éxito",
  "The {} have been removed successfully":
    "El {model} se ha eliminado con éxito",
  "The {} have been approved successfully":
    "El {model} se ha aprobado con éxito",
  "Are you sure you want to archive this {}?":
    "¿Estás seguro de que quieres archivar este {model}?",
  "No pdf available": "No hay pdf disponible",
  "You do not have permissions to access this section":
    "No tiene permisos para acceder a esta sección",
  Welcome: "Bienvenido",
  "food hall": "food hall",
  "Search by project ID": "Buscar por ID de proyecto",
  no_activity_logs: "No hay registros de actividad",
  working_timer: "Temporizador de trabajo",
  select_working_mode: "Seleccionar modo de trabajo",
  accept: "Aceptar",
  stop: "Detener",
  "There's no records yet": "No hay registros todavía",
  "The invoice has been approved successfully":
    "La factura se ha aprobado con éxito",
  "The invoice has been set as extraordinary":
    "La factura se ha establecido como extraordinaria",
  "Create estimate amount": "Crear cantidad estimada",
  "Estimated amount": "Cantidad estimada",
  "Total estimated amount": "Cantidad estimada total",
  activity: "Actividad",
  create_absence: "Crear ausencia",
  "not approved": "no aprobado",
  "credit card": "Tarjeta de Crédito",
  "debit card": "Tarjeta de Débito",
  "bank transfer": "Transferencia Bancaria",
  CIF: "CIF",
  "DNI/NIE": "DNI/NIE",
  PASSPORT: "PASAPORTE",
  "Yes, delete": "Si, borrar",
  "content management": "administración de contenido",
  create: "Crear",
  Dashboard: "Dashboard",
  Incidents: "Incidentes",
  "Users manager": "Administrador de usuarios",
  Store_deleted: "Tienda eliminada",
};

<template>
  <wrapper-layout>
    <v-row no-gutters class="v-100">
      <v-col cols="12" md="2">
        <main-navigation-drawer />
      </v-col>
      <v-col md="10">
        <section class="py-8 px-9">
          <slot></slot>
        </section>
      </v-col>
    </v-row>
  </wrapper-layout>
</template>

<script>
import MainNavigationDrawer from "@/components/navigation/MainNavigationDrawer";
import WrapperLayout from "../WrapperLayout.vue";
export default {
  components: {
    WrapperLayout,
    MainNavigationDrawer,
  },
};
</script>

<style lang="scss" scoped></style>

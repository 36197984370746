import Vue from "vue";
import Vuex from "vuex";
import { store } from "core-js/internals/reflect-metadata";
import states from "@/store/modules/states";
import users from "@/store/modules/users";
import actions from "@/store/modules/actions";
import auth from "@/store/modules/auth";
import rules from "@/store/modules/rules";
import permissions from "@/store/modules/permissions";
import budget from "@/store/modules/budget";
import generic from "@/store/modules/generic";
import project from "@/store/modules/project";
import invoice from "@/store/modules/invoice";
import accountingentry from "@/store/modules/accountingentry";
import timeTracker from "@/store/modules/time-tracker";
import tenant from "@/store/modules/tenant";
import alert from "@/store/modules/alert";
import contract from "@/store/modules/contract";
import storeEntity from "@/store/modules/store";
import finance from "@/store/modules/finance";
import monthlysale from "@/store/modules/monthlysale";
import navigationOptions from "@/store/modules/navigation-options";
import news from "@/store/modules/news";
import absence from "@/store/modules/absence";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    states,
    users,
    actions,
    auth,
    rules,
    permissions,
    budget,
    project,
    generic,
    invoice,
    timeTracker,
    accountingentry,
    tenant,
    contract,
    alert,
    finance,
    store: storeEntity,
    navigationOptions,
    monthlysale,
    news,
    absence,
  },
});

if (module.hot) {
  module.hot.accept(
    ["./modules/states", "./modules/auth", "./modules/permissions"],
    () => {
      const states = require("./modules/states").default;
      const auth = require("./modules/auth").default;
      const permissions = require("./modules/permissions").default;
      store.hotUpdate({
        modules: {
          states: states,
          auth: auth,
          permissions: permissions,
        },
      });
    }
  );
}

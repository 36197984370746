import genericModels from "@/services/genericModels";
import { MODEL, STATUS } from "@/utils/constants";

export const actions = {
  /**
   * @param context : ActionContext<Store>
   * @param {} budget
   */
  set_budget_selected: (context, budget) => {
    context.commit("setBudgetSelected", budget);
  },
  /**
   * @param context : ActionContext<Store>
   * @param {} boolean
   */
  set_budget_wrapper: (context, budgetWrapper) => {
    context.commit("setBudgetWrapper", budgetWrapper);
  },
  set_budget_current_tab: (context, currentTab) => {
    context.commit("setBudgetCurrentTab", currentTab);
  },
  /**
   * @param context : ActionContext<Store>
   * @param amount
   */
  set_budget_notification_amount: (context, amount = null) => {
    if (amount === null) {
      genericModels
        .get(
          "budget",
          {
            filters: {
              filters: [
                {
                  field: "active",
                  op: "==",
                  value: true,
                },
                {
                  field: "status",
                  op: "==",
                  value: STATUS.PENDING,
                },
              ],
            },
          },
          true
        )
        .then((data) => {
          genericModels.cleanParamsOrFilters("budget", "filters");
          context.commit("setBudgetNotificationAmount", data.data.total);
        });
    } else {
      context.commit("setBudgetNotificationAmount", amount);
    }
  },
  get_budgets: (context, showPending = false) => {
    genericModels.get("budget", {
      filters: {
        filters: showPending
          ? [
              { field: "active", op: "==", value: true },
              { field: "status", op: "==", value: "pending" },
            ]
          : [{ field: "active", op: "==", value: true }],
        sort_by: [{ field: "updated_at", desc: true }],
        show_inactives: false,
      },
    });
  },
  get_budgets_pending: (context) => {
    genericModels
      .get(
        "budget",
        {
          filters: {
            filters: [
              {
                field: "active",
                op: "==",
                value: true,
              },
              {
                field: "status",
                op: "==",
                value: "pending",
              },
            ],
          },
        },
        true
      )
      .then((data) => {
        if (data.success) {
          context.commit("setBudgetNotificationAmount", data.data.total);
        }
      });
  },
  get_monthly_budget: async (context, budgetId) => {
    const budgetString = MODEL.BUDGET;
    const [response] = await Promise.all([
      genericModels.postOperation(
        budgetString,
        "detail",
        [budgetId],
        true,
        {},
        "",
        false
      ),
      genericModels.read(budgetString, budgetId),
    ]);
    // Map data to read it better for the tables.
    // The idea is to get an array of objects of type [{id number, month: string, estimate_amount: number, real_amount: number,difference: number }]
    let monthlyBudget = [];
    let count = 0;
    for (let month in response.data[0]) {
      count++;
      monthlyBudget.push({
        id: count,
        month: month,
        estimate_amount: response.data[0][month].estimated,
        total: response.data[0][month].total,
        difference: response.data[0][month].difference,
      });
    }
    // Sum each data of estimate_amount, real_amount, difference
    const totalAmounts = monthlyBudget.reduce(
      (acumulador, actual) => {
        return {
          total_amount: "Total amount",
          estimate_amount: acumulador.estimate_amount + actual.estimate_amount,
          total: acumulador.total + actual.total,
          difference: acumulador.difference + actual.difference,
        };
      },
      { estimate_amount: 0, total: 0, difference: 0 }
    );
    monthlyBudget.push(totalAmounts);
    context.commit("setBudgetMonthlyData", monthlyBudget);
  },
  get_monthly_budget_projects: (context, data) => {
    genericModels
      .postOperation(
        "budget",
        "detail",
        [data.budgetId],
        true,
        {
          month: data.month,
        },
        "Monthly budget loaded successfully"
      )
      .then(async (response) => {
        let monthlyBudgetProjects = [];
        for (let project in response.data[0]) {
          monthlyBudgetProjects.push({
            project_name: project,
            estimate_amount: response.data[0][project].estimated,
            total: response.data[0][project].total,
            difference: response.data[0][project].difference,
          });
        }

        context.commit("setBudgetMonthlyProjectsData", monthlyBudgetProjects);
      });
  },
};

const role = ["administrator", "finance"];
const layout = "admin";
import authMiddleware from "@/middlewares/auth";
import roleMiddleware from "@/middlewares/role";

const metaCCMManager = {
  role,
  layout,
  middleware: [authMiddleware, roleMiddleware],
};

const ccm_manager = [
  {
    path: "/ccm/dashboard",
    name: "ccm_manager.dashboard",
    component: () => import("@/views/ccm-manager/DashboardView.vue"),
    meta: metaCCMManager,
  },
];

export default ccm_manager;

import Vue from "vue";
import {
  datePrimary,
  dateHour,
  DATE_DD_MM_YYYY,
  DATE_MMM_DD_YYYY,
  DATE_hh_mm,
} from "./dates";
import {
  squareMeters,
  abbr,
  megaByte,
  fixDecimals,
  percentageDecimals,
} from "./numbers";
import { replaceUnderscore, capitalize } from "./texts";
import { labelFromIdObject } from "./objects";

Vue.filter("capitalize", capitalize);
Vue.filter("datePrimary", datePrimary);
Vue.filter("DATE_DD_MM_YYYY", DATE_DD_MM_YYYY);
Vue.filter("DATE_MMM_DD_YYYY", DATE_MMM_DD_YYYY);
Vue.filter("DATE_hh_mm", DATE_hh_mm);
Vue.filter("dateHour", dateHour);
Vue.filter("replaceUnderscore", replaceUnderscore);
Vue.filter("squareMeters", squareMeters);
Vue.filter("abbr", abbr);
Vue.filter("labelFromIdObject", labelFromIdObject);
Vue.filter("megaByte", megaByte);
Vue.filter("fixDecimals", fixDecimals);
Vue.filter("percentageDecimals", percentageDecimals);

export const mutations = {
  /**
   * @param State : state
   * @param {} tenant
   */
  setTenantSelected: (state, tenant) => {
    state.tenantSelected = tenant;
  },
  /**
   * @param State : state
   * @param monthlyBudgetGraphics : Array
   */
  setMonthlBudgetGraphics: (state, monthlyBudgetGraphics) => {
    state.monthlyBudgetGraphics = monthlyBudgetGraphics;
  },
  /**
   * @param State : state
   * @param monthlyBudgetGraphicsAllTenants : Array
   */
  setMonthlBudgetGraphicsAllTenants: (
    state,
    monthlyBudgetGraphicsAllTenants
  ) => {
    state.monthlyBudgetGraphicsAllTenants = monthlyBudgetGraphicsAllTenants;
  },
};

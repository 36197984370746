export const mutations = {
  /**
   * @param State : state
   * @param {} contractSelected
   */
  setContractSelected: (state, contract) => {
    state.contractSelected = contract;
  },
  /**
   * @param State : state
   * @param {} contractSelected
   */
  setContracts: (state, contracts) => {
    state.contracts = contracts;
  },
};

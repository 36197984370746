export const chartColors = {
  primary: "rgb(176, 133, 59)",
  palePrimary: "rgb(222, 200, 156)",
  secondary: "rgb(0, 56, 101)",
  paleSecondary: "rgb(57, 120, 172)",
  tooltipBackground: "rgb(85, 89, 109)",
  tooltipText: "rgb(250, 250, 250)",
  gray: "rgb(220, 221, 232)",
  darkGray: "rgb(141, 145, 165)",
};

export const chartTooltip = (ctxCallback) => ({
  displayColors: false,
  backgroundColor: chartColors.tooltipBackground,
  bodyColor: chartColors.tooltipText,
  bodySpacing: 8,
  caretSize: 10,
  padding: 10,
  callbacks: {
    title: () => null,
    label: (ctx) => ctxCallback(ctx),
  },
});

export const getHoverColors = (colors) =>
  colors.map((color) => {
    const [r, g, b] = color.match(/\d+/g);
    return `rgba(${r}, ${g}, ${b}, 0.6)`;
  });

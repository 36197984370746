import genericProject from "@/services/projects";
export const actions = {
  /**
   * @param context : ActionContext<Store>
   * @param {} project
   */

  SET_PROJECT_VIEW_SELECTED: (context, project) => {
    context.commit("setProjectViewSelected", project);
  },

  SET_PROJECT_EDIT: ({ commit }, project) => {
    const { requester_id, budget_id, department_id } = project;
    project["requester_id"] = Array.isArray(requester_id)
      ? requester_id[0]
      : requester_id;
    project["budget_id"] = Array.isArray(budget_id)
      ? budget_id[0].replace(".0", "")
      : budget_id;
    project["department_id"] = Array.isArray(department_id)
      ? department_id[0]
      : department_id;

    commit("setProjectEdit", project);
  },
  async getPendingProjects({ commit }) {
    const hasPending = await genericProject.getPendingProjectsLength();
    commit("setHasProjectsPending", hasPending);
  },
  async getUnassignAccountProjects({ commit }) {
    const hasPending = await genericProject.getUnassignAccountProjectsLength();
    commit("setHasProjectsUnassignAccount", hasPending);
  },
};

import moment from "moment";

function updateWork(state, { dailyTime }) {
  state.dayMinutes = moment(dailyTime.day_hours, "HH:mm:ss").diff(
    moment().startOf("day"),
    "minutes"
  );
  state.startTime =
    Date.now() -
    (dailyTime.hours * 60 * 60 + dailyTime.minutes * 60 + dailyTime.seconds) *
      1000;
  state.timeLeft = dailyTime["left minutes"];
  if (state.timeLeft === 0) {
    state.pauseTime = Date.now();
  }
  localStorage.setItem("TIME_TRACKER_TIME_LEFT", state.timeLeft);
  localStorage.setItem("TIME_TRACKER_START", state.startTime);
  localStorage.setItem("TIME_TRACKER_DAY_MINUTES", state.dayMinutes);
}

export const mutations = {
  startWork: (state, { workingMode, dailyTime }) => {
    updateWork(state, { dailyTime });
    state.workingMode = workingMode;
    localStorage.setItem("TIME_TRACKER_WORKING_MODE", workingMode);
    state.pauseTime = null;
  },
  updateWork: (state, { dailyTime }) => updateWork(state, { dailyTime }),
  setJobRecord: (state, jobrecord) => {
    state.jobrecord = jobrecord;
    localStorage.setItem("TIME_TRACKER_JOB_RECORD", JSON.stringify(jobrecord));
  },
  stopWork: (state) => {
    state.startTime = null;
    state.pauseTime = null;
    state.workingMode = null;
    state.jobrecord = null;
    state.preventTimerDialog = false;
    localStorage.removeItem("TIME_TRACKER_START");
    localStorage.removeItem("TIME_TRACKER_WORKING_MODE");
    localStorage.removeItem("TIME_TRACKER_JOB_RECORD");
  },
  pauseWork: (state) => {
    state.pauseTime = Date.now();
    localStorage.setItem("TIME_TRACKER_PAUSE", state.pauseTime);
    localStorage.removeItem("TIME_TRACKER_JOB_RECORD");
  },
  resumeWork: (state) => {
    const endTime = moment(state.startTime).add(state.dayMinutes, "minutes");
    if (moment().isAfter(endTime)) {
      return;
    }
    state.startTime = Date.now() - Math.abs(state.pauseTime - state.startTime);
    state.pauseTime = null;
    localStorage.setItem("TIME_TRACKER_START", state.startTime);
  },
  preventDialog: (state) => {
    state.preventTimerDialog = true;
  },
  timeLeftToFinishWork: (state, timeLeft) => {
    let remainingTime = timeLeft - 10;
    if (remainingTime < 0) {
      remainingTime = 0;
    }
    state.timeToCloseSession = timeLeft;
    state.timeToShowMessage = remainingTime;
    localStorage.setItem(
      "TIME_TRACKER_TIME_LEFT_TO_SHOW_MESSAGE",
      remainingTime.toString()
    );
    localStorage.setItem(
      "TIME_TRACKER_TIME_LEFT_FINISH_WORK",
      timeLeft.toString()
    );
  },
  clearState: (state) => {
    const clearedState = {
      startTime: null,
      pauseTime: null,
      workingMode: null,
      timeLeft: null,
      timeToShowMessage: 0,
      timeToCloseSession: 0,
      preventTimerDialog: false,
      jobrecord: null,
    };
    Object.keys(clearedState).forEach((key) => {
      state[key] = clearedState[key];
    });
  },
};

<template>
  <wrapper-dialog
    :value="showWelcomeMessage"
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('global.workday_almost_over')"
    maxWidth="500px"
    padding="10px"
  >
    <p class="text-body-1">
      {{
        $t("global.workday_almost_over_message", {
          start_hour: user.start_hour,
          end_hour: user.end_hour,
          name: user.name,
        })
      }}
    </p>
    <template #actions>
      <div cols="12" class="d-flex justify-end px-0 pb-2">
        <button-custom
          :label="$t('global.ok_got_it')"
          @click="close"
          outlined
          color="primary"
          class="mr-0 text-none ml-3"
        />
      </div>
    </template>
  </wrapper-dialog>
</template>

<script>
import ButtonCustom from "@/components/buttons/ButtonCustom.vue";
import { mapActions, mapGetters } from "vuex";
import WrapperDialog from "@/components/general/WrapperDialog.vue";
import authService from "@/services/auth";

export default {
  name: "DialogWorkDayAlmostOver",
  computed: {
    ...mapGetters({
      showWelcomeMessage: "states/showWorkDayAlmostOver",
      user: "auth/user",
    }),
  },
  components: {
    ButtonCustom,
    WrapperDialog,
  },
  methods: {
    ...mapActions({
      setClearUser: "auth/setClearUser",
      setClearTimer: "timeTracker/clearTimer",
    }),
    close() {
      this.$store.dispatch("states/closeWorkDayAlmostOver");
    },
    showDialog() {
      this.$store.dispatch("states/openWorkDayAlmostOver");
    },
    async closeSession() {
      try {
        await authService.logout();
      } finally {
        await this.setClearUser();
        this.close();
        this.setClearTimer();
        await this.$router.push("/login");
      }
    },
  },
  mounted() {
    this.$store.dispatch("timeTracker/getDailyWork");
    if (parseInt(this.$store.getters["timeTracker/timeToShowMessage"]) > 0) {
      const timeOutToShowMessage =
        parseFloat(this.$store.getters["timeTracker/timeToShowMessage"]) *
        60 *
        1000;
      setTimeout(this.showDialog, timeOutToShowMessage);
    }
  },
};
</script>

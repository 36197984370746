/**
 * Replace unnecesary underscores by a space
 * @param value : string
 * @returns string
 */
export const replaceUnderscore = (value) => {
  let result = value.replace("_", " ");
  return result;
};
/**
 * Capitalize text
 * @param value : string
 * @return string
 */
export const capitalize = (value) => {
  if (!value) return "";
  let capitalized = [];
  value.split(" ").forEach((word) => {
    capitalized.push(
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
  });
  return capitalized.join(" ");
};

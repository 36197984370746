export const mutations = {
  setNavLinks: (state, navLinks) => {
    state.navLinks = navLinks;
    const castingNavLinks = JSON.stringify(navLinks);
    localStorage.setItem("NAV_LINKS", castingNavLinks);
  },

  setNavGroups: (state, navGroups) => {
    state.navGroups = navGroups;
    const castingNavGroups = JSON.stringify(navGroups);
    localStorage.setItem("NAV_GROUPS", castingNavGroups);
  },

  setNavSimpleGroups: (state, navSimpleGroups) => {
    state.navSimpleGroups = navSimpleGroups;
    const castingNavSimpleGroups = JSON.stringify(navSimpleGroups);
    localStorage.setItem("NAV_SIMPLE_GROUPS", castingNavSimpleGroups);
  },
};

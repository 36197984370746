import i18n from "@/plugins/i18n";

const colorDefaultStatus = [
  {
    color: "primary lighten-1",
    textColor: "primary--text text--lighten-4",
    iconColor: "primary",
    type: "alert",
  },
  {
    color: "success lighten-4",
    textColor: "success--text text--lighten-3",
    iconColor: "success",
    type: "done",
  },
  {
    color: "success lighten-4",
    textColor: "success--text text--lighten-3",
    iconColor: "success",
    type: "solved",
  },
  {
    color: "neutral lighten-2",
    textColor: "neutral--text text--darken-1",
    iconColor: "neutral darken-1",
    type: "default",
  },
  {
    color: "error lighten-1",
    textColor: "error--text text--lighten-2",
    iconColor: "error",
    type: "error",
  },
  {
    color: "secondary lighten-1",
    textColor: "secondary--text text--lighten-2",
    iconColor: "secondary",
    type: "help",
  },
];

const getColorDefaultStatus = (type) => {
  const color = colorDefaultStatus.find((color) => color.type === type);
  if (color) return color;
  return colorDefaultStatus.find((color) => color.type === "default");
};

export const colorStatus = [
  {
    ...getColorDefaultStatus("alert"),
    iconName: "mdi mdi-exclamation",
    value: "pending",
    text: i18n.t("status.pending"),
  },
  {
    ...getColorDefaultStatus("alert"),
    iconName: "mdi mdi-exclamation",
    value: "pending accept",
    text: i18n.t("status.pending_accept"),
  },
  {
    ...getColorDefaultStatus("alert"),
    iconName: "mdi mdi-exclamation",
    value: "pending cancel",
    text: i18n.t("status.pending_cancel"),
  },
  {
    ...getColorDefaultStatus(),
    iconName: "mdi mdi-lock",
    value: "close",
    text: i18n.t("status.close"),
  },
  {
    ...getColorDefaultStatus("done"),
    iconName: "mdi mdi-check",
    value: "active",
    text: i18n.t("status.active"),
  },
  {
    ...getColorDefaultStatus("done"),
    iconName: "mdi mdi-check",
    value: "open",
    text: i18n.t("status.open"),
  },
  {
    ...getColorDefaultStatus("done"),
    iconName: "mdi mdi-pencil",
    value: "LOI",
    text: i18n.t("status.loi"),
  },
  {
    ...getColorDefaultStatus("done"),
    iconName: "mdi mdi-check-all",
    value: "approved",
    text: i18n.t("status.approved"),
  },
  {
    ...getColorDefaultStatus("done"),
    iconName: "mdi mdi-check-all",
    value: "accepted",
    text: i18n.t("status.accepted"),
  },
  {
    ...getColorDefaultStatus(),
    iconName: "mdi mdi-close",
    value: "deleted",
    text: i18n.t("status.deleted"),
  },
  {
    ...getColorDefaultStatus(),
    iconName: "mdi mdi-close",
    value: "finished",
    text: i18n.t("status.finished"),
  },
  {
    ...getColorDefaultStatus(),
    iconName: "mdi mdi-check",
    value: "rejected",
    text: i18n.t("status.rejected"),
  },
  {
    ...getColorDefaultStatus("error"),
    iconName: "mdi mdi-close",
    value: "cancelled",
    text: i18n.t("status.cancelled"),
  },
  {
    ...getColorDefaultStatus("error"),
    iconName: "mdi mdi-close",
    value: "canceled",
    text: i18n.t("status.canceled"),
  },
  {
    ...getColorDefaultStatus("error"),
    iconName: "mdi mdi-close",
    value: "denied",
    text: i18n.t("status.denied"),
  },
  {
    ...getColorDefaultStatus("help"),
    iconName: "mdi mdi-replay",
    value: "returned",
    text: i18n.t("status.returned"),
  },
  {
    ...getColorDefaultStatus(),
    iconName: "",
    value: "absent",
    text: i18n.t("status.absent"),
  },
  {
    ...getColorDefaultStatus(),
    iconName: "mdi mdi-check",
    value: "Extraordinary",
    text: i18n.t("status.extraordinary"),
  },
  {
    ...getColorDefaultStatus("neutral"),
    iconName: "mdi mdi-message-alert",
    value: "under negotiation",
    text: i18n.t("status.under_negociation"),
  },
  {
    ...getColorDefaultStatus(),
    iconName: "mdi mdi-exclamation-thick",
    value: "unpaid",
    text: i18n.t("status.unpaid"),
  },
  {
    ...getColorDefaultStatus(),
    iconName: "mdi mdi-check",
    value: "paid_default",
    text: i18n.t("status.paid"),
  },
  {
    ...getColorDefaultStatus("error"),
    iconName: "mdi-close",
    value: "Not approved",
    text: i18n.t("status.not_approved"),
  },
  {
    ...getColorDefaultStatus("error"),
    iconName: "mdi-close",
    value: "Not asigned",
    text: i18n.t("status.not_asigned"),
  },
  {
    ...getColorDefaultStatus("alert"),
    iconName: "mdi mdi-exclamation",
    value: "not assigned",
    text: i18n.t("status.not_asigned"),
  },
  {
    ...getColorDefaultStatus("done"),
    iconName: "mdi mdi-check-all",
    value: "Assigned",
    text: i18n.t("status.assigned"),
  },
  {
    ...getColorDefaultStatus("done"),
    iconName: "mdi mdi-check-all",
    value: "approved",
    text: i18n.t("status.assigned"),
  },
  {
    ...getColorDefaultStatus("error"),
    iconName: "mdi mdi-close",
    value: "no_paid",
    text: i18n.t("status.not_paid"),
  },
  {
    ...getColorDefaultStatus("done"),
    iconName: "mdi mdi-check-all",
    value: "paid",
    text: i18n.t("status.paid"),
  },
  {
    ...getColorDefaultStatus("alert"),
    iconName: "mdi mdi-exclamation",
    value: "empty",
    text: i18n.t("status.empty"),
  },
  {
    ...getColorDefaultStatus("done"),
    iconName: "mdi mdi-check",
    value: "rented",
    text: i18n.t("status.rented"),
  },
  {
    ...getColorDefaultStatus("solved"),
    iconName: "mdi mdi-check",
    value: "solved",
    text: i18n.t("status.solved"),
  },
  {
    ...getColorDefaultStatus("help"),
    iconName: "mdi mdi-pencil",
    value: "signed",
    text: i18n.t("status.signed"),
  },
  {
    ...getColorDefaultStatus("alert"),
    iconName: "mdi mdi-pencil",
    value: "not_assigned",
    text: i18n.t("status.not_assigned"),
  },
  {
    ...getColorDefaultStatus("neutral"),
    iconName: "mdi mdi-eye-off",
    value: "disable",
    text: i18n.t("status.disable"),
  },
  {
    ...getColorDefaultStatus("neutral"),
    iconName: "mdi mdi-eye-off",
    value: "disabled",
    text: i18n.t("status.disable"),
  },
  {
    ...getColorDefaultStatus("error"),
    iconName: "mdi mdi-eye-off",
    value: "archived",
    text: i18n.t("status.archived"),
  },
  {
    ...getColorDefaultStatus("help"),
    iconName: "mdi mdi-check",
    value: "draft",
    text: i18n.t("status.draft"),
  },
  {
    ...getColorDefaultStatus("neutral"),
    iconName: "mdi mdi-check",
    value: "published",
    text: i18n.t("status.published"),
  },
];

export const actions = {
  /**
   * @param context : ActionContext<Store>
   * @param {} post
   */
  set_news_selected: (context, news) => {
    context.commit("setNewsSelected", news);
  },
  /**
   * @param context : ActionContext<Store>
   * @param {} boolean
   */
  set_news_wrapper: (context, newsWrapper) => {
    context.commit("setNewsWrapper", newsWrapper);
  },
  /**
   * @param context : ActionContext<Store>
   * @param {} : { publish: boolean}
   */
  set_notifications: (context, notifications) => {
    context.commit("setNotifications", notifications);
  },
  /**
   * @param context : ActionContext<Store>
   * @param newsletters : News[]
   */
  set_newsletters: (context, newsletters) => {
    context.commit("setNewsletters", newsletters);
  },
};

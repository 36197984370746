<template>
  <v-text-field
    :value="value"
    @input="onInputTextField"
    v-on="$listeners"
    v-bind="$attrs"
  ></v-text-field>
</template>

<script>
import { PropTypes } from "@/types";

export default {
  name: "TextFieldDefault",
  props: {
    value: PropTypes.string,
  },
  data() {
    return {};
  },
  methods: {
    onInputTextField(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style></style>

import messagesEn from "@/plugins/i18n/locales/en";
import messagesEs from "@/plugins/i18n/locales/es";
import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const defaultLocale = "en";

export default new VueI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages: {
    en: messagesEn,
    es: messagesEs,
  },
});

export const mutations = {
  /**
   * @param State : state
   * @param {} News
   */
  setNewsSelected: (state, news) => {
    state.newsSelected = news;
  },
  /**
   * @param State : state
   * @param {} boolean
   */
  setNewsWrapper: (state, newsWrapper) => {
    state.newsWrapper = newsWrapper;
  },
  /**
   * @param State : state
   * @param {} : { publish: boolean}
   */
  setNotifications: (state, notifications) => {
    state.notifications = notifications;
  },
  /**
   * @param State : state
   * @param {} News[]
   */
  setNewsletters: (state, newsletters) => {
    state.newsletters = newsletters;
  },
};

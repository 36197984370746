export const getters = {
  dayMinutes: (state) => {
    return state.dayMinutes;
  },
  startTime: (state) => {
    return state.startTime;
  },
  pauseTime: (state) => {
    return state.pauseTime;
  },
  timeLeft: (state) => {
    return state.timeLeft;
  },
  hasTimeSession: (state) => {
    return !!state.startTime && !!state.workingMode;
  },
  timeToShowMessage: (state) => {
    return state.timeToShowMessage;
  },
  timeToCloseSession: (state) => {
    return state.timeToCloseSession;
  },
  preventTimerDialog: (state) => {
    return state.preventTimerDialog;
  },
};

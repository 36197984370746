import { USER_ROLE } from "@/utils/constants";
const role = [USER_ROLE.ADM, USER_ROLE.EMP, USER_ROLE.DIR];
const layout = "admin";
import authMiddleware from "@/middlewares/auth";
import roleMiddleware from "@/middlewares/role";

const metaTenant = {
  role,
  layout,
  middleware: [authMiddleware, roleMiddleware],
};

const tenant = [
  {
    path: "/tenant/dashboard",
    name: "shared.tenant.dashboard",
    component: () => import("@/views/tenant/TenantDashboardView"),
    meta: metaTenant,
  },
  {
    path: "/tenant/stores",
    name: "shared.tenant.stores",
    component: () => import("@/views/tenant/TenantStoresView.vue"),
    meta: metaTenant,
  },
  {
    path: "/tenant/:id",
    name: "shared.tenant.detail",
    component: () => import("@/views/tenant/TenantDetailView"),
    meta: metaTenant,

    children: [
      {
        path: "/tenant/detail/:id",
        name: "shared.tenant.TenantDetail",
        component: () => import("@/components/tenant/detail/TenantDetail.vue"),
        meta: metaTenant,
      },
      {
        path: "/tenant/movements/:id",
        name: "shared.tenant.movements",
        component: () =>
          import("@/components/budget/expenses/expense-list/ExpenseList.vue"),
        meta: metaTenant,
      },
      {
        path: "/tenant/monthly-budget/:id",
        name: "shared.tenant.monthly-budget",
        component: () =>
          import(
            "@/components/tenant/monthly-budget/monthly-budget-list/MonthlyBudgetList.vue"
          ),
        meta: metaTenant,
      },
      {
        path: "/tenant/invoicing-terms/:id",
        name: "shared.tenant.invoicing-terms",
        component: () =>
          import("@/components/tenant/invoicing/InvoicingTerms.vue"),
        meta: metaTenant,
      },
      {
        path: "/tenant/crm-list/:id",
        name: "shared.tenant.crm-list",
        component: () => import("@/components/tenant/crm/crm-list/CrmList.vue"),
        meta: metaTenant,
      },
      {
        path: "/tenant/alert-list/:id",
        name: "shared.tenant.alert-list",
        component: () =>
          import("@/components/tenant/Alerts/alert-list/AlertsList.vue"),
        meta: metaTenant,
      },
      {
        path: "/tenant/attachment-files/:id",
        name: "shared.tenant.attachment-files",
        component: () =>
          import("@/components/tenant/AttachmentFiles/AttachmentFilesList.vue"),
        meta: metaTenant,
      },
      {
        path: "/tenant/history-list/:id",
        name: "shared.tenant.history-list",
        component: () =>
          import("@/components/tenant/history/history-list/HistoryList.vue"),
        meta: metaTenant,
      },
    ],
  },
  {
    path: "/tenant/detail/:id/history",
    name: "shared.tenant.detail.history",
    component: () => import("@/views/tenant/TenantDetailHistoryView.vue"),
    meta: metaTenant,
  },
  {
    path: "/tenant/stores/consumption/:id/:label/:type",
    name: "shared.tenant.stores.consumption",
    component: () => import("@/views/tenant/ConsumptionHistoryView.vue"),
    meta: metaTenant,
  },
];

export default tenant;

const state = {
  permissions: [],
};

const getters = {};

const mutations = {
  /**
   * @param State : state
   * @param {} permissions
   */
  setPermissionsList: (state, permissions) => {
    state.permissions = permissions;
  },
};

const actions = {
  /**
   * @param context : ActionContext<Store>
   * @param {} auth
   */
  setPermissionsList: (context, permissions) => {
    context.commit("setPermissionsList", permissions);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

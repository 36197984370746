export const WORKING_MODES = {
  ON_SITE: "on-site",
  REMOTE: "remote work",
  OUTSIDE: "outside",
};

export const WORKING_MODES_LIST = [
  WORKING_MODES.ON_SITE,
  WORKING_MODES.REMOTE,
  WORKING_MODES.OUTSIDE,
];

export const PropTypes = {
  array: {
    type: Array,
    default: () => [],
  },

  object: {
    type: Object,
    default: () => null,
  },

  string: {
    type: String,
    default: "",
  },

  number: {
    type: Number,
    default: 0,
  },

  formMode: {
    type: String,
    required: true,
    validator: (o) => ["create", "update"].includes(o),
  },

  boolean: {
    type: Boolean,
    default: false,
  },
};
